import React, {Component} from 'react'
import { connect } from 'react-redux'
import {windowResize} from '../actions'

const mapStateToProps = (state, ownProps) => {
  return {
    dimensions: state.dimensions
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

class FooterContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      position: this.props.dimensions.innerHeight > this.props.dimensions.scrollHeight ? 'absolute' : 'relative'
    }
    this.reposition = this.reposition.bind(this)
  }
  componentWillReceiveProps () {
    this.reposition()
  }
  componentDidMount () {
    this.reposition()
    const {dispatch} = this.props
    window.addEventListener('resize', function () {
      dispatch(windowResize())
    }, false)
  }
  componentWillUnmount () {
    window.removeEventListener('resize')
  }
  reposition () {
    setTimeout(() => {
      const footer = document.getElementById('footer')
      const primaryBottom = document.getElementsByClassName('ls-primary')[0] ? document.getElementsByClassName('ls-primary')[0].getBoundingClientRect().bottom + window.scrollY : document.getElementsByClassName('ls-fluid')[0].getBoundingClientRect().bottom + window.scrollY
      const footerBottom = footer.getBoundingClientRect().bottom + window.scrollY
      const footerHeight = footer.scrollHeight
      const overflow = primaryBottom > footerBottom || primaryBottom + footerHeight > this.props.dimensions.innerHeight
      // console.log('primaryBottom: ' + primaryBottom)
      // console.log('footerBottom: ' + footerBottom)
      // console.log('footer height: ' + document.getElementById('footer').offsetHeight)
      // console.log('dimensions.innerHeight: ' + this.props.dimensions.innerHeight)
      this.setState({
        // position: this.props.dimensions.innerHeight > (this.props.dimensions.scrollHeight + document.getElementById('footer').offsetHeight) ? 'absolute' : 'relative'
        position: !overflow ? 'absolute' : 'relative'
      })
    }, 0)
  }
  render () {
    const {position} = this.state
    return (
      <nav id='footer' className='navbar navbar-expand navbar-dark bg-dark app-navbar flex-nowrap flex-row d-print-none' style={{position: position}}>
        <span className='navbar-text' style={{marginRight: '2rem'}}>© 2018 Atanemi, LLC</span>
        <ul className='navbar-nav mr-auto'>
          <li className='nav-item'><a className='nav-link' href='/about'>About</a></li>
          <li className='nav-item'><a className='nav-link' href='/guide'>Guide</a></li>
          <li className='nav-item'><a className='nav-link' href='/site-policy'>Site policy</a></li>
          <li className='nav-item'><a className='nav-link' href='/contact'>Contact</a></li>
          <li className='nav-item'><a className='nav-link' href='https://github.com/LitSignal/public/issues'>Issues</a></li>
        </ul>
      </nav>
    )
  }
}

const Footer = connect(mapStateToProps, mapDispatchToProps)(FooterContainer)

export default Footer
