import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { uploadLibrary } from '../actions'
import Upload from '../components/Upload'

class AsyncUploadLibrary extends Component {
  constructor (props) {
    super(props)
    this.onUpload = this.onUpload.bind(this)
  }
  onUpload (files, fileSha1Map) {
    const { dispatch } = this.props
    if (files.length > 0) {
      console.log(`AsyncUploadLibrary => files.length: ${files.length}`)
      dispatch(uploadLibrary(files, fileSha1Map))
    }
  }
  render () {
    return (
      <Upload
        onUpload={this.onUpload}
        existingSha1s={this.props.existingSha1s}
        label='Upload library'
        multipleFiles
        asDropdown
      />
    )
  }
}

AsyncUploadLibrary.propTypes = {
  dispatch: PropTypes.func.isRequired,
  existingSha1s: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default AsyncUploadLibrary
