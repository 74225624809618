import React, {Component} from 'react'
import fetch from 'isomorphic-fetch'
import PropTypes from 'prop-types'
import AsyncStar from '../containers/AsyncStar'
import AsyncShare from '../containers/AsyncShare'
import AsyncAddToModule from '../containers/AsyncAddToModule'
import Recommendation from './Recommendation'
import LinkInfo from './LinkInfo'
import {windowResize} from '../actions'

class AsyncVideo extends Component {
  constructor (props) {
    super(props)
    this.state = {embedHTML: null}
    this.updateEmbedHTML = this.updateEmbedHTML.bind(this)
  }
  updateEmbedHTML (html) {
    this.setState({embedHTML: html})
  }
  componentWillMount () {
    if (!this.state.embedHTML) {
      const {url} = this.props.metadata
      let oEmbedUrl = `https://noembed.com/embed?url=${encodeURI(url)}`
      fetch(oEmbedUrl, {method: 'GET'})
        .then(response => {
          if (response.ok) {
            response.json()
              .then(json => {
                this.updateEmbedHTML(json.html)
                this.props.dispatch(windowResize())
              })
          }
        })
    }
  }
  render () {
    const {dispatch, stars, metadata, uuid, userLoggedIn, modifiedModules, isDesktop} = this.props
    const { url, title, recommendation } = metadata
    const reference = <LinkInfo title={title} url={url} />

    let header
    if (recommendation) {
      header = <Recommendation dispatch={dispatch} recommendation={recommendation} />
    }
    return (
      <div className='media-list' >
        <div className='ls-reference'>
          <li className='media list-group-item ls-embed'>
            <div className='embed-responsive embed-responsive-16by9' >
              {header}
              <div dangerouslySetInnerHTML={{__html: this.state.embedHTML}} />
            </div>
          </li>
          <li className='media list-group-item ls-controls' >
            {userLoggedIn && <div>
              <AsyncStar dispatch={dispatch} stars={stars} refUUID={uuid} isDesktop={isDesktop} />
              <AsyncAddToModule dispatch={dispatch} modifiedModules={modifiedModules} refUUID={uuid} reference={reference} isDesktop={isDesktop} />
              <AsyncShare dispatch={dispatch} refUUID={uuid} reference={reference} isDesktop={isDesktop} />
            </div>}
          </li>
        </div>
      </div>
    )
  }
}

AsyncVideo.propTypes = {
  dispatch: PropTypes.func.isRequired,
  stars: PropTypes.object.isRequired,
  metadata: PropTypes.object.isRequired,
  uuid: PropTypes.string.isRequired,
  userLoggedIn: PropTypes.bool,
  editing: PropTypes.bool,
  modifiedModules: PropTypes.arrayOf(PropTypes.string).isRequired,
  isDesktop: PropTypes.bool.isRequired
}

export default AsyncVideo
