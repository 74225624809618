import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ModalCard from './ModalCard'
import {slugify} from '../helpers'

class AddTag extends Component {
  constructor (props) {
    super(props)
    this.state = {showModal: false}
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.addTag = this.addTag.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  closeModal (e) {
    e.stopPropagation()
    this.setState({showModal: false})
  }
  openModal (e) {
    this.setState({showModal: true})
    this.props.onChange('')
  }
  addTag (e) {
    this.props.onAddTag(e)
    this.closeModal(e)
  }

  handleInputChange (e) {
    this.props.onChange(e.target.value)
  }

  render () {
    const { tagInput, isDesktop } = this.props
    const { showModal } = this.state

    const Form = (
      <form onSubmit={this.addTag}>
        <h4>Tag this module</h4>
        <div className='input-group mb-0' >
          <input
            id='tagfield'
            className='form-control'
            name='tag'
            type='text'
            placeholder=''
            value={tagInput}
            onChange={this.handleInputChange}
            autoFocus
          />
          <div className='input-group-append'>
            <button className='btn btn-primary' type='submit' >Add tag</button>
          </div>
        </div>
        <small className='form-text text-muted'>{tagInput ? `This tag will be rendered as: ${slugify(tagInput)}` : ' Punctuation and spaces will be modified in the final tag.'}</small>
      </form>
    )

    return (
      <a tabIndex='-1' role='button' className='nav-link' onClick={this.openModal}><i className='icon icon-tag' />{isDesktop ? 'New tag' : null}
        {showModal === true && <ModalCard onHide={this.closeModal} >
          <div className='card-body'>
            {Form}
          </div>
        </ModalCard>}
      </a>
    )
  }
}

AddTag.propTypes = {
  tagInput: PropTypes.string.isRequired,
  onAddTag: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isDesktop: PropTypes.bool
}

export default AddTag
