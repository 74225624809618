import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ModalCard from '../components/ModalCard'

class AddToModule extends Component {
  constructor (props) {
    super(props)
    this.state = {showAddToModuleModal: false}
    this.openAddToModule = this.openAddToModule.bind(this)
    this.close = this.close.bind(this)
    this.addToModule = this.addToModule.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
  }

  close (e) {
    e.stopPropagation()
    this.setState({ showAddToModuleModal: false })
  }
  openAddToModule () {
    this.setState({ showAddToModuleModal: true })
  }
  addToModule (e) {
    this.props.onAddToModule(e)
    this.close(e)
  }

  handleSelectChange (e) {
    this.props.onSelect(e.target.value)
  }

  render () {
    const { reference, moduleSelect, moduleOptions, isDesktop } = this.props
    // const isFirstModule = moduleOptions.length === 0
    return (
      <a tabIndex='-1' role='button' title='Add this to another module' onClick={this.openAddToModule}>
        <i className='icon icon-add-to-list' />{isDesktop ? 'Add to module' : ''}
        {this.state.showAddToModuleModal && <ModalCard onHide={this.close}>
          <div className='card-body'>
            {reference}
            <form style={{paddingTop: '1rem'}}>
              <div className='form-group'>
                <label htmlFor='moduleselect'>Select a module </label>
                <select id='moduleselect' name='moduletitle' className='form-control' onChange={this.handleSelectChange} value={moduleSelect}>
                  {moduleOptions.map(function (moduleTitle) {
                    return (
                      <option value={moduleTitle} key={moduleTitle}>{moduleTitle}</option>
                    )
                  })}
                </select>
              </div>
              <button className='btn btn-primary' type='button' onClick={this.addToModule}>Add item</button>
            </form>
          </div>
        </ModalCard>}
      </a>
    )
  }
}

AddToModule.propTypes = {
  reference: PropTypes.element.isRequired,
  moduleSelect: PropTypes.string.isRequired,
  moduleOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onAddToModule: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  isDesktop: PropTypes.bool
}

export default AddToModule
