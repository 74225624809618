import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ModuleHeader extends Component {
  constructor (props) {
    super(props)
    this.state = {
      expanded: false
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (e) {
    if (e.target.nodeName === 'A') {
      return
    }
    this.setState({
      expanded: !this.state.expanded
    })
  }

  render () {
    const {module} = this.props
    const {canonicalTitle, author} = module

    return (
      <div>
        <div>
          <h1 className='ls-module-title'>{canonicalTitle || ''} </h1>
          <div className='ls-module-author'>by <a href={`/${author}`}>{author}</a></div>
          {!!module.parentTitle && <div className='ls-module-author'>Forked from <a href={`/${module.parentAuthor}/${module.parentTitle}`}>{module.parentAuthor}/{module.parentTitle}</a></div>}
          <h3 className='ls-module-description'>{module.description}</h3>
        </div>
      </div>
    )
  }
}

ModuleHeader.propTypes = {
  module: PropTypes.object.isRequired
}

ModuleHeader.defaultProps = {
  description: 'Click on the Edit button to add a description to this module.'
}

export default ModuleHeader
