const RemoveTag = ({
  onRemove,
  tag
}) => {
  return (
    <button className='btn btn-sm btn-danger mr-3' tabIndex='-1' role='button' onClick={onRemove} title='Remove this tag.'><i className='icon icon-cross mr-2' />{tag}</button>
  )
}

export default RemoveTag
