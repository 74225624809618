import {Component} from 'react'
import Recaptcha from 'react-google-invisible-recaptcha'
import {validator, getRecaptchaSiteKey} from '../helpers'
const sitekey = getRecaptchaSiteKey()

class RegisterForm extends Component {
  constructor (props) {
    super(props)
    this.handleRecaptcha = this.handleRecaptcha.bind(this)
    this.triggerRecaptcha = this.triggerRecaptcha.bind(this)
  }
  handleRecaptcha () {
    // console.log('resolved with: ' + this.recaptcha.getResponse())
    this.props.triggerRegistration(this.recaptcha.getResponse())
  }
  triggerRecaptcha (evt) {
    evt.preventDefault()
    // document.getElementsByClassName('register-form needs-validation')[0].classList.add('was-validated')
    // console.log('form submit')
    // TODO: perform some client side validation
    this.recaptcha.execute()
  }
  render () {
    const {onUsernameChange, onEmailChange, onPasswordChange, username, slugifiedUsername, email, password, showLogin, usernameAvailable} = this.props
    const UsernameFeedback = username ? !usernameAvailable ? `${slugifiedUsername} is not available` : `${slugifiedUsername} is available!` : ''
    const passwordIsValid = validator.isPassword(password)
    const emailIsValid = validator.isEmail(email)
    const passwordFeedback = passwordIsValid ? '' : 'Password must be at least 6 characters'
    const emailFeedback = emailIsValid ? '' : 'Please enter a valid email address'
    const usernameValidationClass = username ? usernameAvailable ? 'form-control is-valid' : 'form-control is-invalid' : 'form-control'
    const emailValidationClass = email ? emailIsValid ? 'form-control is-valid' : 'form-control is-invalid' : 'form-control'
    const passwordValidationClass = password ? validator.isPassword(password) ? 'form-control is-valid' : 'form-control is-invalid' : 'form-control'
    const passwordFeedbackClass = passwordIsValid ? 'valid-feedback' : 'invalid-feedback'
    const emailFeedbackClass = emailIsValid ? 'valid-feedback' : 'invalid-feedback'
    const usernameFeedbackClass = usernameAvailable ? 'valid-feedback' : 'invalid-feedback'
    return (
      <form className='register-form needs-validation' noValidate onSubmit={this.triggerRecaptcha}>
        <h5 className='card-title'>Register</h5>
        <div className='form-group' >
          <label htmlFor='usernamefield'>Username</label>
          <input
            className={usernameValidationClass}
            required
            name='username'
            type='text'
            placeholder='username'
            onChange={onUsernameChange}
            value={username}
            autoFocus />
          <div className={usernameFeedbackClass}>
            {UsernameFeedback}
          </div>
        </div>
        <div className='form-group' >
          <label htmlFor='emailfield'>Email</label>
          <input
            className={emailValidationClass}
            required
            name='email'
            type='text'
            placeholder='email'
            onChange={onEmailChange}
            value={email} />
          <div className={emailFeedbackClass}>
            {emailFeedback}
          </div>
        </div>
        <div className='form-group' >
          <label htmlFor='password-field'>Password</label>
          <input
            className={passwordValidationClass}
            required
            name='password'
            type='password'
            placeholder='password'
            onChange={onPasswordChange}
            value={password} />
          <div className={passwordFeedbackClass}>
            {passwordFeedback}
          </div>
        </div>
        <div className='form-group'>
          <button className='btn btn-primary' type='submit' disabled={[username && usernameAvailable, emailIsValid, passwordIsValid].indexOf(false) > -1}>Register</button>
        </div>
        <Recaptcha
          ref={ref => { this.recaptcha = ref }}
          sitekey={sitekey}
          onResolved={this.handleRecaptcha} />
        <p>
          Already registered? <a tabIndex='-1' role='button' onClick={showLogin}>Sign in here</a>.
        </p>
      </form>
    )
  }
}

export default RegisterForm
