import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { addTag } from '../actions'
import AddTag from '../components/AddTag'

class AsyncAddTag extends Component {
  constructor (props) {
    super(props)
    this.state = {tagInput: ''}
    this.onAddTag = this.onAddTag.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }
  onAddTag (e) {
    e.preventDefault()
    const { dispatch } = this.props
    // console.log('isBeginner: ' + isBeginner)
    // console.log('readme: ' + readme)
    dispatch(addTag(this.state.tagInput, this.props.componentUUID))
  }
  handleChange (value) {
    this.setState({ tagInput: value })
  }
  render () {
    const { isDesktop } = this.props
    return (
      <AddTag
        tagInput={this.state.tagInput}
        onChange={this.handleChange}
        onAddTag={this.onAddTag}
        isDesktop={isDesktop}
      />
    )
  }
}

AsyncAddTag.propTypes = {
  dispatch: PropTypes.func.isRequired,
  componentUUID: PropTypes.string.isRequired,
  isDesktop: PropTypes.bool.isRequired
}

export default AsyncAddTag
