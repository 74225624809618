import React, {Component} from 'react'
import PropTypes from 'prop-types'
import AsyncLinks from './../containers/AsyncLinks'
// import Review from '../components/Review'
import ReferenceToolbar from '../components/ReferenceToolbar'
import Recommendation from './Recommendation'
import BookInfo from './BookInfo'

class Book extends Component {
  constructor (props) {
    super(props)
    this.state = {
      expanded: false
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (e) {
    // if (e.target.nodeName === 'A') {
    //   return
    // }
    // this.setState({
    //   expanded: !this.state.expanded
    // })
  }
  render () {
    const { dispatch, modifiedModules, stars, uuid, book, userLoggedIn, isDesktop } = this.props
    const { approved, isbn13, recommendation, title } = book
    const isUploads = false
    const reference = <BookInfo book={book} />

    let header
    if (recommendation) {
      header = <Recommendation dispatch={dispatch} recommendation={recommendation} />
    }
    const referenceClass = this.state.expanded ? 'media list-group-item ls-reference ls-reference-expanded' : 'media list-group-item ls-reference'
    // const indicatorClass = this.state.expanded ? 'icon icon-chevron-up' : 'icon icon-chevron-down'

    return (
      <li onClick={this.handleClick} className={referenceClass}>
        {header}
        {reference}
        <AsyncLinks
          dispatch={dispatch} reference={reference}
          isbn13={isbn13}
          refUUID={uuid}
          title={title}
        />
        {userLoggedIn && <ReferenceToolbar dispatch={dispatch} stars={stars} isDesktop={isDesktop} modifiedModules={modifiedModules} reference={reference} approved={approved} uuid={uuid} idUploads={isUploads} userLoggedIn={userLoggedIn} />}
      </li>
    )
  }
}

Book.propTypes = {
  dispatch: PropTypes.func.isRequired,
  modifiedModules: PropTypes.arrayOf(PropTypes.string).isRequired,
  stars: PropTypes.object.isRequired,
  rearranging: PropTypes.bool.isRequired,
  uuid: PropTypes.string.isRequired,
  book: PropTypes.shape({
    title: PropTypes.string,
    author: PropTypes.string,
    isbn13: PropTypes.number,
    asin: PropTypes.string,
    ean: PropTypes.number,
    publisher: PropTypes.string,
    pubDate: PropTypes.string,
    approved: PropTypes.bool,
    stargazers: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  moduleTitle: PropTypes.string,
  index: PropTypes.number,
  length: PropTypes.number,
  editing: PropTypes.bool,
  userLoggedIn: PropTypes.bool
}

export default Book

