import React from 'react'
import PropTypes from 'prop-types'
import UserModule from '../components/UserModule'
import ModulesSummary from '../components/ModulesSummary'
import AsyncStar from '../containers/AsyncStar'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
  return {
    user: state.user,
    module: state.module,
    stars: state.stars,
    history: state.history,
    storage: state.storage,
    client: state.client,
    search: state.search,
    userMetrics: state.userMetrics
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch
  }
}

const UserContainer = ({
  user,
  module,
  stars,
  history,
  storage,
  dispatch,
  client,
  search,
  userMetrics
}) => {
  const isDesktop = client.type.localeCompare('desktop') === 0 || client.type.localeCompare('tablet') === 0
  // console.log(`userMetrics.profile: ${JSON.stringify(userMetrics.profile)}`)
  const {username, fullName, credentials, bio, location} = userMetrics.profile
  const {recentModules, recentStars, trendingModules, activeModules} = userMetrics
  const {impact, reach, starCount, moduleStarCount} = userMetrics.metrics
  // const modifiedModules = history.modules.modified
  const recentStarsModule = {
    title: 'Recent Stars',
    editing: false,
    rearranging: false,
    components: recentStars
  }
  const userLoggedIn = !!user.username
  const isOwnHome = user && user.username === username
  // console.log(`recentStarsModule: ${JSON.stringify(recentStarsModule)}`)
  return (
    <div>
      <div className='card'>
        <div className='card-header ls-reference'>
          <div className='d-flex'>
            <div className='mr-auto'>
              <a className='mr-4 font-weight-bold' href={`/${username}`}><i className='icon icon-home mr-2' />{isDesktop ? 'Home' : ''}</a>
              <a className='mr-4' href={`/${username}/modules`}><i className='icon icon-documents' />{isDesktop ? 'Modules' : ''}</a>
              <a className='mr-4' href={`/${username}/stars`}><i className='icon icon-star' />{isDesktop ? 'Stars' : ''}</a>
              {user.username === username && <a className={user.username === username && 'mr-4'} href={`/${username}/uploads`}><i className='icon icon-upload' />{isDesktop ? 'Uploads' : ''}</a>}
              {user.username === username && <a className='' href={`/${username}/recommendations`}><i className='icon icon-thumbs-up' />{isDesktop ? 'Recommendations' : ''}</a>}
            </div>
            <h5 className='my-0'>{username}</h5>
          </div>
        </div>
        <div className='card-body'>
          {userMetrics.profile && <div className='flex-d'>
            <div className='row'>
              <div className='col'>
                <div className='card-title ls-reference'>
                  <div className='d-flex'>
                    <h5 className='my-0 mr-auto'>{fullName || username}{credentials ? `, ${credentials}` : ''}</h5>
                    {userLoggedIn && !isOwnHome && <AsyncStar stars={stars} dispatch={dispatch} refUUID={userMetrics.profile.uuid} isDesktop={isDesktop} />}
                  </div>
                </div>
                <div className='font-weight-normal'>{username}</div>
                {location && <div>{location}</div>}
                {bio && <div className='font-weight-normal'>{bio}</div>}
              </div>
              {(starCount > 0 || moduleStarCount > 0 || impact > 0 || reach > 0) && <div className='col'>
                {/* <div className='card-header ls-reference'>
                <div className='d-flex'>
                  <h5 className='my-0 mr-auto'>Activity</h5>
                </div>
              </div> */}
                <div className=''>
                  <h5 className='mb-3 text-center'>Activity</h5>
                  <ul className='card-menu mb-0 text-center'>
                    <span className='card-menu-item text-inherit'>
                      <h3 className='icon icon-line-graph' />
                      <h6 className=''>
                        {'Impact'}
                      </h6>
                      <h6 className='my-0 font-weight-normal'>{Math.floor(impact)}</h6>
                    </span>
                    <span className='card-menu-item text-inherit'>
                      <h3 className='icon icon-bar-graph' />
                      <h6 className=''>
                        {'Reach'}
                      </h6>
                      <h6 className='my-0 font-weight-normal'>{reach} users</h6>
                    </span>
                    <span className='card-menu-item text-inherit'>
                      <h3 className='icon icon-star' />
                      <h6 className=''>
                        {'Stars'}
                      </h6>
                      <h6 className='my-0 font-weight-normal'>{starCount + moduleStarCount}</h6>
                    </span>
                  </ul>
                </div>
              </div>}
            </div>
          </div>}
        </div>
      </div>
      <div>
        {recentModules.length + activeModules.length + recentStars.length + trendingModules.length > 0 && <div>
          {/* {profile} */}
          <ModulesSummary trendingModules={trendingModules} activeModules={activeModules} recentModules={recentModules} />
          {recentStars.length > 0 && <div>
            <div className='media-list'>
              <div className='bg-light media list-group-item'>
                <h5 className='my-auto ls-reference'>Stars</h5>
              </div>
              <UserModule
                user={user}
                module={recentStarsModule}
                stars={stars}
                history={history}
                storage={storage}
                dispatch={dispatch}
                isDesktop={isDesktop}
                search={search}
              />
            </div>
          </div>}
          {}
          {}
        </div>}
      </div>
    </div>
  )
}

UserContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  module: PropTypes.object.isRequired,
  stars: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  storage: PropTypes.object.isRequired,
  search: PropTypes.object.isRequired
}

const UserView = connect(mapStateToProps, mapDispatchToProps)(UserContainer)

export default UserView
