import {
  REINITIALIZE_STORE
} from '../constants'

const STATE_USER_METRICS = {
  profile: {},
  metrics: {},
  recentStars: [],
  recentModules: [],
  trendingModules: [],
  activeModules: []
}

function userMetrics (state = STATE_USER_METRICS, action) {
  switch (action.type) {
    case REINITIALIZE_STORE: {
      return action.store.userMetrics
    }
    default:
      return state
  }
}

export default userMetrics
