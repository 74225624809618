import React from 'react'
import PropTypes from 'prop-types'
import { removeFromModule } from '../actions'

const RemoveComponentButton = ({
  dispatch,
  rearranging,
  moduleTitle,
  uuid
}) => {
  const onRemove = (evt) => {
    evt.stopPropagation()
    dispatch(removeFromModule(moduleTitle, uuid))
  }

  return (
    <button className='btn btn-small btn-danger' onClick={onRemove} ><i className='icon icon-cross mr-2' />Delete</button>
  )
}

RemoveComponentButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  rearranging: PropTypes.bool.isRequired,
  moduleTitle: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired
}

export default RemoveComponentButton
