import React from 'react'

const PageButtons = ({
  pages,
  activePage,
  searchUrl
}) => {
  let buttons = []
  let liClassFirst = activePage === 1 ? 'page-item active' : 'page-item'
  let liClassLast = activePage === pages ? 'page-item active' : 'page-item'
  // console.log('activePage: ' + activePage)
  // console.log('pages: ' + pages)
  if (pages < 11) {
    for (let page = 1; page <= pages; page++) {
      let liClass = activePage === page ? 'page-item active' : 'page-item'
      // console.log('liClass(' + i + '): ' + liClass)
      buttons.push(<li className={liClass} key={page} ><a className='page-link' href={`${searchUrl}&page=${page}`} >{page}</a></li>)
    }
  } else if (activePage < 8) {
    for (let page = 1; page < 9; page++) {
      let liClass = activePage === page ? 'page-item active' : 'page-item'
      buttons.push(<li className={liClass} key={page} ><a className='page-link' href={`${searchUrl}&page=${page}`} >{page}</a></li>)
    }
    buttons.push(<li className='page-item disabled' key='ellipsis' ><a className='page-link' role='button' aria-label='ellipsis' ><span aria-hidden='true' >&hellip;</span><span className='sr-only'>Ellipsis</span></a></li>)
    buttons.push(<li className={liClassLast} key={pages} ><a className='page-link' href={`${searchUrl}&page=${pages}`} >{pages}</a></li>)
  } else if (pages - activePage < 3) {
    buttons.push(<li className={liClassFirst} key={1} ><a className='page-link' href={`${searchUrl}&page=1`} >{1}</a></li>)
    buttons.push(<li className='page-item disabled' key='ellipsis' ><a className='page-link' role='button' aria-label='ellipsis' ><span aria-hidden='true' >&hellip;</span><span className='sr-only'>Ellipsis</span></a></li>)
    for (let page = pages - 7; page <= pages; page++) {
      let liClass = activePage === page ? 'page-item active' : 'page-item'
      buttons.push(<li className={liClass} key={page} ><a className='page-link' href={`${searchUrl}&page=${page}`} >{page}</a></li>)
    }
  } else {
    buttons.push(<li className={liClassFirst} key={1} ><a className='page-link' href={`${searchUrl}&page=1`} >{1}</a></li>)
    buttons.push(<li className='page-item disabled' key='ellipsis' ><a className='page-link' role='button' aria-label='ellipsis' ><span aria-hidden='true' >&hellip;</span><span className='sr-only'>Ellipsis</span></a></li>)
    for (let page = activePage - 2; page <= activePage + 3; page++) {
      let liClass = activePage === page ? 'page-item active' : 'page-item'
      buttons.push(<li className={liClass} key={page} ><a className='page-link' href={`${searchUrl}&page=${page}`} >{page}</a></li>)
    }
    buttons.push(<li className='page-item disabled' key='ellipsis2' ><a className='page-link' role='button' aria-label='ellipsis' ><span aria-hidden='true' >&hellip;</span><span className='sr-only'>Ellipsis</span></a></li>)
    buttons.push(<li className={liClassLast} key={pages} ><a className='page-link' href={`${searchUrl}&page=${pages}`} >{pages}</a></li>)
  }
  return buttons
}

export default PageButtons
