import React, {Component} from 'react'
import {Portal} from 'react-portal'
import PropTypes from 'prop-types'

class LargeModalCard extends Component {
  render () {
    const {onHide} = this.props
    return (
      <Portal node={document.getElementById('react-modal')}>
        <div onClick={onHide} className='md-overlay md-show-overlay' />
        <div onClick={e => e.stopPropagation()} className='md-modal-large md-show'>
          {/* change to card */}
          <div className='card my-auto mx-auto'>
            {this.props.children}
          </div>
        </div>
      </Portal>
    )
  }
}

LargeModalCard.propTypes = {
  onHide: PropTypes.func.isRequired
}

export default LargeModalCard
