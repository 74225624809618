const RemoveFromModule = ({
  onRemove,
  skipConfirm
}) => {
  if (skipConfirm) {
    return (
      <a tabIndex='-1' role='button' onClick={onRemove} title='Remove this item from the module.' ><i className='icon icon-cross' /></a>
    )
  } else {
    return (
      <div className='btn-group'>
        <a tabIndex='-1' role='button' onClick={onRemove}><i className='icon icon-cross' /></a>
      </div>
    )
  }
}

export default RemoveFromModule
