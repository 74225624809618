import React, {Component} from 'react'
import { connect } from 'react-redux'
import AuthButton from '../components/AuthButton'
import AsyncUploadLibrary from '../containers/AsyncUploadLibrary'
import AsyncNewModule from '../containers/AsyncNewModule'
import { logout } from '../actions'

const mapStateToProps = (state) => {
  return {
    user: state.user,
    search: state.search,
    stars: state.stars,
    history: state.history,
    storage: state.storage,
    client: state.client,
    alert: state.alert
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch
  }
}

class Search extends Component {
  constructor (props) {
    super(props)
    this.state = {searchSelected: false}
    this.selectSearch = this.selectSearch.bind(this)
    this.unselectSearch = this.unselectSearch.bind(this)
  }
  selectSearch (e) {
    this.setState({searchSelected: true})
    e.preventDefault()
  }
  unselectSearch (e) {
    this.setState({searchSelected: false})
    e.preventDefault()
  }
  render () {
    const {dispatch, user, storage, client, alert} = this.props
    const {isAuthenticated} = user
    const {searchSelected} = this.state
    const isDesktop = client.type.localeCompare('desktop') === 0 || client.type.localeCompare('tablet') === 0
    const existingSha1s = storage.library.map(item => item.sha1)

    return (
      <nav className='navbar navbar-expand navbar-dark bg-dark app-navbar d-print-none search-navbar' >
        <div className='container'>
          <div className='flex-column w-100'>
            <div className='flex-row flex-nowrap'>
              <ul className='navbar-nav'>
                {!searchSelected && <a className='navbar-brand my-auto mb-0 h1' href={isAuthenticated ? `/${user.username}` : '/about'}>{isDesktop ? `Litsignal` : `Ls`}</a>}
                {searchSelected && <a className='navbar-brand my-auto mb-0 h1' href={isAuthenticated ? `/${user.username}` : '/about'} onClick={this.unselectSearch}><span className='icon icon-cross' /></a>}
                {isDesktop && <form action='/search' method='get' className='form-inline mr-auto' style={{width: '33%'}}>
                  <div className='input-group input-group-sm my-auto' style={{width: '100%'}}>
                    <input type='text' className='form-control' name='term' id='term' placeholder='Search' aria-label='Username' aria-describedby='basic-addon1' />
                    <div className='input-group-append'>
                      <button className='btn btn-light' type='submit'><span className='icon icon-magnifying-glass' /></button>
                    </div>
                  </div>
                </form>}
                {!isDesktop && !searchSelected && <a className='nav-link mr-auto' role='button' onClick={this.selectSearch} ><i className='icon icon-magnifying-glass' /></a>}
                {!isDesktop && searchSelected && <form action='/search' method='get' className='form-inline mr-auto' style={{width: '100%'}}>
                  <div className='input-group input-group-sm my-auto' style={{width: '100%'}}>
                    <input type='text' className='form-control' autoFocus name='term' id='term' placeholder='Search' aria-label='Username' aria-describedby='basic-addon1' />
                    <div className='input-group-append'>
                      <button className='btn btn-light' type='submit'><span className='icon icon-magnifying-glass' /></button>
                    </div>
                    {/* <div className='input-group-append'>
                      <button className='btn btn-light' type='button' onClick={this.unselectSearch}><span className='icon icon-cross' /></button>
                    </div> */}
                  </div>
                </form>}
                {isAuthenticated && !searchSelected && <li className='nav-item'>
                  <AsyncNewModule dispatch={dispatch} isDesktop={isDesktop} />
                </li>}
                {isAuthenticated && !searchSelected && <li className='nav-item dropdown'>
                  <a className='nav-link dropdown-toggle' id='navbarDropdown' tabIndex='-1' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    {user.username}
                  </a>
                  <div className='dropdown-menu dropdown-menu-right' aria-labelledby='navbarDropdown'>
                    <a className='dropdown-item' href={`/${user.username}/collaboration`}><span className='icon icon-swap' />{'Collaboration'}</a>
                    <AsyncUploadLibrary dispatch={dispatch} existingSha1s={existingSha1s} />
                    <a className='dropdown-item' href='/settings/user'><span className='icon icon-user' />Profile</a>
                    <a className='dropdown-item' tabIndex='-1' role='button' href='/logout' >
                      <i className='icon icon-log-out' />{'Logout'}
                    </a>
                  </div>
                </li>}
                {!isAuthenticated && !searchSelected && <li className='nav-item'>
                  <AuthButton alert={alert} dispatch={dispatch} />
                </li>}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

const SearchNavbar = connect(mapStateToProps, mapDispatchToProps)(Search)

export default SearchNavbar
