const LoginForm = ({
  login,
  onUsernameChange,
  onPasswordChange,
  showRegister,
  showReset,
  username,
  password,
  alert
}) => (
  <form onSubmit={login}>
    <h5 className='card-title'>Login</h5>
    <div className='form-group'>
      <label htmlFor='usernamefield'>Username</label>
      <input
        required
        name='username'
        id='usernamefield'
        className='form-control'
        type='text'
        placeholder='username or email'
        onChange={onUsernameChange}
        value={username}
        autoFocus />
    </div>
    <div className='form-group'>
      <label htmlFor='passwordfield'>Password</label>
      <input
        required
        id='passwordfield'
        className='form-control'
        name='password'
        type='password'
        placeholder='password'
        onChange={onPasswordChange}
        value={password} />
    </div>
    <div className='form-group'>
      <button className='btn btn-primary' type='submit' disabled={!username || !password}>Login</button>
    </div>
    <p>
      Not a member yet? <a tabIndex='-1' role='button' onClick={showRegister}>Sign up here</a>.
      <br />
      Forgot your password? <a tabIndex='-1' role='button'onClick={showReset}>Reset it here</a>.
    </p>
  </form>
)

export default LoginForm
