import React from 'react'

const PageButtons = ({
  pages,
  activePage,
  handleSelect
}) => {
  let buttons = []
  let liClassFirst = activePage === 1 ? 'page-item active' : 'page-item'
  let liClassLast = activePage === pages ? 'page-item active' : 'page-item'
  // console.log('activePage: ' + activePage)
  // console.log('pages: ' + pages)
  if (pages < 11) {
    for (let i = 1; i <= pages; i++) {
      let liClass = activePage === i ? 'page-item active' : 'page-item'
      // console.log('liClass(' + i + '): ' + liClass)
      buttons.push(<li className={liClass} key={i} ><a data-tag={i} className='page-link' tabIndex='-1' role='button' onClick={handleSelect}>{i}</a></li>)
    }
  } else if (activePage < 8) {
    for (let i = 1; i < 9; i++) {
      let liClass = activePage === i ? 'page-item active' : 'page-item'
      buttons.push(<li className={liClass} key={i} ><a data-tag={i} className='page-link' tabIndex='-1' role='button' onClick={handleSelect}>{i}</a></li>)
    }
    buttons.push(<li className='page-item disabled' key='ellipsis' ><a className='page-link' role='button' aria-label='ellipsis' ><span aria-hidden='true' >&hellip;</span><span className='sr-only'>Ellipsis</span></a></li>)
    buttons.push(<li className={liClassLast} key={pages} ><a data-tag={pages} className='page-link' tabIndex='-1' role='button' onClick={handleSelect}>{pages}</a></li>)
  } else if (pages - activePage < 3) {
    buttons.push(<li className={liClassFirst} key={1} ><a data-tag={1} className='page-link' tabIndex='-1' role='button' onClick={handleSelect}>{1}</a></li>)
    buttons.push(<li className='page-item disabled' key='ellipsis' ><a className='page-link' role='button' aria-label='ellipsis' ><span aria-hidden='true' >&hellip;</span><span className='sr-only'>Ellipsis</span></a></li>)
    for (let i = pages - 7; i <= pages; i++) {
      let liClass = activePage === i ? 'page-item active' : 'page-item'
      buttons.push(<li className={liClass} key={i} ><a data-tag={i} className='page-link' tabIndex='-1' role='button' onClick={handleSelect}>{i}</a></li>)
    }
  } else {
    buttons.push(<li className={liClassFirst} key={1} ><a data-tag={1} className='page-link' tabIndex='-1' role='button' onClick={handleSelect}>{1}</a></li>)
    buttons.push(<li className='page-item disabled' key='ellipsis' ><a className='page-link' role='button' aria-label='ellipsis' ><span aria-hidden='true' >&hellip;</span><span className='sr-only'>Ellipsis</span></a></li>)
    for (let i = activePage - 2; i <= activePage + 3; i++) {
      let liClass = activePage === i ? 'page-item active' : 'page-item'
      buttons.push(<li className={liClass} key={i} ><a data-tag={i} className='page-link' tabIndex='-1' role='button' onClick={handleSelect}>{i}</a></li>)
    }
    buttons.push(<li className='page-item disabled' key='ellipsis2' ><a className='page-link' role='button' aria-label='ellipsis' ><span aria-hidden='true' >&hellip;</span><span className='sr-only'>Ellipsis</span></a></li>)
    buttons.push(<li className={liClassLast} key={pages} ><a data-tag={pages} className='page-link' tabIndex='-1' role='button' onClick={handleSelect}>{pages}</a></li>)
  }
  return buttons
}

export default PageButtons
