import React from 'react'
import { connect } from 'react-redux'
// import {dissociateLibraryItem} from '../actions'

const mapStateToProps = (state, ownProps) => {
  return {
    library: state.storage.library,
    componentUUID: ownProps.componentUUID
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

const DissociateLibraryItemContainer = ({
  library,
  componentUUID,
  dispatch
}) => {
  const onDissociate = (e) => {
    e.stopPropagation()
    const matchingItems = library.filter(item => item.componentUUID === componentUUID)
    if (matchingItems.length > 0) return new Error('Duplicate components in library.')
    // const sha1 = matchingItems[0].sha1
    // dispatch(dissociateLibraryItem(sha1, componentUUID))
  }
  if (componentUUID) {
    return (
      <a tabIndex='-1' role='button' onClick={onDissociate} title='Dissociate PDF'>Dissociate this PDF document from this reference.</a>
    )
  } else {
    return null
  }
}

const DissociateLibraryItem = connect(mapStateToProps, mapDispatchToProps)(DissociateLibraryItemContainer)

export default DissociateLibraryItem
