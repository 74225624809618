import User from '../components/User'

const UserSearchResults = ({
  results,
  dispatch,
  noFilter,
  stars,
  isDesktop,
  user
}) => {
  const userLoggedIn = !!user.username
  if (results && results.length > 0) {
    return (
      <div className='container'>
        <div className='dropdown-menu w-100 py-0' style={{display: 'block'}} >
          <div className='media-list my-0'>
            {results.map((component, index, array) => {
              return (
                <User
                  dispatch={dispatch} stars={stars} user={component} key={component.uuid} index={index}
                  userLoggedIn={userLoggedIn} isDesktop={isDesktop}
                />
              )
            })}
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default UserSearchResults
