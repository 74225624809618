import React, { Component } from 'react'
import PropTypes from 'prop-types'
import fileDialog from 'file-dialog'
import sha1 from 'js-sha1'
import async from 'async'
import {getEncodedFilename} from '../helpers'

class Upload extends Component {
  constructor (props) {
    super(props)
    this.openUpload = this.openUpload.bind(this)
    this.upload = this.upload.bind(this)
    this.dragenter = this.dragenter.bind(this)
    this.dragover = this.dragover.bind(this)
    this.drop = this.drop.bind(this)
    this.handleFileSelect = this.handleFileSelect.bind(this)
    this.handleFiles = this.handleFiles.bind(this)
  }

  openUpload () {
    fileDialog({multiple: this.props.multipleFiles, name: 'uploads'})
      .then(files => { return {files, existingSha1s: this.props.existingSha1s} })
      .then(this.handleFiles)
  }

  upload (files, fileSha1Map) {
    this.props.onUpload(files, fileSha1Map)
  }

  handleFileSelect (e) {
    this.handleFiles({files: e.target.files, existingSha1s: this.props.existingSha1s})
    e.preventDefault()
  }

  handleFiles (params) {
    const {files, existingSha1s} = params
    console.log(`existingSha1s: ${JSON.stringify(existingSha1s)}`)
    // figure out a way to remove duplicate files before upload
    let filesArray = Array.from(files)
    // for (let i = 0; i < files.length; i++) {
    //   filesArray.push(files.item(i))
    // }
    async.parallel(filesArray.map(file => {
      return (callback) => {
        var reader = new FileReader() // eslint-disable-line
        reader.onload = function (event) {
          const filename = getEncodedFilename(file.name)
          console.log(`filename: ${filename}`)
          const sha1Digest = sha1(event.target.result)
          console.log(`sha1Digest: ${sha1Digest}`)
          let fileSha1 = {filename, sha1: sha1Digest}
          if (existingSha1s.indexOf(sha1Digest) === -1) {
            callback(null, fileSha1)
          } else {
            callback(null, null)
          }
        }
        reader.readAsArrayBuffer(file)
      }
    }),
    (err, results) => {
      if (err) return err
      console.log('async.results: ' + JSON.stringify(results))
      // should be able to filter out duplicate files and entries from fileSha1Map
      const fileSha1Map = results.filter(result => result !== null)
      console.log(`filtered fileSha1Map: ${JSON.stringify(fileSha1Map)}`)
      console.log(`filesArray: ${filesArray}`)
      console.log(`filesArray[0].name: ${filesArray[0].name}`)
      const filteredFiles = filesArray.filter(file => {
        console.log(`file.name: ${file.name}`)
        console.log(`fileSha1Map.map(filename): ${fileSha1Map.map(fileSha1 => decodeURIComponent(fileSha1.filename))}`)
        return fileSha1Map.map(fileSha1 => decodeURIComponent(fileSha1.filename)).indexOf(file.name) > -1
      })
      console.log(`filteredFiles: ${filteredFiles}`)
      this.upload(filteredFiles, fileSha1Map)
    })
  }

  dragenter (e) {
    e.stopPropagation()
    e.preventDefault()
  }

  dragover (e) {
    e.stopPropagation()
    e.preventDefault()
  }

  drop (e) {
    e.stopPropagation()
    e.preventDefault()

    var dt = e.dataTransfer
    var files = dt.files

    this.handleFiles({files, existingSha1s: this.props.existingSha1s})
  }

  render () {
    const { label = 'Upload', asLink = false, asDropdown = false, asNav = false, icon = 'upload' } = this.props
    if (asLink) {
      return (
        <a tabIndex='-1' role='button' id='upload-button'
          onClick={this.openUpload}
          onDragEnter={this.dragenter}
          onDragOver={this.dragover}
          onDrop={this.drop}>
          <i className={`icon icon-${icon}`} />{label}
        </a>
      )
    }
    if (asNav) {
      return (
        <a tabIndex='-1' role='button' id='upload-button' className='nav-link'
          onClick={this.openUpload}
          onDragEnter={this.dragenter}
          onDragOver={this.dragover}
          onDrop={this.drop}>
          <i className={`icon icon-${icon}`} />{label}
        </a>
      )
    }
    if (asDropdown) {
      return (
        <a className='dropdown-item' tabIndex='-1' role='button' id='upload-button'
          onClick={this.openUpload}
          onDragEnter={this.dragenter}
          onDragOver={this.dragover}
          onDrop={this.drop}>
          <i className={`icon icon-${icon}`} />{label}
        </a>
      )
    }
    return (
      <button id='upload-button' title='Click to open the file browser or drag and drop files here.'
        onClick={this.openUpload}
        onDragEnter={this.dragenter}
        onDragOver={this.dragover}
        onDrop={this.drop}>
        <i className={`icon icon-${icon}`} />{label}
      </button>
    )
  }
}

Upload.propTypes = {
  onUpload: PropTypes.func.isRequired,
  multipleFiles: PropTypes.bool,
  asLink: PropTypes.bool,
  asDropdown: PropTypes.bool,
  existingSha1s: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default Upload
