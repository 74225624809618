import React, {Component} from 'react'
import PropTypes from 'prop-types'
import AsyncStar from '../containers/AsyncStar'
import AsyncShare from '../containers/AsyncShare'
import Recommendation from './Recommendation'

class User extends Component {
  constructor (props) {
    super(props)
    this.state = {
      expanded: false
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (e) {
    // if (e.target.nodeName === 'A') {
    //   return
    // }
    // this.setState({
    //   expanded: !this.state.expanded
    // })
  }
  render () {
    const { dispatch, stars, user, isDesktop, userLoggedIn } = this.props
    const { uuid, recommendation } = user
    const { fullName, username } = user.metadata
    let fullNameComponent
    if (fullName) {
      fullNameComponent = (
        <div>
          {fullName}
        </div>
      )
    }
    const reference = (
      <div className='ls-reference-title'>
        <a href={`/${username}`}>
          <i className='icon icon-user' />{username}
        </a>
        {fullNameComponent}
      </div>
    )

    let header
    if (recommendation) {
      header = <Recommendation dispatch={dispatch} recommendation={recommendation} />
    }
    const referenceClass = this.state.expanded ? 'media list-group-item ls-reference ls-reference-expanded' : 'media list-group-item ls-reference'
    // const indicatorClass = this.state.expanded ? 'icon icon-chevron-up' : 'icon icon-chevron-down'

    return (
      <li onClick={this.handleClick} className={referenceClass}>
        {header}
        {reference}
        {userLoggedIn && <div className='ls-controls'>

          <AsyncStar dispatch={dispatch} stars={stars} refUUID={uuid} isDesktop={isDesktop} />
          <AsyncShare dispatch={dispatch} refUUID={uuid} reference={reference} isDesktop={isDesktop} />

        </div>}
      </li>
    )
  }
}

User.propTypes = {
  dispatch: PropTypes.func.isRequired,
  stars: PropTypes.object.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string,
    fullName: PropTypes.string
  }).isRequired,
  userLoggedIn: PropTypes.bool
}

export default User
