import { BookTitleRow, BookAuthorRow } from './ReferenceComponents'

const BookInfo = ({
  book
}) => {
  return (
    <div style={{lineHeight: 1.4}}>
      <BookTitleRow title={book.title} isbn13={book.isbn13} />
      <BookAuthorRow author={book.author} />
    </div>
  )
}

export default BookInfo
