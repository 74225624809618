import React from 'react'
import ModuleView from './ModuleView'
import SearchView from './SearchView'
import HomeView from './HomeView'
import UserView from './UserView'
import Footer from '../components/Footer'
import Alert from '../components/Alert'
// import MaintenanceNav from '../components/MaintenanceNav'
// import ReleaseNotes from '../components/ReleaseNotes'
import NavbarView from './NavbarView'
import UploadLibraryProgress from '../components/UploadLibraryProgress'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
  return {
    module: state.module,
    search: state.search,
    userMetrics: state.userMetrics,
    notifications: state.notifications
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch
  }
}

class LayoutContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {position: document.body.scrollHeight > window.screen.availHeight ? 'relative' : 'absolute'}
  }
  render () {
    const {search, module, userMetrics} = this.props
    // console.log(module.title)
    // console.log('uploads: ' + (module && module.title && ['uploads'].indexOf(module.title) > -1).toString())
    return (
      <div id='layout'>
        <div id='react-modal' />
        <Alert />
        <NavbarView />
        <UploadLibraryProgress />
        {/* {notifications && notifications.maintenance && <MaintenanceNav />} */}
        <div className='container ls-primary'>
          {/* {notifications && notifications.release && <ReleaseNotes />} */}
          {search && ((!module.title && !userMetrics.metrics) || search.components.length > 0) && <SearchView />}
          {module && module.title && ['uploads', 'modules', 'stars', 'recommendations'].indexOf(module.title) > -1 && <UserView />}
          {module && module.title && ['uploads', 'modules', 'stars', 'recommendations'].indexOf(module.title) === -1 && <ModuleView />}
          {userMetrics && userMetrics.metrics && <HomeView />}
        </div>
        <Footer position={this.state.position} />
      </div>
    )
  }
}

const RootLayout = connect(mapStateToProps, mapDispatchToProps)(LayoutContainer)

export default RootLayout
