export default {
  API_URL: '/api',
  STORAGE_API_URL: '',
  API_HEADERS: {
    'Content-Type': 'application/json'
  }
}

// API URLs

export const PROD_API_URL = 'https://litsignal.com/api'
export const API_URL = (typeof location === 'undefined')
  ? '/api'
  : location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/api' // eslint-disable-line

// CLOUDFRONT

export const CLOUDFRONT_PROD_URL = 'https://assets.litsignal.com'
export const CLOUDFRONT_DEV_URL = 'https://dp80a36u5v9i0.cloudfront.net'

// API PATHS

export const STORAGE_SYNC_PATH = '/library/sync'

// State

export const STATE_USER = {
  username: '',
  podcast: {},
  isAuthenticated: false,
  auth: {
    result: '',
    message: ''
  }
}

export const STATE_CLIENT = {
  type: 'desktop'
}

export const STATE_REF_COLLECTIONS = []

export const STATE_STARS = {
  componentUUIDs: [],
  starring: STATE_REF_COLLECTIONS,
  unstarring: STATE_REF_COLLECTIONS
}
export const STATE_STORAGE = {
  library: [], // {componentUUID, sha1}
  unmatchedFiles: [], // {sha1, originalName}
  uploading: false
}

export const SAVING = 'SAVING'
export const SAVED = 'SAVED'
export const CHANGING = 'CHANGING'
export const MODULE_CHANGING = 'MODULE_CHANGING'

export const STATE_MODULE = {
  author: '',
  authorProfile: {},
  title: '',
  description: '',
  components: [],
  tags: [],
  removing: STATE_REF_COLLECTIONS,
  rearranging: false,
  filter: '',
  editing: false,
  uploading: false,
  saveState: SAVED,
  page: 1,
  visible: [],
  versionUUIDs: [],
  unpublished: true
}

export const STATE_ARTICLE = {
  uuid: '',
  pmid: '',
  title: '',
  authors: '',
  date: '',
  volume: '',
  issue: '',
  pages: '',
  doi: '',
  pii: '',
  pmcid: '',
  journal: {
    name: ''
  }
}

export const STATE_DIMENSIONS = {
  scrollHeight: 0,
  innerHeight: 0,
  overflow: false
}

export const STATE_BOOK = {
  uuid: '',
  title: '',
  author: '',
  isbn13: '',
  pubDate: ''
}

export const STATE_LINK = {
  uuid: '',
  title: '',
  url: '',
  host: ''
}

// HTTP Methods

export const GET = 'GET'
export const PUT = 'PUT'
export const PATCH = 'PATCH'
export const POST = 'POST'
export const DELETE = 'DELETE'

// Star actions

export const STAR = 'STAR'
export const STAR_REQUEST = 'STAR_REQUEST'
export const STAR_SUCCESS = 'STAR_SUCCESS'
export const STAR_ERROR = 'STAR_ERROR'

export const UNSTAR = 'UNSTAR'
export const UNSTAR_REQUEST = 'UNSTAR_REQUEST'
export const UNSTAR_SUCCESS = 'UNSTAR_SUCCESS'
export const UNSTAR_ERROR = 'UNSTAR_ERROR'

// Reference actions

export const ADD_TO_MODULE = 'ADD_TO_MODULE'
export const ADD_TO_MODULE_REQUEST = 'ADD_TO_MODULE_REQUEST'
export const ADD_TO_MODULE_SUCCESS = 'ADD_TO_MODULE_SUCCESS'
export const ADD_TO_MODULE_ERROR = 'ADD_TO_MODULE_ERROR'

export const CREATE_MODULE = 'CREATE_MODULE'
export const CREATE_MODULE_REQUEST = 'CREATE_MODULE_REQUEST'
export const CREATE_MODULE_SUCCESS = 'CREATE_MODULE_SUCCESS'
export const CREATE_MODULE_ERROR = 'CREATE_MODULE_ERROR'

// Module actions

export const PUBLISH_MODULE_REQUEST = 'PUBLISH_MODULE_REQUEST'
export const PUBLISH_MODULE_SUCCESS = 'PUBLISH_MODULE_SUCCESS'
export const PUBLISH_MODULE_ERROR = 'PUBLISH_MODULE_ERROR'

export const REMOVE_FROM_MODULE = 'REMOVE_FROM_MODULE'
export const REMOVE_FROM_MODULE_REQUEST = 'REMOVE_FROM_MODULE_REQUEST'
export const REMOVE_FROM_MODULE_SUCCESS = 'REMOVE_FROM_MODULE_SUCCESS'
export const REMOVE_FROM_MODULE_ERROR = 'REMOVE_FROM_MODULE_ERROR'

export const MOVE_REFERENCE = 'MOVE_REFERENCE'
export const MOVE_REFERENCE_REQUEST = 'MOVE_REFERENCE_REQUEST'
export const MOVE_REFERENCE_SUCCESS = 'MOVE_REFERENCE_SUCCESS'
export const MOVE_REFERENCE_ERROR = 'MOVE_REFERENCE_ERROR'

// Library actions

export const STORAGE_SYNC = 'STORAGE_SYNC'
export const STORAGE_SYNC_REQUEST = 'STORAGE_SYNC_REQUEST'
export const STORAGE_SYNC_SUCCESS = 'STORAGE_SYNC_SUCCESS'
export const STORAGE_SYNC_ERROR = 'STORAGE_SYNC_ERROR'

// Recommendation actions

export const RECOMMEND_REQUEST = 'RECOMMEND_REQUEST'
export const RECOMMEND_SUCCESS = 'RECOMMEND_SUCCESS'
export const RECOMMEND_ERROR = 'RECOMMEND_ERROR'
export const ACCEPT_RECOMMENDATION_REQUEST = 'ACCEPT_RECOMMENDATION_REQUEST'
export const ACCEPT_RECOMMENDATION_SUCCESS = 'ACCEPT_RECOMMENDATION_SUCCESS'
export const ACCEPT_RECOMMENDATION_ERROR = 'ACCEPT_RECOMMENDATION_ERROR'
export const DECLINE_RECOMMENDATION_REQUEST = 'DECLINE_RECOMMENDATION_REQUEST'
export const DECLINE_RECOMMENDATION_SUCCESS = 'DECLINE_RECOMMENDATION_SUCCESS'
export const DECLINE_RECOMMENDATION_ERROR = 'DECLINE_RECOMMENDATION_ERROR'

// Suggestion actions

export const SUGGEST_REQUEST = 'SUGGEST_REQUEST'
export const SUGGEST_SUCCESS = 'SUGGEST_SUCCESS'
export const SUGGEST_ERROR = 'SUGGEST_ERROR'
export const ACCEPT_SUGGESTION_REQUEST = 'ACCEPT_SUGGESTION_REQUEST'
export const ACCEPT_SUGGESTION_SUCCESS = 'ACCEPT_SUGGESTION_SUCCESS'
export const ACCEPT_SUGGESTION_ERROR = 'ACCEPT_SUGGESTION_ERROR'
export const DECLINE_SUGGESTION_REQUEST = 'DECLINE_SUGGESTION_REQUEST'
export const DECLINE_SUGGESTION_SUCCESS = 'DECLINE_SUGGESTION_SUCCESS'
export const DECLINE_SUGGESTION_ERROR = 'DECLINE_SUGGESTION_ERROR'

// LiveFilter actions

export const FILTER_MODULE = 'FILTER_MODULE'
export const UPDATE_FILTER = 'UPDATE_FILTER'
export const SELECT_PAGE = 'SELECT_PAGE'

// History actions

export const MODULE_VISITED = 'MODULE_VISITED'
export const MODULE_MODIFIED = 'MODULE_MODIFIED'

// Alert actions

export const DISMISS_ALERT = 'DISMISS_ALERT'
export const STATE_ALERT = 'STATE_ALERT'
export const TRIGGER_ALERT = 'TRIGGER_ALERT'

export const EDIT_MODULE = 'EDIT_MODULE'
export const VIEW_MODULE = 'VIEW_MODULE'

// Share link actions

export const VALIDATE_URL_ERROR = 'VALIDATE_URL_ERROR'

export const SHARE_LINK_REQUEST = 'SHARE_LINK_REQUEST'
export const SHARE_LINK_SUCCESS = 'SHARE_LINK_SUCCESS'
export const SHARE_LINK_ERROR = 'SHARE_LINK_ERROR'

export const UPDATE_README_REQUEST = 'UPDATE_README_REQUEST'
export const UPDATE_README_SUCCESS = 'UPDATE_README_SUCCESS'
export const UPDATE_README_ERROR = 'UPDATE_README_ERROR'

export const UPDATE_DESCRIPTION_REQUEST = 'UPDATE_DESCRIPTION_REQUEST'
export const UPDATE_DESCRIPTION_SUCCESS = 'UPDATE_DESCRIPTION_SUCCESS'
export const UPDATE_DESCRIPTION_ERROR = 'UPDATE_DESCRIPTION_ERROR'

export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_ERROR = 'REGISTER_ERROR'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR'

export const UPLOAD_ATTACHMENTS_REQUEST = 'UPLOAD_ATTACHMENTS_REQUEST'
export const UPLOAD_ATTACHMENTS_SUCCESS = 'UPLOAD_ATTACHMENTS_SUCCESS'
export const UPLOAD_ATTACHMENTS_ERROR = 'UPLOAD_ATTACHMENTS_ERROR'

export const REMOVE_ATTACHMENT_REQUEST = 'REMOVE_ATTACHMENT_REQUEST'
export const REMOVE_ATTACHMENT_SUCCESS = 'REMOVE_ATTACHMENT_SUCCESS'
export const REMOVE_ATTACHMENT_ERROR = 'REMOVE_ATTACHMENT_ERROR'

export const UPLOAD_LIBRARY_REQUEST = 'UPLOAD_LIBRARY_REQUEST'
export const UPLOAD_LIBRARY_SUCCESS = 'UPLOAD_LIBRARY_SUCCESS'
export const UPLOAD_LIBRARY_ERROR = 'UPLOAD_LIBRARY_ERROR'
export const UPLOAD_LIBRARY_PROGRESS = 'UPLOAD_LIBRARY_PROGRESS'

export const UPLOAD_EPISODE_REQUEST = 'UPLOAD_EPISODE_REQUEST'
export const UPLOAD_EPISODE_SUCCESS = 'UPLOAD_EPISODE_SUCCESS'
export const UPLOAD_EPISODE_ERROR = 'UPLOAD_EPISODE_ERROR'

export const REMOVE_EPISODE_REQUEST = 'REMOVE_EPISODE_REQUEST'
export const REMOVE_EPISODE_SUCCESS = 'REMOVE_EPISODE_SUCCESS'
export const REMOVE_EPISODE_ERROR = 'REMOVE_EPISODE_ERROR'

export const SEARCH_PUBMED_REQUEST = 'SEARCH_PUBMED_REQUEST'
export const SEARCH_PUBMED_SUCCESS = 'SEARCH_PUBMED_SUCCESS'
export const SEARCH_PUBMED_ERROR = 'SEARCH_PUBMED_ERROR'

export const UNDO_PUBMED_SEARCH = 'UNDO_PUBMED_SEARCH'

export const SEARCH_ALL_REQUEST = 'SEARCH_ALL_REQUEST'
export const SEARCH_ALL_SUCCESS = 'SEARCH_ALL_SUCCESS'
export const SEARCH_ALL_ERROR = 'SEARCH_ALL_ERROR'

export const SELECT_PUBMED_PAGE_REQUEST = 'SELECT_PUBMED_PAGE_REQUEST'
export const SELECT_PUBMED_PAGE_SUCCESS = 'SELECT_PUBMED_PAGE_SUCCESS'
export const SELECT_PUBMED_PAGE_ERROR = 'SELECT_PUBMED_PAGE_ERROR'

export const SELECT_LITSIGNAL_PAGE_REQUEST = 'SELECT_LITSIGNAL_PAGE_REQUEST'
export const SELECT_LITSIGNAL_PAGE_SUCCESS = 'SELECT_LITSIGNAL_PAGE_SUCCESS'
export const SELECT_LITSIGNAL_PAGE_ERROR = 'SELECT_LITSIGNAL_PAGE_ERROR'

export const SELECT_SEARCH_ALL = 'SELECT_SEARCH_ALL'
export const SELECT_PUBMED_SEARCH = 'SELECT_PUBMED_SEARCH'
export const SELECT_LITSIGNAL_SEARCH = 'SELECT_LITSIGNAL_SEARCH'
export const SELECT_BOOK_SEARCH = 'SELECT_BOOK_SEARCH'
export const UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM'

export const SEARCH_LITSIGNAL_REQUEST = 'SEARCH_LITSIGNAL_REQUEST'
export const SEARCH_LITSIGNAL_ERROR = 'SEARCH_LITSIGNAL_ERROR'
export const SEARCH_LITSIGNAL_SUCCESS = 'SEARCH_LITSIGNAL_SUCCESS'

export const SEARCH_BOOK_REQUEST = 'SEARCH_BOOK_REQUEST'
export const SEARCH_BOOK_ERROR = 'SEARCH_BOOK_ERROR'
export const SEARCH_BOOK_SUCCESS = 'SEARCH_BOOK_SUCCESS'

export const CLEAR_SEARCH = 'CLEAR_SEARCH'

export const RECONCILE_PUBMED_REQUEST = 'RECONCILE_PUBMED_REQUEST'
export const RECONCILE_PUBMED_SUCCESS = 'RECONCILE_PUBMED_SUCCESS'
export const RECONCILE_PUBMED_ERROR = 'RECONCILE_PUBMED_ERROR'

export const RECONCILE_UPLOAD_REQUEST = 'RECONCILE_UPLOAD_REQUEST'
export const RECONCILE_UPLOAD_SUCCESS = 'RECONCILE_UPLOAD_SUCCESS'
export const RECONCILE_UPLOAD_ERROR = 'RECONCILE_UPLOAD_ERROR'

export const LIBRARY_UPDATED = 'LIBRARY_UPDATED'

export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS'
export const UPLOAD_COMPLETE = 'UPLOAD_COMPLETE'

export const WINDOW_RESIZE = 'WINDOW_RESIZE'

export const ADD_TAG_SUCCESS = 'ADD_TAG_SUCCESS'
export const ADD_TAG_ERROR = 'ADD_TAG_ERROR'
export const ADD_TAG_REQUEST = 'ADD_TAG_REQUEST'
export const REMOVE_TAG_SUCCESS = 'REMOVE_TAG_SUCCESS'
export const REMOVE_TAG_ERROR = 'REMOVE_TAG_ERROR'
export const REMOVE_TAG_REQUEST = 'REMOVE_TAG_REQUEST'

export const PUBLICATION_TYPES = ['addresses', 'autobiography', 'bibliography', 'biography', 'book illustrations', 'case reports', 'classical article', 'clinical conference', 'clinical study', 'clinical trial', 'clinical trial, phase i', 'clinical trial, phase ii', 'clinical trial, phase iii', 'clinical trial, phase iv', 'collected works', 'comment', 'comparative study', 'congresses', 'consensus development conference', 'consensus development conference, nih', 'controlled clinical trial', 'corrected and republished article', 'dataset', 'dictionary', 'directory', 'duplicate publication', 'editorial', 'electronic supplementary materials', 'english abstract', 'ephemera', 'evaluation studies', 'festschrift', 'government publications', 'guideline', 'historical article', 'interactive tutorial', 'interview', 'introductory journal article', 'journal article', 'lectures', 'legal cases', 'legislation', 'letter', 'meta analysis', 'multicenter study', 'news', 'newspaper article', 'observational study', 'overall', 'patient education handout', 'periodical index', 'personal narratives', 'pictorial works', 'popular works', 'portraits', 'practice guideline', 'pragmatic clinical trial', 'publication components', 'publication formats', 'publication type category', 'published erratum', 'randomized controlled trial', 'research support, american recovery and reinvestment act', 'research support, n i h, extramural', 'research support, n i h, intramural', "research support, non u s gov't", "research support, u s gov't, non p h s", "research support, u s gov't, p h s", 'research support, u s government', 'retracted publication', 'retraction of publication', 'review', 'scientific integrity review', 'study characteristics', 'support of research', 'technical report', 'twin study', 'validation studies', 'video audio media', 'webcasts']

export const REINITIALIZE_STORE = 'REINITIALIZE_STORE'

export const DISMISS_RELEASE_NOTES_REQUEST = 'DISMISS_RELEASE_NOTES_REQUEST'
export const DISMISS_RELEASE_NOTES_SUCCESS = 'DISMISS_RELEASE_NOTES_SUCCESS'
export const DISMISS_RELEASE_NOTES_ERROR = 'DISMISS_RELEASE_NOTES_ERROR'

export const DELETE_LIBRARY_ITEM_REQUEST = 'DELETE_LIBRARY_ITEM_REQUEST'
export const DELETE_LIBRARY_ITEM_ERROR = 'DELETE_LIBRARY_ITEM_ERROR'
export const DELETE_LIBRARY_ITEM_SUCCESS = 'DELETE_LIBRARY_ITEM_SUCCESS'
export const PATCH_LIBRARY_ITEM_REQUEST = 'PATCH_LIBRARY_ITEM_REQUEST'
export const PATCH_LIBRARY_ITEM_ERROR = 'PATCH_LIBRARY_ITEM_ERROR'
export const PATCH_LIBRARY_ITEM_SUCCESS = 'PATCH_LIBRARY_ITEM_SUCCESS'

export const REVERT_CHANGES = 'REVERT_CHANGES'
export const REFERENCE_TOUCHED = 'REFERENCE_TOUCHED'
export const CLEAR_PUBMED_SEARCH = 'CLEAR_PUBMED_SEARCH'

/* eslint-disable */
export const README_WELCOME = `### This is your module
The things that are important to you are important to the community, and your contributions are appreciated. Use this space to:
- Share educational materials
- Synthesize research
- Create a reading list
- Publish a podcast

### Getting started
- Click on the 'Edit' button, and add a description. Then click the 'Save' button.
- Click on the 'Edit' button again, modify this text, and save your changes. Check out our [markdown guide](/litsignal/markdown-guide) to familiarize yourself with the available formatting options.
- Click on the 'Attach' button, and upload a document.
- Do a PubMed search on the main page, and click the 'Add to module' button on one of the search results. Select the title of this module, and click the 'Add item' button.

### Formatting text

Litsignal uses Markdown, a text format designed by [Jon Gruber](https://daringfireball.net/). Below you'll find a few examples to get you started with Markdown. Visit the [Documentation Guide](/litsignal/documentation-guide) for a more detailed guide.

Each of the examples below demonstrates the transformation of the raw text to formatted text. The raw text is reproduced in a code block, and the formatted output immediately follows.

***

### Headers

> Use 1-6 \`#\`'s at the start of a line followed by a \`space\` to denote a header, \`<H1>\` to \`<H6>\`.

	#### H4 header

#### H4 header

***

### Emphasis

> Surround a span of words with one or two \`*\` or \`_\` to denote emphasis equivalent to italics and bold or \`<em>\` and \`<strong>\` html tags.

	*italic emphasis*

*italic emphasis*

	**bold emphasis**

**bold emphasis**

***

### Lists

> Create a list by starting a line with a \`-\`, \`+\`, or \`*\` followed by a \`space\`. 

	My list:
	- Item one
	+ Item two
	* Item three

My list:
- Item one
- Item two
- Item three

> Similarly, numbered lists can be created by starting a line with \`0-9\` followed by \`.\` and a \`space\`.

	My numbered list:
	1. Item one
	2. Item two
	3. Item three

My numbered list:
1. Item one
2. Item two
3. Item three

---

Please be aware of the license section at the bottom of the module. This helps other users determine what their rights and responsibilities are with regard to the use, attribution, and distribution of your material. The CC-BY attribution license is the default for all modules shared on Litsignal. 

We intend to make collaboration as effortless as possible and [welcome feedback](/contact) if you identify an opportunity for improvement. Thank you for trying Litsignal.`

export const MARKDOWN_GUIDE = `

# Documentation Guide

Your documentation is the first thing most visitors will see. Make it concise but complete. As a starting point, consider these questions:
- What is the module about?
- What is its intended use?
- Are there any restrictions on sharing or using the attachments?
- How can others contribute?

**The following sections provide information about how to add and edit documentation and how to use Markdown to format the text.**

***

## Editing

Start typing in the \`DESCRIPTION\` and \`TEXT\` fields to add documentation to your module. Add links, embed video, or upload attachments using the buttons in the toolbar at the top of the page. Rearrange references through a drag and drop interface. Don't fuss about making mistakes along the way. All of your changes are private until you decide to publish a new version of your module, and everything is saved automatically.

***

## Formatting

Use **Markdown** to add styling to the text. Markdown is a lightweight standard designed by [Jon Gruber](https://daringfireball.net/) to provide useful formatting features while retaining human readability, You can read more about the philosophy and features of Markdown [here](https://daringfireball.net/projects/markdown/syntax). In the years since its description, it has become the defacto standard for writing text documents for the web, even spawning a number of derivative formats. Below you'll find examples of some of the more common features of Markdown. You can dive deeper by reviewing the [Markdown syntax documentation](https://daringfireball.net/projects/markdown/syntax).

Each of the examples below demonstrates the transformation of the raw text to formatted text. The raw text is reproduced in a code block, and the formatted output immediately follows.

***

### Images

> Embed an image similar to the way you would create a link.

  ![Litsignal logo](https://assets.litsignal.com/images/coverart.png)

***

### Headers

> Use 1-6 \`#\`'s at the start of a line followed by a \`space\` to denote a header, \`<H1>\` to \`<H6>\`.

	#### H4 header

#### H4 header

***

### Emphasis

> Surround a span of words with one or two \`*\` or \`_\` to denote emphasis equivalent to italics and bold or \`<em>\` and \`<strong>\` html tags.

	*italic emphasis*

*italic emphasis*

	**bold emphasis**

**bold emphasis**

***

### Block quotes

> Prepend a line or paragraph with \`>\` and a \`space\` to create a block quote.

	> Block quote line 1
	> Block quote line 2

> or

	> Block quote line 1
	Block quote line 2

> Block quote line 1  
Block quote line 2

***

### Lists

> Create a list by starting a line with a \`-\`, \`+\`, or \`*\` followed by a \`space\`. 

	My list:
	- Item one
	+ Item two
	* Item three

My list:
- Item one
- Item two
- Item three

> Similarly, numbered lists can be created by starting a line with \`0-9\` followed by \`.\` and a \`space\`.

	My numbered list:
	1. Item one
	2. Item two
	3. Item three

My numbered list:
1. Item one
2. Item two
3. Item three

***

### Links

> Create an inline link by surrounding a span of text with \`[]\` followed by the URL surrounded by \`()\`.

	I recommend [Litsignal](https:litsignal.com "Optional title") to all of my friends.

I recommend [Litsignal](https:litsignal.com "Optional title") to all of my friends.

> You can also use shortcuts to use links that are defined elsewhere in the document.

	I recommend [Litsignal][1] to all of my friends.

	[1]: https://litsignal.com "Optional title"

<br/>

	I recommend [Litsignal][shortcut] to all of my friends.

	[shortcut]: https://litsignal.com "Optional title"

I recommend [Litsignal][shortcut] to all of my friends.

[shortcut]: https://litsignal.com "Optional title"

> Links can also be created by surrounding a URL with \`<>\`.

	I recommend <https://litsignal.com> to all of my friends.

I recommend <https://litsignal.com> to all of my friends.

***

### Horizontal rule

> Insert a horizontal line by placing \`***\` alone on a line.

	***

***

### Backslash escape

> Since \`*\`, \`#\`, \`\` \` \`\`, \`_\`, \`{}\`, \`[]\`,\`()\`, \`<>\`, \`+\`, \`-\`, \`.\`, and \`!\` all may have special meaning in Markdown depending on where they appear, you may occasionally have to use a \`\\\` to bypass the formatting engine.

	**emphasized text**

**emphasized text**

	\*\*literal asterisks\*\*

\*\*literal asterisks\*\*
 

***

### Other features

> \`Code blocks\` and \`inline code\` have been employed throughout this document. To create a \`code block\`, indent a block of text. To create \`inline code\`, surround a span of text with \`backticks\` (\`\` \` \`\`). `

/* eslint-enable */
