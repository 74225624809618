import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ModalCard from '../components/ModalCard'

class PublishModule extends Component {
  constructor (props) {
    super(props)
    this.state = {showPublishModal: false}
    this.openPublish = this.openPublish.bind(this)
    this.closePublish = this.closePublish.bind(this)
    this.handleCommentChange = this.handleCommentChange.bind(this)
    this.recommend = this.recommend.bind(this)
  }

  closePublish (e) {
    e.stopPropagation()
    this.setState({ showPublishModal: false })
  }
  openPublish () {
    this.setState({ showPublishModal: true })
  }

  handleCommentChange (e) {
    this.props.onCommentChange(e.target.value)
  }

  recommend (e) {
    this.closePublish(e)
    this.props.onPublish(e)
  }

  render () {
    const { reference, comment, isDesktop } = this.props
    const remainingCommentCharacters = 255 - comment.length
    let commentLabel = 'Comment'
    if (remainingCommentCharacters < 50) {
      commentLabel = 'Comment (' + remainingCommentCharacters + ' characters remaining)'
    }
    return (
      <a tabIndex='-1' role='button' onClick={this.openPublish}>
        <span className='icon icon-publish' />{isDesktop ? 'Publish this version' : ''}
        {this.state.showPublishModal && <ModalCard onHide={this.closePublish}>
          <div className='card-body'>
            {reference}
            <form style={{paddingTop: '1rem'}}>
              <div className='form-group'>
                <label htmlFor='comment-input'>{commentLabel}</label>
                <div className='input-group'>
                  <input id='comment-input' name='comment'
                    className='form-control'
                    placeholder='Optional comment describing your changes.'
                    value={comment}
                    onChange={this.handleCommentChange}
                    maxLength='255'
                  />
                  <div className='input-group-append'>
                    <button className='btn btn-primary' type='button' onClick={this.recommend}>Publish</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </ModalCard>}
      </a>
    )
  }
}

PublishModule.propTypes = {
  comment: PropTypes.string.isRequired,
  onPublish: PropTypes.func.isRequired,
  onCommentChange: PropTypes.func.isRequired,
  isDesktop: PropTypes.bool
}

export default PublishModule
