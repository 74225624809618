import {
  UPDATE_FILTER, UPDATE_README_SUCCESS, REMOVE_FROM_MODULE_SUCCESS, SHARE_LINK_SUCCESS, SEARCH_PUBMED_SUCCESS, CLEAR_SEARCH,
  UPDATE_DESCRIPTION_SUCCESS, UPLOAD_EPISODE_SUCCESS, REMOVE_EPISODE_SUCCESS, REMOVE_ATTACHMENT_SUCCESS, CLEAR_PUBMED_SEARCH,
  UPLOAD_ATTACHMENTS_SUCCESS, LIBRARY_UPDATED, EDIT_MODULE, VIEW_MODULE, SELECT_PAGE, SELECT_PUBMED_PAGE_SUCCESS, WINDOW_RESIZE,
  STATE_DIMENSIONS
} from '../constants'

function dimensions (state = STATE_DIMENSIONS, action) {
  switch (action.type) {
    case UPDATE_FILTER:
    case UPDATE_README_SUCCESS:
    case REMOVE_FROM_MODULE_SUCCESS:
    case SHARE_LINK_SUCCESS:
    case SEARCH_PUBMED_SUCCESS:
    case UPDATE_DESCRIPTION_SUCCESS:
    case UPLOAD_EPISODE_SUCCESS:
    case REMOVE_EPISODE_SUCCESS:
    case REMOVE_ATTACHMENT_SUCCESS:
    case UPLOAD_ATTACHMENTS_SUCCESS:
    case LIBRARY_UPDATED:
    case EDIT_MODULE:
    case VIEW_MODULE:
    case SELECT_PUBMED_PAGE_SUCCESS:
    case SELECT_PAGE:
    case CLEAR_PUBMED_SEARCH:
    case CLEAR_SEARCH:
    case WINDOW_RESIZE:
    default:
      if (action.force) {
        return Object.assign({}, state, {
          scrollHeight: document.body.scrollHeight,
          innerHeight: document.body.scrollHeight - 100,
          overflow: true
        })
      } else {
        return Object.assign({}, state, {
          scrollHeight: document.body.scrollHeight,
          innerHeight: window.innerHeight,
          overflow: document.body.scrollHeight > window.innerHeight
        })
      }
  }
}

export default dimensions
