import {
  STATE_CLIENT
} from '../constants'

function client (state = STATE_CLIENT, action) {
  switch (action.type) {
    default:
      return state
  }
}

export default client
