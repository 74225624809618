import {
  STATE_STARS,
  STAR, STAR_REQUEST, STAR_SUCCESS, STAR_ERROR,
  UNSTAR, UNSTAR_REQUEST, UNSTAR_SUCCESS, UNSTAR_ERROR,
  LOGIN_SUCCESS, REGISTER_SUCCESS
} from '../constants'

function stars (state = STATE_STARS, action) {
  switch (action.type) {
    case STAR:
      return state
    case STAR_REQUEST:
    {
      let starIndex = state.starring.indexOf(action.componentUUID)
      if (starIndex === -1) {
        return Object.assign({}, state, {
          starring: state.starring.concat([ action.componentUUID ])
        })
      } else {
        return state
      }
    }
    case STAR_SUCCESS:
    {
      let starIndex = state.starring.indexOf(action.componentUUID)
      let newStarring = state.starring.slice(0, starIndex).concat(state.starring.slice(starIndex + 1))
      return Object.assign({}, state, {
        componentUUIDs: state.componentUUIDs.concat([ action.componentUUID ]),
        starring: newStarring
      })
    }
    case STAR_ERROR:
    {
      let starIndex = state.starring.indexOf(action.componentUUID)
      let revertedStarring = state.starring.slice(0, starIndex).concat(state.starring.slice(starIndex + 1))
      return Object.assign({}, state, {
        starring: revertedStarring
      })
    }
    case UNSTAR:
      return state
    case UNSTAR_REQUEST:
    {
      let unstarIndex = state.unstarring.indexOf(action.componentUUID)
      if (unstarIndex === -1) {
        return Object.assign({}, state, {
          unstarring: state.unstarring.concat([ action.componentUUID ])
        })
      } else {
        return state
      }
    }
    case UNSTAR_SUCCESS:
    {
      // get the index of the item within state.unstarring so that it can be removed
      let unstarIndex = state.unstarring.indexOf(action.componentUUID)
      // create a new object called optimistic to replace unstarring in the returned state
      let newUnstarring = state.unstarring.slice(0, unstarIndex).concat(state.unstarring.slice(unstarIndex + 1))
      // get the index of the item in state (stars) so that it can be removed
      unstarIndex = state.componentUUIDs.indexOf(action.componentUUID)
      // return a new state without the unstarred item
      return Object.assign({}, state, {
        componentUUIDs: state.componentUUIDs
          .slice(0, unstarIndex)
          .concat(state.componentUUIDs.slice(unstarIndex + 1)),
        unstarring: newUnstarring
      })
    }
    case UNSTAR_ERROR:
    {
      let unstarIndex = state.unstarring.indexOf(action.componentUUID)
      let revertedUnstarring = state.unstarring.slice(0, unstarIndex).concat(state.unstarring.slice(unstarIndex + 1))
      return Object.assign({}, state, {
        unstarring: revertedUnstarring
      })
    }
    case LOGIN_SUCCESS: {
      return Object.assign({}, action.stars, {
        starring: [],
        unstarring: []
      })
    }
    case REGISTER_SUCCESS: {
      return STATE_STARS
    }
    default:
      return state
  }
}

export default stars
