import React from 'react'
import { connect } from 'react-redux'
import {deleteLibraryItem} from '../actions'

const mapStateToProps = (state, ownProps) => {
  return {
    library: state.storage.library,
    componentUUID: ownProps.componentUUID
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

const DeleteLibraryItemContainer = ({
  library,
  componentUUID,
  dispatch
}) => {
  const onDelete = (e) => {
    e.stopPropagation()
    const matchingItems = library.filter(item => item.componentUUID === componentUUID)
    if (matchingItems.length > 0) return new Error('Duplicate components in library.')
    const sha1 = matchingItems[0].sha1
    dispatch(deleteLibraryItem(sha1))
  }
  if (componentUUID) {
    return (
      <a tabIndex='-1' role='button' onClick={onDelete} title='Delete PDF'>Delete this PDF document from your library.</a>
    )
  } else {
    return null
  }
}

const DeleteLibraryItem = connect(mapStateToProps, mapDispatchToProps)(DeleteLibraryItemContainer)

export default DeleteLibraryItem
