import React from 'react'
import PropTypes from 'prop-types'
import ModuleProper from '../components/ModuleProper'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
  return {
    user: state.user,
    module: state.module,
    search: state.search,
    stars: state.stars,
    history: state.history,
    storage: state.storage,
    client: state.client
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch
  }
}

const ModuleContainer = ({
  user,
  module,
  search,
  stars,
  history,
  storage,
  dispatch,
  client
}) => {
  const isDesktop = client.type.localeCompare('desktop') === 0 || client.type.localeCompare('tablet') === 0
  return (
    <ModuleProper
      user={user}
      module={module}
      search={search}
      stars={stars}
      history={history}
      storage={storage}
      dispatch={dispatch}
      isDesktop={isDesktop}
    />
  )
}

ModuleContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  module: PropTypes.object.isRequired,
  stars: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  storage: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  search: PropTypes.object.isRequired
}

const ModuleView = connect(mapStateToProps, mapDispatchToProps)(ModuleContainer)

export default ModuleView
