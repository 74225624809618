import React, {Component} from 'react'
import { connect } from 'react-redux'
import AuthButton from '../components/AuthButton'
import AsyncUploadLibrary from '../containers/AsyncUploadLibrary'
import AsyncNewModule from '../containers/AsyncNewModule'
import AsyncUserSearch from '../containers/AsyncUserSearch'
import SearchForm from '../components/SearchForm'
import { logout, selectPubmedSearch, selectLitsignalSearch, selectSearchAll } from '../actions'

const mapStateToProps = (state) => {
  return {
    user: state.user,
    search: state.search,
    stars: state.stars,
    history: state.history,
    storage: state.storage,
    client: state.client
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch
  }
}

class Search extends Component {
  constructor (props) {
    super(props)
    this.state = {userSelected: false, term: this.props.search.term}
    this.selectSearchAll = this.selectSearchAll.bind(this)
    this.selectPubmed = this.selectPubmed.bind(this)
    this.selectLitsignal = this.selectLitsignal.bind(this)
    this.selectUser = this.selectUser.bind(this)
    this.handleTermChange = this.handleTermChange.bind(this)
  }
  selectSearchAll () {
    const {dispatch} = this.props
    this.setState(Object.assign({}, this.state, {userSelected: false}))
    return dispatch(selectSearchAll())
  }
  selectPubmed () {
    const {dispatch} = this.props
    this.setState(Object.assign({}, this.state, {userSelected: false}))
    return dispatch(selectPubmedSearch())
  }
  selectLitsignal () {
    const {dispatch} = this.props
    this.setState(Object.assign({}, this.state, {userSelected: false}))
    return dispatch(selectLitsignalSearch())
  }
  selectUser () {
    this.setState(Object.assign({}, this.state, {userSelected: true}))
  }
  handleTermChange (e) {
    if (!this.state.userSelected) this.setState({...this.state, term: e.target.value})
  }
  render () {
    // console.log(`this.state: ${JSON.stringify(this.state)}`)
    // console.log(`this.state.term: ${JSON.stringify(this.state)}`)
    const {dispatch, user, storage, client, stars} = this.props
    const {type} = this.props.search
    const {term} = this.state
    // console.log(`term: ${JSON.stringify(term)}`)
    // console.log(`term: ${JSON.stringify(term)}`)
    const {isAuthenticated} = user
    const isDesktop = client.type.localeCompare('desktop') === 0 || client.type.localeCompare('tablet') === 0
    const existingSha1s = storage.library.map(item => item.sha1)
    const searchAllClass = !this.state.userSelected && type === 'all' ? 'nav-link active' : 'nav-link'
    const moduleClass = !this.state.userSelected && type === 'litsignal' ? 'nav-link active' : 'nav-link'
    const articleClass = !this.state.userSelected && type === 'pubmed' ? 'nav-link active' : 'nav-link'
    const userClass = this.state.userSelected ? 'nav-link active' : 'nav-link'

    const NavAll = () => {
      if (term && !this.state.userSelected && this.props.search.type !== 'all') {
        return <li className='nav-item'><a className={searchAllClass} href={`/search?term=${term}`} >All</a></li>
      } else {
        return <li className='nav-item'><a className={searchAllClass} tabIndex='-1' role='button' onClick={this.selectSearchAll}>All</a></li>
      }
    }
    const NavPubmed = () => {
      if (term && !this.state.userSelected && this.props.search.type !== 'pubmed') {
        return <li className='nav-item'><a className={articleClass} href={`/search/pubmed?term=${term}`} >Articles</a></li>
      } else {
        return <li className='nav-item'><a className={articleClass} tabIndex='-1' role='button' onClick={this.selectPubmed}>Articles</a></li>
      }
    }
    const NavLitsignal = () => {
      if (term && !this.state.userSelected && this.props.search.type !== 'litsignal') {
        return <li className='nav-item mr-auto'><a className={moduleClass} href={`/search/litsignal?term=${term}`} >Modules</a></li>
      } else {
        return <li className='nav-item mr-auto'><a className={moduleClass} tabIndex='-1' role='button' onClick={this.selectLitsignal}>Modules</a></li>
      }
    }

    return (
      <nav className='navbar navbar-expand navbar-dark bg-dark app-navbar d-print-none search-navbar' >
        <div className='container'>
          <div className='flex-column w-100'>
            <div className='flex-row flex-nowrap'>
              <ul className='navbar-nav'>
                <a className='navbar-brand my-auto mr-auto mb-0 h1' href='/about'>{isDesktop ? 'Litsignal' : 'Ls'}</a>
                {isAuthenticated && <li className='nav-item'>
                  <a className='nav-link' href={`/${user.username}`}><i className='icon icon-home' />{isDesktop && 'Home'}</a>
                </li>}
                {isAuthenticated && <li className='nav-item'>
                  <AsyncNewModule dispatch={dispatch} isDesktop={isDesktop} />
                </li>}
                {isAuthenticated && <li className='nav-item dropdown'>
                  <a className='nav-link dropdown-toggle' id='navbarDropdown' tabIndex='-1' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    {user.username}
                  </a>
                  <div className='dropdown-menu dropdown-menu-right' aria-labelledby='navbarDropdown'>
                    <a className='dropdown-item' href={`/${user.username}/collaboration`}><span className='icon icon-swap' />{'Collaboration'}</a>
                    <AsyncUploadLibrary dispatch={dispatch} existingSha1s={existingSha1s} />
                    <a className='dropdown-item' href='/settings/user'><span className='icon icon-user' />Profile</a>
                    <a className='dropdown-item' tabIndex='-1' role='button' href='/logout' >
                      <i className='icon icon-log-out' />{'Logout'}
                    </a>
                  </div>
                </li>}
                {!isAuthenticated && <li className='nav-item'>
                  <AuthButton />
                </li>}
              </ul>
            </div>
            <div className='flex-row flex-nowrap'>
              {this.state.userSelected && <AsyncUserSearch
                dispatch={dispatch}
                stars={stars}
                isDesktop={isDesktop}
                user={user}
              />}
              {!this.state.userSelected && <SearchForm term={term} type={type} onInputChange={this.handleTermChange} />}
            </div>
            <div className='flex-row flex-nowrap'>
              <ul className='navbar-nav mr-auto'>
                <li className='nav-item'><span className='navbar-text mr-2'><i className='icon icon-magnifying-glass' /></span></li>
                <NavAll />
                <NavPubmed />
                <NavLitsignal />
                <li className='nav-item'><a className={userClass} tabIndex='-1' role='button' onClick={this.selectUser}>Users</a></li>
              </ul>
            </div>
            {type === 'pubmed' && !isDesktop && <div className='flex-row flex-nowrap'>
              <ul className='navbar-nav'>
                <li className='nav-item'><a className='text-secondary' target='_blank' href='https://www.ncbi.nlm.nih.gov/home/about/policies/'>NCBI Disclaimer and Copyright notice</a></li>
              </ul>
            </div>}
          </div>
        </div>
      </nav>
    )
  }
}

const SearchNavbar = connect(mapStateToProps, mapDispatchToProps)(Search)

export default SearchNavbar
