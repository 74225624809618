import React, {Component} from 'react'
import PropTypes from 'prop-types'
import LoginForm from './LoginForm'
import RegisterForm from './RegisterForm'
import ForgotPasswordForm from './ForgotPasswordForm'
import { login, register, forgotPassword } from '../actions'
import {slugify} from '../helpers'
import {API_URL} from '../constants'
import { debounce } from 'lodash'
import fetch from 'isomorphic-fetch'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
  return {
    user: state.user,
    alert: state.alert
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch
  }
}

class AuthCard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      action: 'login',
      username: '',
      slugifiedUsername: '',
      password: '',
      email: '',
      usernameAvailable: true
    }
    this.showLogin = this.showLogin.bind(this)
    this.login = this.login.bind(this)
    this.showRegister = this.showRegister.bind(this)
    this.showReset = this.showReset.bind(this)
    this.triggerRegistration = this.triggerRegistration.bind(this)
    this.forgot = this.forgot.bind(this)
    this.onRegisterUsernameChange = this.onRegisterUsernameChange.bind(this)
    this.onUsernameChange = this.onUsernameChange.bind(this)
    this.onPasswordChange = this.onPasswordChange.bind(this)
    this.onEmailChange = this.onEmailChange.bind(this)
    this.onFormChange = this.onFormChange.bind(this)
    this.close = this.close.bind(this)
    this.checkUsernameAvailability = debounce(this.checkUsernameAvailability.bind(this), 200)
  }
  showLogin (evt) {
    evt.preventDefault()
    this.onFormChange()
    this.setState(Object.assign({}, this.state, {showModal: true, action: 'login'}))
  }
  showRegister (evt) {
    evt.preventDefault()
    this.onFormChange()
    this.setState(Object.assign({}, this.state, {showModal: true, action: 'register'}))
  }
  showReset (evt) {
    evt.preventDefault()
    this.onFormChange()
    this.setState(Object.assign({}, this.state, {showModal: true, action: 'forgot'}))
  }
  close (evt) {
    this.props.closeAuthModal(evt)
  }
  checkUsernameAvailability () {
    fetch(`${API_URL}/users/username_available/${this.state.slugifiedUsername}`)
      .then(response => {
        if (response.ok) {
          return {usernameAvailable: true}
        } else {
          return {usernameAvailable: false}
        }
      })
      .then(usernameAvailable => this.setState(Object.assign({}, this.state, usernameAvailable)))
  }
  onRegisterUsernameChange (evt) {
    // console.log('onRegisterUsernameChange()')
    this.onUsernameChange(evt)
    this.setState(Object.assign({}, this.state, {username: evt.target.value, slugifiedUsername: slugify(evt.target.value)}))
    this.checkUsernameAvailability()
  }
  onUsernameChange (evt) {
    this.setState(Object.assign({}, this.state, {
      username: evt.target.value,
      slugifiedUsername: slugify(evt.target.value)
    }))
  }
  onPasswordChange (evt) {
    this.setState(Object.assign({}, this.state, {
      password: evt.target.value
    }))
  }
  onEmailChange (evt) {
    this.setState(Object.assign({}, this.state, {
      email: evt.target.value
    }))
  }
  login (evt) {
    evt.preventDefault()
    const { dispatch } = this.props
    dispatch(login(this.state.username, this.state.password))
    this.close(evt)
  }
  triggerRegistration (recaptcha) {
    const {dispatch} = this.props
    dispatch(register(this.state.username, this.state.email, this.state.password, recaptcha))
    this.close()
  }
  forgot (recaptcha) {
    const {dispatch} = this.props
    dispatch(forgotPassword(this.state.email, recaptcha))
    this.close()
  }
  componentWillMount () {
    if (!this.state.action && this.props.initialAction) {
      this.setState(Object.assign({}, this.state, {action: this.props.initialAction}))
    }
  }
  componentDidMount () {
    this.onFormChange()
  }
  onFormChange () {
    // console.log('onFormChange()')
    setTimeout(() => {
      const loginForm = document.getElementsByClassName('login-form')[0]
      const registerForm = document.getElementsByClassName('register-form')[0]
      const autofillUsername = loginForm ? loginForm.querySelector('input[name="username"]').value : ''
      const autofillRegisterUsername = registerForm ? registerForm.querySelector('input[name="username"]').value : ''
      const autofillEmail = document.querySelector('input[name="email"]') ? document.querySelector('input[name="email"]').value : ''
      const autofillPassword = document.querySelector('input[name="password"]') ? document.querySelector('input[name="password"]').value : ''
      if (autofillUsername !== '') this.onUsernameChange({target: {value: autofillUsername}})
      if (autofillEmail !== '') this.onUsernameChange({target: {value: autofillEmail}})
      if (autofillPassword !== '') this.onPasswordChange({target: {value: autofillPassword}})
      if (autofillRegisterUsername !== '') this.onRegisterUsernameChange({target: {value: autofillRegisterUsername}})
    }, 100)
  }
  render () {
    const {action} = this.state
    const {isAuthenticated} = this.props.user
    let form
    switch (action) {
      case 'register':
        form = <RegisterForm triggerRegistration={this.triggerRegistration} showLogin={this.showLogin} onReCAPTCHA={this.onReCAPTCHA} onUsernameChange={this.onRegisterUsernameChange} onEmailChange={this.onEmailChange} onPasswordChange={this.onPasswordChange} slugifiedUsername={this.state.slugifiedUsername} username={this.state.username} password={this.state.password} email={this.state.email} usernameAvailable={this.state.usernameAvailable} alert={this.props.alert} />
        break
      case 'forgot':
        form = <ForgotPasswordForm forgot={this.forgot} showLogin={this.showLogin} onReCAPTCHA={this.onReCAPTCHA} onEmailChange={this.onEmailChange} email={this.state.email} alert={this.props.alert} />
        break
      case 'login':
      default:
        form = <LoginForm login={this.login} showRegister={this.showRegister} showReset={this.showReset} onReCAPTCHA={this.onReCAPTCHA} onUsernameChange={this.onUsernameChange} onPasswordChange={this.onPasswordChange} username={this.state.username} password={this.state.password} alert={this.props.alert} />
        break
    }
    if (isAuthenticated) return null
    return (
      <div className='card-body'>
        {form}
      </div>
    )
  }
}

AuthCard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  initialAction: PropTypes.string
}

const AuthCardConnect = connect(mapStateToProps, mapDispatchToProps)(AuthCard)

export default AuthCardConnect
