import {
  DISMISS_RELEASE_NOTES_SUCCESS,
  DISMISS_RELEASE_NOTES_ERROR
} from '../constants'

const STATE_NOTIFICATIONS = {
  release: {},
  maintenance: {}
}

function notifications (state = STATE_NOTIFICATIONS, action) {
  switch (action.type) {
    case DISMISS_RELEASE_NOTES_SUCCESS: {
      return Object.assign({}, state, {
        release: {}
      })
    }
    case DISMISS_RELEASE_NOTES_ERROR:
    default: {
      return state
    }
  }
}

export default notifications
