const Star = ({
  starState,
  onStar,
  onUnstar,
  bsStyle,
  isDesktop
}) => {
  const starColor = bsStyle === 'primary' ? 'yellow' : 'orange'

  if (starState === 'starring') {
    // starring
    return (
      <a tabIndex='-1' role='button' disabled onClick={onUnstar}>
        <i className='icon icon-star' />{isDesktop ? 'Starred' : ''}
      </a>
    )
  } else if (starState === 'unstarring') {
    // unstarring
    return (
      <a tabIndex='-1' role='button' disabled onClick={onStar}>
        <i className='icon icon-star-outlined' />{isDesktop ? 'Star' : ''}
      </a>
    )
  } else if (starState === 'starred') {
    // starred with no pending operations
    return (
      <a tabIndex='-1' role='button' onClick={onUnstar}>
        <i className='icon icon-star' style={{color: starColor}} />{isDesktop ? 'Starred' : ''}
      </a>
    )
  } else if (starState === 'unstarred') {
    // unstarred with no pending operations
    return (
      <a tabIndex='-1' role='button' onClick={onStar}>
        <i className='icon icon-star-outlined' />{isDesktop ? 'Star' : ''}
      </a>
    )
  }
}

export default Star
