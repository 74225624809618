import React from 'react'
import DissociatedFiles from '../components/DissociatedFiles'

const UserUploads = ({
  user,
  module,
  storage,
  dispatch
}) => {
  return (
    <div className='media list-group-item'>
      <DissociatedFiles />
    </div>
  )
}

export default UserUploads
