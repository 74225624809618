import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { shareLink } from '../actions'
import ModalCard from '../components/ModalCard'

class AsyncModuleVideo extends Component {
  constructor (props) {
    super(props)
    this.state = { url: '', showShareLinkModal: false }
    this.onInputChange = this.onInputChange.bind(this)
    this.onShareLink = this.onShareLink.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.close = this.close.bind(this)
    this.openShareLink = this.openShareLink.bind(this)
  }
  onInputChange (input) {
    this.state = { url: input }
  }
  onShareLink (e) {
    e.preventDefault()
    const { dispatch, moduleUUID } = this.props
    dispatch(shareLink(moduleUUID, this.state.url))
    this.close(e)
  }
  handleChange (e) {
    this.setState(Object.assign({}, this.state, {
      url: e.target.value
    }))
  }
  close (e) {
    e.stopPropagation()
    this.setState({ url: '', showShareLinkModal: false })
  }
  openShareLink () {
    this.setState({ url: '', showShareLinkModal: true })
  }
  render () {
    return (
      <a tabIndex='-1' role='button' onClick={this.openShareLink} className='nav-link'>
        <i className='icon icon-video' />Embed a video
        {this.state.showShareLinkModal && <ModalCard onHide={this.close}>
          <div className='card-body'>
            <h4>Embed a video</h4>
            <p>To embed a video in this module, simply paste a link from YouTube or Vimeo in the input below, and submit the form.</p>
            <form style={{paddingTop: '1rem'}}>
              {/* <label htmlFor='sharefield'>Embed a video</label> */}
              <div className='input-group'>
                <input
                  id='sharefield'
                  className='form-control'
                  name='share-link'
                  type='text'
                  placeholder='https://www.youtube.com/videoId'
                  onChange={this.handleChange}
                  value={this.state.url}
                  autoFocus />
                <div className='input-group-append'>
                  <button className='btn btn-primary' type='button' onClick={this.onShareLink}>Submit video</button>
                </div>
              </div>
            </form>
          </div>
        </ModalCard>}
      </a>
    )
  }
}

AsyncModuleVideo.propTypes = {
  dispatch: PropTypes.func.isRequired,
  moduleUUID: PropTypes.string.isRequired
}

export default AsyncModuleVideo
