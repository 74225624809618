import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { shareLink } from '../actions'
import ReferenceLinks from '../components/ReferenceLinks'

class AsyncLinks extends Component {
  constructor (props) {
    super(props)
    this.state = { url: '' }
    this.onShareLink = this.onShareLink.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }
  onShareLink (e) {
    e.preventDefault()
    const { dispatch, refUUID } = this.props
    dispatch(shareLink(refUUID, this.state.url))
  }
  handleChange (value) {
    this.setState({ url: value })
  }
  render () {
    const { reference, refUUID, privatePDF, pmcid, doi, pmid, fulltext, title } = this.props
    return (
      <ReferenceLinks
        reference={reference}
        privatePDF={privatePDF}
        pmcid={pmcid}
        doi={doi}
        pmid={pmid}
        refUUID={refUUID}
        title={title}
        fulltext={fulltext}
        url={this.state.url}
        moduleSelect={this.state.moduleSelect}
        onChange={this.handleChange}
        onShareLink={this.onShareLink}
        moduleOptions={this.props.modifiedModules}
      />
    )
  }
}

AsyncLinks.propTypes = {
  reference: PropTypes.object.isRequired,
  privatePDF: PropTypes.bool,
  pmcid: PropTypes.string,
  doi: PropTypes.string,
  pmid: PropTypes.number,
  fulltext: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  refUUID: PropTypes.string.isRequired,
  title: PropTypes.string,
  modifiedModules: PropTypes.arrayOf(PropTypes.string)
}

export default AsyncLinks
