import { LinkTitleRow } from '../components/ReferenceComponents'

const LinkInfo = ({
  title,
  url,
  active = false
}) => {
  const urlRow = url ? <div><a target='_blank' href={url}>{url}</a></div> : null
  return (
    <div style={{lineHeight: 1.4}}>
      <LinkTitleRow title={title} url={url} active={active} />
      {urlRow}
    </div>
  )
}

export default LinkInfo
