import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ModalCard from '../components/ModalCard'
import ArticleInfo from './ArticleInfo'

class ReconcileUpload extends Component {
  constructor (props) {
    super(props)
    if (props.search.searching || props.search.components.length > 0) {
      this.state = {
        showModal: true
      }
    } else {
      this.state = {
        showModal: false
      }
    }
    this.closeModal = this.closeModal.bind(this)
    this.openModal = this.openModal.bind(this)
    this.search = this.search.bind(this)
    this.selectArticle = this.selectArticle.bind(this)
    this.handleSearchTermChange = this.handleSearchTermChange.bind(this)
  }

  closeModal (e) {
    e.stopPropagation()
    this.setState(Object.assign({}, this.state, {
      showModal: false
    }))
    this.props.onClearSearch()
  }

  openModal () {
    this.setState(Object.assign({}, this.state, {
      showModal: true
    }))
  }

  handleSearchTermChange (e) {
    this.props.onSearchTermChange(e.target.value)
  }

  search (e) {
    e.preventDefault()
    this.props.onSearch()
    this.setState(Object.assign({}, this.state, {
      showModal: true
    }))
  }

  selectArticle (e) {
    // console.log('e.target.value: ' + e.target.value)
    this.props.onReconcile(e.target.value)
    this.closeModal(e)
  }

  clickBackground () {
    // console.log('click click click')
  }

  render () {
    const {searchTerm, search} = this.props
    // console.log('search: ' + JSON.stringify(search))
    const searchResults = search ? search.components : []
    // console.log(searchResults.length + ' search results')
    const ResultsComponent = ({searchResults}) => {
      if (searchResults.length > 0) {
        return (
          <div>
            {searchResults.map(component => {
              // console.log('component.pmid: ' + component.pmid)
              return (
                <div key={component.uuid}>
                  <hr />
                  <ArticleInfo article={component.metadata} />
                  <button value={component.uuid} onClick={this.selectArticle} >Select</button>
                </div>
              )
            })}
          </div>
        )
      } else {
        return null
      }
    }

    return (
      <a tabIndex='-1' role='button' onClick={this.openModal} title='Search for the correct reference for this upload.' ><i className='icon icon-magnifying-glass' />
        {this.state.showModal && <ModalCard onHide={this.closeModal} >
          <div className='card-body'>
            <h2>Litsignal</h2>
            <form onSubmit={this.search}>
              <div className='form-group'>
                <label htmlFor='searchfield'>Search PubMed for a match</label>
                <div className='input-group'>
                  <input
                    name='searchterm'
                    id='searchfield'
                    className='form-control'
                    placeholder='Enter the title or PubMed ID for best results'
                    onChange={this.handleSearchTermChange}
                    value={searchTerm}
                    autoFocus />
                  <div className='input-group-append'>
                    <button className='btn btn-primary' type='submit'>Search</button>
                  </div>
                </div>
              </div>
            </form>
            <ResultsComponent searchResults={searchResults} />
          </div>
        </ModalCard>}
      </a>
    )
  }
}

ReconcileUpload.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onReconcile: PropTypes.func.isRequired,
  onClearSearch: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  searchResults: PropTypes.object,
  sha1: PropTypes.string,
  search: PropTypes.object
}

export default ReconcileUpload
