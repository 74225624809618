import React from 'react'
import ModuleEditor from './ModuleEditor'
import ModuleViewer from './ModuleViewer'

const ModuleProper = ({
  user,
  module,
  stars,
  history,
  storage,
  dispatch,
  isDesktop,
  search
}) => {
  if (module.editing) {
    return <ModuleEditor user={user} module={module} stars={stars} history={history} storage={storage} dispatch={dispatch} isDesktop={isDesktop} search={search} />
  } else {
    return <ModuleViewer user={user} module={module} stars={stars} history={history} storage={storage} dispatch={dispatch} isDesktop={isDesktop} search={search} />
  }
}

export default ModuleProper
