import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ModalCard from './ModalCard'

class NewModule extends Component {
  constructor (props) {
    super(props)
    this.state = {showModal: false}
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.createModule = this.createModule.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  closeModal (e) {
    e.stopPropagation()
    this.setState({showModal: false})
  }
  openModal (e) {
    this.setState({showModal: true})
  }
  createModule (e) {
    this.props.onCreateModule(e)
    this.closeModal(e)
  }

  handleInputChange (e) {
    this.props.onChange(e.target.value)
  }

  render () {
    const { moduleInput, isDesktop } = this.props
    const { showModal } = Object.assign({}, this.state)

    const Form = (
      <form onSubmit={this.createModule}>
        <h4>Create a new module</h4>
        <div className='input-group'>
          <input
            id='titlefield'
            className='form-control'
            name='moduletitle'
            type='text'
            placeholder='Title of new module'
            value={moduleInput}
            onChange={this.handleInputChange}
            autoFocus
          />
          <div className='input-group-append'>
            <button className='btn btn-primary' type='submit' >Create module</button>
          </div>
        </div>
      </form>
    )

    return (
      <a className='nav-link' tabIndex='-1' role='button' onClick={this.openModal}><i className='icon icon-plus' />{isDesktop ? 'New module' : null}
        {showModal === true && <ModalCard onHide={this.closeModal} >
          <div className='card-body'>
            {Form}
          </div>
        </ModalCard>}
      </a>
    )
  }
}

NewModule.propTypes = {
  moduleInput: PropTypes.string.isRequired,
  onCreateModule: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isDesktop: PropTypes.bool
}

export default NewModule
