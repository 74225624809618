import RemoveFromModule from './RemoveFromModule'
import { declineRecommendation } from '../actions'

const Recommendation = ({
  recommendation,
  dispatch
}) => {
  const { uuid, contributorName, contributorUsername, message, date, refUUID } = recommendation
  const onDismiss = () => {
    dispatch(declineRecommendation(uuid, refUUID))
  }
  return (
    <div>
      <span className='pull-right'><RemoveFromModule onRemove={onDismiss} skipConfirm /></span>
      <div style={{paddingBottom: 10}} >
        <a href={`/${contributorUsername}`}><i className='icon icon-thumbs-up' />{contributorName}</a>
        {date}
        <br />{message}
      </div>
    </div>
  )
}

export default Recommendation
