import React from 'react'
import PropTypes from 'prop-types'
import { star, unstar } from '../actions'
import Star from '../components/Star'

const AsyncStar = ({
  dispatch,
  stars,
  refUUID,
  bsStyle,
  isDesktop
}) => {
  let starState
  if (stars.starring.indexOf(refUUID) > -1) {
    starState = 'starring'
  } else if (stars.unstarring.indexOf(refUUID) > -1) {
    starState = 'unstarring'
  } else if (stars.componentUUIDs.indexOf(refUUID) > -1) {
    starState = 'starred'
  } else if (stars.componentUUIDs.indexOf(refUUID) === -1) {
    starState = 'unstarred'
  }
  const onStar = (e) => {
    e.stopPropagation()
    dispatch(star(refUUID))
  }

  const onUnstar = (e) => {
    e.stopPropagation()
    dispatch(unstar(refUUID))
  }
  return (
    <Star
      starState={starState}
      refUUID={refUUID}
      onStar={onStar}
      onUnstar={onUnstar}
      isDesktop={isDesktop}
    />
  )
}

AsyncStar.propTypes = {
  dispatch: PropTypes.func.isRequired,
  stars: PropTypes.object.isRequired,
  refUUID: PropTypes.string.isRequired,
  bsStyle: PropTypes.string
}

export default AsyncStar
