import React, { Component } from 'react'
import AuthCard from './AuthCard'
import ModalCard from './ModalCard'
import {dismissAlert} from '../actions'

class AuthButton extends Component {
  constructor (props) {
    super(props)
    this.state = {showAuthModal: false}
    this.openAuthModal = this.openAuthModal.bind(this)
    this.closeAuthModal = this.closeAuthModal.bind(this)
  }

  closeAuthModal (e = new Event('closeAuthModal')) {
    const {alert, dispatch} = this.props
    e.stopPropagation()
    this.setState({ showAuthModal: false })
  }
  openAuthModal () {
    this.setState({ showAuthModal: true })
  }

  render () {
    return (
      <a className='nav-link' tabIndex='-1' role='button' title='Sign in or sign up' onClick={this.openAuthModal}>
        <i className='icon icon-login' />Sign in or sign up
        {this.state.showAuthModal && <ModalCard onHide={this.closeAuthModal}>
          <AuthCard closeAuthModal={this.closeAuthModal} />
        </ModalCard>}
      </a>
    )
  }
}

export default AuthButton
