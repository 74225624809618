import React, {Component} from 'react'

class ModulesSummary extends Component {
  constructor (props) {
    super(props)
    this.state = {selected: 'recent'}
    this.handleClick = this.handleClick.bind(this)
  }
  handleClick (e) {
    this.setState({selected: e.target.getAttribute('data-tag')})
  }
  render () {
    const {trendingModules, activeModules, recentModules} = this.props
    const {selected} = this.state
    if (trendingModules.length + activeModules.length + recentModules.length === 0) return null
    return (
      <div className='card'>
        <div className='card-header ls-reference'>
          <div className='d-flex'>
            <h5 className='my-0 mr-auto'>Modules</h5>
            <div>
              {trendingModules.length > 0 && <a role='button' tabIndex='-1' data-tag='trending' onClick={this.handleClick} className={selected === 'trending' ? 'mr-3 font-weight-bold' : 'mr-3'}>trending</a>}
              {activeModules.length > 0 && <a role='button' tabIndex='-1' data-tag='active' onClick={this.handleClick} className={selected === 'active' ? 'mr-3 font-weight-bold' : 'mr-3'}>active</a>}
              {recentModules.length > 0 && <a role='button' tabIndex='-1' data-tag='recent' onClick={this.handleClick} className={selected === 'recent' ? 'font-weight-bold' : ''}>recent</a>}
            </div>
          </div>
        </div>
        <div className='card-body ls-reference'>
          {trendingModules.length > 0 && selected === 'trending' && <div>
            {trendingModules.map(module => {
              return (<div key={module.uuid}>
                <a className='ls-reference-title' href={`/${module.author}/${module.title}`}>{module.canonicalTitle}</a>
              </div>)
            })}
          </div>}
          {activeModules.length > 0 && selected === 'active' && <div>
            {activeModules.map(module => {
              return (<div key={module.uuid}>
                <a className='ls-reference-title' href={`/${module.author}/${module.title}`}>{module.canonicalTitle}</a>
              </div>)
            })}
          </div>}
          {recentModules.length > 0 && selected === 'recent' && <div>
            {recentModules.map(module => {
              return (<div key={module.uuid}>
                <a className='ls-reference-title' href={`/${module.author}/${module.title}`}>{module.canonicalTitle}</a>
              </div>)
            })}
          </div>}
        </div>
      </div>
    )
  }
}

export default ModulesSummary
