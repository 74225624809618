import user from './user'
import client from './client'
import history from './history'
import stars from './stars'
import storage from './storage'
import alert from './alert'
import module from './module'
import search from './search'
import dimensions from './dimensions'
import notifications from './notifications'
import userMetrics from './userMetrics'

import { combineReducers } from 'redux'

const rootReducer = combineReducers({
  user,
  client,
  history,
  stars,
  storage,
  alert,
  module,
  search,
  dimensions,
  notifications,
  userMetrics
})

export default rootReducer
