import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { LinkTitleRow } from '../components/ReferenceComponents'
import { BookTitleRow, BookAuthorRow, JournalRow } from './ReferenceComponents'
import RemoveComponentButton from '../containers/RemoveComponentButton'
import { moveReference } from '../actions'
import {SAVING} from '../constants'
import {unescape} from 'lodash'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'

const ComponentRow = ({component, module, dispatch}) => {
  const {uuid, metadata} = component
  switch (component.type) {
    case 'user': {
      const { fullName, username } = metadata
      return (
        <div className='d-flex'>
          <div className='p-2'>
            <div className='ls-reference-title'>
              <a href={`/${username}`}>
                <i className='icon icon-user mr-2' /> {username}
              </a>
              {fullName && <div>
                {fullName}
              </div>}
            </div>
          </div>
          <div className='ml-auto my-auto p-2'>
            <RemoveComponentButton uuid={uuid} rearranging={module.rearranging} moduleTitle={module.title} dispatch={dispatch} />
          </div>
        </div>
      )
    }
    case 'module': {
      const {author, title, canonicalTitle, description} = metadata
      return (
        <div className='d-flex'>
          <div className='p-2'>
            <div className='ls-reference-title'>
              <i className='icon icon-documents mr-2' />{canonicalTitle || title}
              <div>
                <div>{author}</div>
                {description && <div>{description}</div>}
              </div>
            </div>
          </div>
          <div className='ml-auto my-auto p-2'>
            <RemoveComponentButton uuid={uuid} rearranging={module.rearranging} moduleTitle={module.title} dispatch={dispatch} />
          </div>
        </div>
      )
    }
    case 'link': {
      const {title, url} = metadata
      return (
        <div className='d-flex'>
          <div className='p-2'>
            <div style={{lineHeight: 1.4}}>
              <LinkTitleRow title={title} url={url} active={false} />
              {url && <div>{url}</div>}
            </div>
          </div>
          <div className='ml-auto my-auto p-2'>
            <RemoveComponentButton uuid={uuid} rearranging={module.rearranging} moduleTitle={module.title} dispatch={dispatch} />
          </div>
        </div>
      )
    }
    case 'article': {
      const {title, authors, journal, date, volume, issue, pages} = metadata
      return (
        <div className='d-flex'>
          <div className='p-2'>
            <div style={{lineHeight: 1.4}}>
              <div className='ls-reference-title' itemProp='name'><i className='icon icon-text-document mr-2' />{unescape(title)}</div>
              {authors[0] && <span itemProp='author' itemType='http://schema.org/Person'>{authors.join(authors[0].includes(',') ? '; ' : ', ')}</span>}
              <JournalRow journalName={journal.name} date={date} volume={volume} issue={issue} pages={pages} abbr={journal.abbreviation} />
            </div>
          </div>
          <div className='ml-auto my-auto p-2'>
            <RemoveComponentButton uuid={uuid} rearranging={module.rearranging} moduleTitle={module.title} dispatch={dispatch} />
          </div>
        </div>
      )
    }
    case 'book': {
      const {title, isbn13, author} = metadata
      return (
        <div className='d-flex'>
          <div className='p-2'>
            <div style={{lineHeight: 1.4}}>
              <BookTitleRow title={title} isbn13={isbn13} />
              <BookAuthorRow author={author} />
            </div>
          </div>
          <div className='ml-auto my-auto p-2'>
            <RemoveComponentButton uuid={uuid} rearranging={module.rearranging} moduleTitle={module.title} dispatch={dispatch} />
          </div>
        </div>
      )
    }
  }
}

class EditReferences extends Component {
  constructor (props) {
    super(props)
    this.onDragEnd = this.onDragEnd.bind(this)
  }
  onDragEnd (result) {
    if (!result.destination) {
      return
    }
    this.props.dispatch(moveReference(this.props.module.title, result.source.droppabeleId, result.source.index, result.destination.index))
  }
  render () {
    const {module, dispatch} = this.props
    const {components} = module
    if (components.length > 0) {
      return (
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId='referenceDrop' isDropDisabled={module.saveState === SAVING} >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className='media-list'
                // style={getListStyle(snapshot.isDraggingOver)}
              >
                <li className='media list-group-item ls-heavy'>REFERENCES</li>
                {components.map(
                  (component, index, array) => (
                    <Draggable key={component.uuid} draggableId={component.uuid} index={index} isDropDisabled={module.saveState === SAVING} >
                      {(provided, snapshot) => (
                        <li
                          className='media list-group-item'
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ComponentRow component={component} module={module} dispatch={dispatch} />
                        </li>
                      )}
                    </Draggable>
                  )
                )}
              </div>)}
          </Droppable>
        </DragDropContext>
      )
    } else {
      return null
    }
  }
}

EditReferences.propTypes = {
  dispatch: PropTypes.func.isRequired,
  module: PropTypes.object.isRequired,
  isDesktop: PropTypes.bool.isRequired
}

export default EditReferences
