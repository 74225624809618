import fetch from 'isomorphic-fetch'
import FormData from 'form-data'
import { slugify, fetchOptions, getCSRF } from './helpers'
import {
  API_URL,
  STAR_ERROR,
  STAR_REQUEST,
  STAR_SUCCESS,
  UNSTAR_ERROR,
  UNSTAR_REQUEST,
  UNSTAR_SUCCESS,
  PUBLISH_MODULE_REQUEST,
  PUBLISH_MODULE_SUCCESS,
  PUBLISH_MODULE_ERROR,
  ADD_TO_MODULE_REQUEST,
  ADD_TO_MODULE_SUCCESS,
  ADD_TO_MODULE_ERROR,
  CREATE_MODULE_REQUEST,
  CREATE_MODULE_SUCCESS,
  CREATE_MODULE_ERROR,
  REMOVE_FROM_MODULE_REQUEST,
  REMOVE_FROM_MODULE_SUCCESS,
  REMOVE_FROM_MODULE_ERROR,
  REMOVE_ATTACHMENT_REQUEST,
  REMOVE_ATTACHMENT_SUCCESS,
  REMOVE_ATTACHMENT_ERROR,
  REMOVE_EPISODE_REQUEST,
  REMOVE_EPISODE_SUCCESS,
  REMOVE_EPISODE_ERROR,
  MOVE_REFERENCE_REQUEST,
  MOVE_REFERENCE_SUCCESS,
  MOVE_REFERENCE_ERROR,
  STORAGE_SYNC_ERROR,
  STORAGE_SYNC_REQUEST,
  STORAGE_SYNC_SUCCESS,
  SEARCH_PUBMED_ERROR,
  SEARCH_PUBMED_REQUEST,
  SEARCH_PUBMED_SUCCESS,
  SEARCH_ALL_ERROR,
  SEARCH_ALL_REQUEST,
  SEARCH_ALL_SUCCESS,
  SELECT_PUBMED_PAGE_ERROR,
  SELECT_PUBMED_PAGE_REQUEST,
  SELECT_PUBMED_PAGE_SUCCESS,
  SELECT_SEARCH_ALL,
  SELECT_PUBMED_SEARCH,
  SELECT_LITSIGNAL_SEARCH,
  SELECT_BOOK_SEARCH,
  SEARCH_LITSIGNAL_REQUEST,
  SEARCH_LITSIGNAL_ERROR,
  SEARCH_LITSIGNAL_SUCCESS,
  SEARCH_BOOK_REQUEST,
  SEARCH_BOOK_ERROR,
  SEARCH_BOOK_SUCCESS,
  UPDATE_SEARCH_TERM,
  CLEAR_SEARCH,
  RECONCILE_PUBMED_ERROR,
  RECONCILE_PUBMED_REQUEST,
  RECONCILE_PUBMED_SUCCESS,
  RECONCILE_UPLOAD_ERROR,
  RECONCILE_UPLOAD_REQUEST,
  CLEAR_PUBMED_SEARCH,
  UNDO_PUBMED_SEARCH,
  FILTER_MODULE,
  UPDATE_FILTER,
  SELECT_PAGE,
  MODULE_VISITED,
  EDIT_MODULE,
  VIEW_MODULE,
  REFERENCE_TOUCHED,
  RECOMMEND_REQUEST,
  RECOMMEND_SUCCESS,
  RECOMMEND_ERROR,
  ACCEPT_RECOMMENDATION_REQUEST,
  ACCEPT_RECOMMENDATION_SUCCESS,
  ACCEPT_RECOMMENDATION_ERROR,
  SHARE_LINK_REQUEST,
  SHARE_LINK_SUCCESS,
  SHARE_LINK_ERROR,
  UPDATE_README_REQUEST,
  UPDATE_README_SUCCESS,
  UPDATE_README_ERROR,
  UPDATE_DESCRIPTION_REQUEST,
  UPDATE_DESCRIPTION_SUCCESS,
  UPDATE_DESCRIPTION_ERROR,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  UPLOAD_ATTACHMENTS_REQUEST,
  UPLOAD_ATTACHMENTS_SUCCESS,
  UPLOAD_ATTACHMENTS_ERROR,
  UPLOAD_LIBRARY_REQUEST,
  UPLOAD_LIBRARY_SUCCESS,
  UPLOAD_LIBRARY_ERROR,
  UPLOAD_LIBRARY_PROGRESS,
  UPLOAD_EPISODE_REQUEST,
  UPLOAD_EPISODE_SUCCESS,
  UPLOAD_EPISODE_ERROR,
  LIBRARY_UPDATED,
  UPLOAD_PROGRESS,
  UPLOAD_COMPLETE,
  DISMISS_ALERT,
  WINDOW_RESIZE,
  MODULE_MODIFIED,
  MODULE_CHANGING,
  ADD_TAG_SUCCESS,
  ADD_TAG_ERROR,
  ADD_TAG_REQUEST,
  REMOVE_TAG_SUCCESS,
  REMOVE_TAG_ERROR,
  REMOVE_TAG_REQUEST,
  DISMISS_RELEASE_NOTES_REQUEST,
  DISMISS_RELEASE_NOTES_ERROR,
  DISMISS_RELEASE_NOTES_SUCCESS,
  DELETE_LIBRARY_ITEM_REQUEST,
  DELETE_LIBRARY_ITEM_ERROR,
  DELETE_LIBRARY_ITEM_SUCCESS,
  PATCH_LIBRARY_ITEM_REQUEST,
  PATCH_LIBRARY_ITEM_ERROR,
  PATCH_LIBRARY_ITEM_SUCCESS,
  GET,
  PUT,
  POST,
  DELETE,
  PATCH,
  REINITIALIZE_STORE
} from './constants'

// HISTORY API

export function reinitializeStore (store) {
  return {
    type: REINITIALIZE_STORE,
    store
  }
}

// MODULE VERSION CONTROL

export function publishModule (moduleUUID, comment) {
  return dispatch => {
    dispatch(publishModuleRequest())
    return fetch(`${API_URL}/modules/${moduleUUID}`, fetchOptions(PATCH, {comment}))
      .then(response => {
        if (response.ok) {
          return response.json().then(function (json) {
            dispatch(publishModuleSuccess(json.message, json.module))
            setTimeout(() => dispatch(dismissAlert()), 3000)
          })
        } else {
          dispatch(publishModuleError())
          setTimeout(() => dispatch(dismissAlert()), 3000)
        }
      })
  }
}

const publishModuleRequest = () => {
  return {
    type: PUBLISH_MODULE_REQUEST
  }
}

const publishModuleSuccess = (message, module) => {
  return {
    type: PUBLISH_MODULE_SUCCESS,
    message,
    module
  }
}

const publishModuleError = (message) => {
  return {
    type: PUBLISH_MODULE_ERROR,
    message
  }
}

// to be determined

// ARTICLE

export function addToModule (moduleTitle, componentUUID) {
  moduleTitle = slugify(moduleTitle)
  return dispatch => {
    dispatch(addToModuleRequest())
    return fetch(`${API_URL}/modules/${moduleTitle}/components/${componentUUID}`, fetchOptions(PATCH))
      .then(response => {
        if (response.ok) {
          return response.json().then(function (json) {
            dispatch(addToModuleSuccess(json.message, json.url))
            dispatch(moduleModified(moduleTitle))
            setTimeout(() => dispatch(dismissAlert()), 3000)
          })
        } else {
          dispatch(addToModuleError())
          setTimeout(() => dispatch(dismissAlert()), 3000)
        }
      })
  }
}

const addToModuleRequest = () => {
  return {
    type: ADD_TO_MODULE_REQUEST
  }
}

const addToModuleSuccess = (message, url) => {
  return {
    type: ADD_TO_MODULE_SUCCESS,
    message,
    url
  }
}

const addToModuleError = () => {
  return {
    type: ADD_TO_MODULE_ERROR
  }
}

export function createModule (canonicalTitle, readme = '') {
  return dispatch => {
    dispatch(createModuleRequest())
    return fetch(`${API_URL}/modules`, fetchOptions(POST, {canonicalTitle, readme}))
      .then(response => {
        if (response.ok) {
          return response.json().then(function (json) {
            dispatch(createModuleSuccess(json.message, json.url, json.module))
            setTimeout(() => dispatch(dismissAlert()), 3000)
          })
        } else {
          dispatch(createModuleError())
          setTimeout(() => dispatch(dismissAlert()), 3000)
        }
      })
  }
}

const createModuleRequest = () => {
  return {
    type: CREATE_MODULE_REQUEST
  }
}

const createModuleSuccess = (message, url, module) => {
  return {
    type: CREATE_MODULE_SUCCESS,
    message,
    url,
    module
  }
}

const createModuleError = () => {
  return dispatch => {
    dispatch(dismissAlert)
    return {
      type: CREATE_MODULE_ERROR
    }
  }
}

export function addTag (tag, componentUUID) {
  return dispatch => {
    dispatch(addTagRequest())
    return fetch(`${API_URL}/tags`, fetchOptions(POST, {tag, componentUUID}))
      .then(response => {
        if (response.ok) {
          return response.json().then(function (json) {
            dispatch(addTagSuccess(json.message, json.tag))
            setTimeout(() => dispatch(dismissAlert()), 3000)
          })
        } else {
          dispatch(addTagError())
          setTimeout(() => dispatch(dismissAlert()), 3000)
        }
      }
      )
  }
}

const addTagRequest = () => {
  return {
    type: ADD_TAG_REQUEST
  }
}

const addTagSuccess = (message, tag) => {
  return {
    type: ADD_TAG_SUCCESS,
    message,
    tag
  }
}

const addTagError = () => {
  return dispatch => {
    dispatch(dismissAlert)
    return {
      type: ADD_TAG_ERROR
    }
  }
}

export function removeTag (tag, componentUUID) {
  return dispatch => {
    dispatch(removeTagRequest())
    return fetch(`${API_URL}/tags`, fetchOptions(DELETE, {tag, componentUUID}))
      .then(response => {
        if (response.ok) {
          return response.json().then(function (json) {
            dispatch(removeTagSuccess(json.message, tag))
            setTimeout(() => dispatch(dismissAlert()), 3000)
          })
        } else {
          dispatch(removeTagError())
          setTimeout(() => dispatch(dismissAlert()), 3000)
        }
      })
  }
}

const removeTagRequest = () => {
  return {
    type: REMOVE_TAG_REQUEST
  }
}

const removeTagSuccess = (message, tag) => {
  return {
    type: REMOVE_TAG_SUCCESS,
    message,
    tag
  }
}

const removeTagError = () => {
  return dispatch => {
    dispatch(dismissAlert)
    return {
      type: REMOVE_TAG_ERROR
    }
  }
}

// MODULE

export const moduleModified = (title) => {
  // tells history reducer to update
  return {
    type: MODULE_MODIFIED,
    title
  }
}

export const moduleChanging = () => {
  // tells module reducer that changes are pending
  return {
    type: MODULE_CHANGING
  }
}

export function removeFromModule (moduleTitle, componentUUID) {
  return dispatch => {
    dispatch(removeFromModuleRequest(componentUUID))
    return fetch(`${API_URL}/modules/${moduleTitle}/components/${componentUUID}`, fetchOptions(DELETE))
      .then(response => {
        if (response.ok) {
          dispatch(moduleModified(moduleTitle))
          dispatch(removeFromModuleSuccess(componentUUID))
        } else {
          dispatch(removeFromModuleError(componentUUID))
        }
      })
  }
}

export const removeFromModuleRequest = (componentUUID) => {
  return {
    type: REMOVE_FROM_MODULE_REQUEST,
    componentUUID
  }
}

export const removeFromModuleSuccess = (componentUUID) => {
  return {
    type: REMOVE_FROM_MODULE_SUCCESS,
    componentUUID
  }
}

const removeFromModuleError = (componentUUID) => {
  return {
    type: REMOVE_FROM_MODULE_ERROR,
    componentUUID
  }
}

export function removeAttachment (username, moduleTitle, sha1) {
  return dispatch => {
    dispatch(removeAttachmentRequest(sha1))
    return fetch(`${API_URL}/attachments/${username}/${moduleTitle}/${sha1}`, fetchOptions(DELETE))
      .then(response => {
        if (response.ok) {
          return response.json().then(function (json) {
            dispatch(moduleModified(moduleTitle))
            dispatch(removeAttachmentSuccess(json.attachments))
          })
        } else {
          dispatch(removeAttachmentError())
        }
      })
  }
}

export const removeAttachmentRequest = () => {
  return {
    type: REMOVE_ATTACHMENT_REQUEST
  }
}

export const removeAttachmentSuccess = (attachments) => {
  return {
    type: REMOVE_ATTACHMENT_SUCCESS,
    attachments
  }
}

const removeAttachmentError = () => {
  return {
    type: REMOVE_ATTACHMENT_ERROR
  }
}

export function removeEpisode (username, moduleTitle) {
  return dispatch => {
    dispatch(removeEpisodeRequest())
    return fetch(`${API_URL}/episode/${username}/${moduleTitle}`, fetchOptions(DELETE))
      .then(response => {
        if (response.ok) {
          dispatch(moduleModified(moduleTitle))
          dispatch(removeEpisodeSuccess())
        } else {
          dispatch(removeEpisodeError())
        }
      })
  }
}

export const removeEpisodeRequest = () => {
  return {
    type: REMOVE_EPISODE_REQUEST
  }
}

export const removeEpisodeSuccess = () => {
  return {
    type: REMOVE_EPISODE_SUCCESS
  }
}

const removeEpisodeError = () => {
  return {
    type: REMOVE_EPISODE_ERROR
  }
}

// /:title/move/:componentUUID/to/:toIndex

export function moveReference (moduleTitle, componentUUID, fromIndex, toIndex) {
  return dispatch => {
    if (fromIndex !== toIndex) {
      dispatch(moveReferenceRequest(moduleTitle, componentUUID, fromIndex, toIndex))
      return fetch(
        `${API_URL}/modules/${moduleTitle}/move/${componentUUID}/from/${fromIndex}/to/${toIndex}`,
        fetchOptions(PATCH)
      )
        .then(response => {
          if (response.ok) {
            dispatch(moduleModified(moduleTitle))
            dispatch(moveReferenceSuccess(moduleTitle, componentUUID, fromIndex, toIndex))
          } else {
            dispatch(moveReferenceError(moduleTitle, componentUUID, fromIndex, toIndex))
          }
        })
    } else {
      return null
    }
  }
}

export const moveReferenceRequest = (moduleTitle, componentUUID, fromIndex, toIndex) => {
  return {
    type: MOVE_REFERENCE_REQUEST,
    componentUUID,
    fromIndex,
    toIndex,
    moduleTitle
  }
}

export const moveReferenceSuccess = (moduleTitle, componentUUID, fromIndex, toIndex) => {
  return {
    type: MOVE_REFERENCE_SUCCESS,
    componentUUID,
    fromIndex,
    toIndex,
    moduleTitle
  }
}

const moveReferenceError = (moduleTitle, componentUUID, fromIndex, toIndex) => {
  return {
    type: MOVE_REFERENCE_ERROR,
    componentUUID,
    fromIndex,
    toIndex,
    moduleTitle
  }
}

// STARS

export function star (componentUUID) {
  return dispatch => {
    dispatch(starRequest(componentUUID))
    return fetch(`${API_URL}/stars/${componentUUID}`, fetchOptions(PUT))
      .then(response => {
        if (response.ok) {
          dispatch(starSuccess(componentUUID))
        } else {
          dispatch(starError(componentUUID))
        }
      })
    // .catch(dispatch(starError(componentUUID)))
  }
}

const starRequest = (componentUUID) => {
  return {
    type: STAR_REQUEST,
    componentUUID
  }
}

const starSuccess = (componentUUID) => {
  return {
    type: STAR_SUCCESS,
    componentUUID
  }
}

const starError = (componentUUID) => {
  return {
    type: STAR_ERROR,
    componentUUID
  }
}

export function unstar (componentUUID, apiToken) {
  return dispatch => {
    dispatch(unstarRequest(componentUUID))
    return fetch(`${API_URL}/stars/${componentUUID}`, fetchOptions(DELETE))
      .then(response => {
        if (response.ok) {
          dispatch(unstarSuccess(componentUUID))
        } else {
          dispatch(unstarError(componentUUID))
        }
      })
  }
}

const unstarRequest = (componentUUID) => {
  return {
    type: UNSTAR_REQUEST,
    componentUUID
  }
}

const unstarSuccess = (componentUUID) => {
  return {
    type: UNSTAR_SUCCESS,
    componentUUID
  }
}

const unstarError = (componentUUID) => {
  return {
    type: UNSTAR_ERROR,
    componentUUID
  }
}

// STORAGE LIBRARY

export function storageSync () {
  return dispatch => {
    dispatch(storageSyncRequest)
    return fetch(`${API_URL}/library/sync`, fetchOptions(GET))
      .then(response => {
        if (response.ok) {
          dispatch(storageSyncSuccess(response.payload.library, response.payload.unmatchedFiles))
          setTimeout(() => dispatch(dismissAlert()), 3000)
        } else {
          dispatch(storageSyncError())
          setTimeout(() => dispatch(dismissAlert()), 3000)
        }
      })
  }
}

const storageSyncRequest = () => {
  return {
    type: STORAGE_SYNC_REQUEST
  }
}

const storageSyncSuccess = (library, unmatchedFiles) => {
  return {
    type: STORAGE_SYNC_SUCCESS,
    library,
    unmatchedFiles
  }
}

const storageSyncError = () => {
  return {
    type: STORAGE_SYNC_ERROR
  }
}

export const updateFilter = terms => {
  return {
    type: UPDATE_FILTER,
    terms
  }
}

export const filterModule = () => {
  return {
    type: FILTER_MODULE
  }
}

function getOffsetTop (element) {
  var offsetTop = 0
  while (element && !isNaN(element.offsetTop)) {
    offsetTop += element.offsetTop - element.scrollTop
    element = element.offsetParent
  }
  return offsetTop < 10 ? 0 : (offsetTop - 10)
}

export const selectPage = page => {
  window.scroll(0, getOffsetTop(document.getElementById('search-element')))
  return {
    type: SELECT_PAGE,
    page
  }
}

export const recommend = (user, message, componentUUID) => {
  // console.log('recommend:')
  // console.log('userUUID: ' + user)
  // console.log('componentUUID: ' + componentUUID)
  return dispatch => {
    dispatch(recommendRequest)
    return fetch(`${API_URL}/recommendations/${user}/${componentUUID}`, fetchOptions(POST, {message: message}))
      .then(response => {
        if (response.ok) {
          return response.json().then(function (json) {
            dispatch(recommendSuccess(json.message, json.url))
            setTimeout(() => dispatch(dismissAlert()), 3000)
          })
        } else {
          dispatch(recommendError())
          setTimeout(() => dispatch(dismissAlert()), 3000)
        }
      })
  }
}

const recommendRequest = () => {
  return {
    type: RECOMMEND_REQUEST
  }
}

const recommendSuccess = (message, url) => {
  return {
    type: RECOMMEND_SUCCESS,
    message,
    url
  }
}

const recommendError = () => {
  return {
    type: RECOMMEND_ERROR
  }
}

export const acceptRecommendation = (recommendation) => {
  return dispatch => {
    dispatch(acceptRecommendationRequest)
    return fetch(`${API_URL}/recommendations/${recommendation.uuid}`, fetchOptions(PUT))
      .then(response => {
        if (response.ok) {
          return response.json().then(function (json) {
            dispatch(acceptRecommendationSuccess(recommendation.componentUUID, recommendation.uuid, json.payload.reference))
            dispatch(star(recommendation.componentUUID))
            setTimeout(() => dispatch(dismissAlert()), 3000)
          })
        } else {
          dispatch(acceptRecommendationError(recommendation.componentUUID))
          setTimeout(() => dispatch(dismissAlert()), 3000)
        }
      })
  }
}

const acceptRecommendationRequest = (componentUUID) => {
  return {
    type: ACCEPT_RECOMMENDATION_REQUEST,
    componentUUID
  }
}

const acceptRecommendationSuccess = (componentUUID, suggestionUUID, reference) => {
  return {
    type: ACCEPT_RECOMMENDATION_SUCCESS,
    componentUUID,
    suggestionUUID,
    reference
  }
}

const acceptRecommendationError = (componentUUID) => {
  return {
    type: ACCEPT_RECOMMENDATION_ERROR,
    componentUUID
  }
}

export const declineRecommendation = (recUUID, componentUUID) => {
  return dispatch => {
    dispatch(declineRecommendationRequest(componentUUID))
    return fetch(`${API_URL}/recommendations/${recUUID}`, fetchOptions(DELETE))
      .then(response => {
        if (response.ok) {
          dispatch(declineRecommendationSuccess(componentUUID))
        } else {
          dispatch(declineRecommendationError(componentUUID))
        }
      })
  }
}

const declineRecommendationRequest = (componentUUID) => {
  return {
    type: REMOVE_FROM_MODULE_REQUEST,
    componentUUID
  }
}

const declineRecommendationSuccess = (componentUUID) => {
  return {
    type: REMOVE_FROM_MODULE_SUCCESS,
    componentUUID
  }
}

const declineRecommendationError = (componentUUID) => {
  return {
    type: REMOVE_FROM_MODULE_ERROR,
    componentUUID
  }
}

export const moduleVisited = (author, title) => {
  return {
    type: MODULE_VISITED,
    author,
    title
  }
}

export const referenceTouched = (componentUUID) => {
  return {
    type: REFERENCE_TOUCHED,
    componentUUID
  }
}

export const dismissAlert = () => {
  return {type: DISMISS_ALERT}
}

export const editModule = () => {
  return {
    type: EDIT_MODULE
  }
}

export const viewModule = () => {
  return {
    type: VIEW_MODULE
  }
}

export const shareLink = (moduleUUID, url) => {
  return dispatch => {
    dispatch(shareLinkRequest())
    return fetch(`${API_URL}/links/${moduleUUID}`, fetchOptions(POST, {url: url}))
      .then(response => {
        if (response.ok) {
          return response.json().then(function (json) {
            dispatch(shareLinkSuccess(json.message, json.url, json.payload.link, moduleUUID))
          })
        } else {
          dispatch(shareLinkError())
          setTimeout(() => dispatch(dismissAlert()), 3000)
        }
      })
  }
}

const shareLinkRequest = () => {
  return {
    type: SHARE_LINK_REQUEST
  }
}

const shareLinkSuccess = (message, url, link, moduleUUID) => {
  return {
    type: SHARE_LINK_SUCCESS,
    message,
    url,
    link,
    moduleUUID
  }
}

const shareLinkError = () => {
  return {
    type: SHARE_LINK_ERROR
  }
}

export const updateReadme = (moduleUUID, readme) => {
  return dispatch => {
    dispatch(updateReadmeRequest())
    return fetch(`${API_URL}/modules/${moduleUUID}/readme`, fetchOptions(PATCH, {readme: readme}))
      .then(response => {
        if (response.ok) {
          dispatch(updateReadmeSuccess(readme))
        } else {
          dispatch(updateReadmeError())
        }
      })
  }
}

const updateReadmeRequest = () => {
  return {
    type: UPDATE_README_REQUEST
  }
}

const updateReadmeSuccess = (readme) => {
  return {
    type: UPDATE_README_SUCCESS,
    readme
  }
}

const updateReadmeError = () => {
  return {
    type: UPDATE_README_ERROR
  }
}

export const updateDescription = (moduleUUID, description) => {
  return dispatch => {
    dispatch(updateDescriptionRequest())
    return fetch(`${API_URL}/modules/${moduleUUID}/description`, fetchOptions(PATCH, {description}))
      .then(response => {
        if (response.ok) {
          dispatch(updateDescriptionSuccess(description))
        } else {
          dispatch(updateDescriptionError())
        }
      })
  }
}

const updateDescriptionRequest = () => {
  return {
    type: UPDATE_DESCRIPTION_REQUEST
  }
}

const updateDescriptionSuccess = (description) => {
  return {
    type: UPDATE_DESCRIPTION_SUCCESS,
    description
  }
}

const updateDescriptionError = () => {
  return {
    type: UPDATE_DESCRIPTION_ERROR
  }
}

export const register = (username, email, password, gReCAPTCHAResponse) => {
  return dispatch => {
    dispatch(registerRequest())
    return fetch(`${API_URL}/users/register`, fetchOptions(POST, {
      username,
      email,
      password,
      'g-recaptcha-response': gReCAPTCHAResponse
    }))
      .then(response => {
        if (response.ok) {
          return response.json().then(function (json) {
            dispatch(registerSuccess(json.user))
          })
        } else {
          return response.json().then(function (json) {
            dispatch(registerError(json.message))
          })
        }
      })
  }
}

const registerRequest = () => {
  return {
    type: REGISTER_REQUEST
  }
}

const registerSuccess = (user) => {
  window.location.href = `/${user.username}`
  return {
    type: REGISTER_SUCCESS,
    user
  }
}

const registerError = (message) => {
  return {
    type: REGISTER_ERROR,
    message
  }
}

export const login = (username, password) => {
  return dispatch => {
    dispatch(loginRequest())
    return fetch(`${API_URL}/users/login`, fetchOptions(POST, {username, password}))
      .then(response => {
        if (response.ok) {
          return response.json().then(function (json) {
            dispatch(loginSuccess(json.user, json.stars, json.storage))
          })
        } else {
          return response.json().then(function (json) {
            dispatch(loginError(json.message))
          })
        }
      })
  }
}

const loginRequest = () => {
  return {
    type: LOGIN_REQUEST
  }
}

const loginSuccess = (user, stars, storage) => {
  if (['/about', '/search', '/'].indexOf(window.location.pathname) === -1) {
    window.location.reload()
  } else {
    window.location.assign(`${window.location.origin}/${user.username}`)
  }
  return {
    type: LOGIN_SUCCESS,
    user,
    stars,
    storage
  }
}

const loginError = (message) => {
  return {
    type: LOGIN_ERROR,
    message
  }
}

export const forgotPassword = (email, gReCAPTCHAResponse) => {
  return dispatch => {
    dispatch(forgotPasswordRequest())
    return fetch(`${API_URL}/users/forgot_password`, fetchOptions(POST, {
      email,
      'g-recaptcha-response': gReCAPTCHAResponse
    }))
      .then(response => {
        if (response.ok) {
          return response.json().then(function (json) {
            dispatch(forgotPasswordSuccess(json.message))
            setTimeout(() => dispatch(dismissAlert()), 5000)
          })
        } else {
          return response.json().then(function (json) {
            dispatch(forgotPasswordError(json.message))
          })
        }
      })
  }
}

const forgotPasswordRequest = () => {
  return {
    type: FORGOT_PASSWORD_REQUEST
  }
}

const forgotPasswordSuccess = (message) => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    message
  }
}

const forgotPasswordError = (message) => {
  return {
    type: FORGOT_PASSWORD_ERROR,
    message
  }
}

export const uploadAttachments = (files, fileSha1Map, moduleAuthor, moduleTitle, organization = null) => {
  // console.log('uploadAttachments => fileSha1Map: ' + JSON.stringify(fileSha1Map))
  return dispatch => {
    dispatch(uploadAttachmentsRequest())
    let uploadUrl = `${API_URL}/attachments/${moduleAuthor}/${moduleTitle}`
    if (organization) uploadUrl += `/${organization}`
    let xhr = new XMLHttpRequest() // eslint-disable-line

    const updateProgress = (evt) => {
      if (evt.lengthComputable) {
        dispatch(uploadProgress(evt.total, evt.loaded, files.length))
      }
    }
    const transferComplete = (evt) => {
      //
    }
    const transferFailed = (evt) => {
      dispatch(uploadAttachmentsError())
      setTimeout(() => dispatch(dismissAlert()), 3000)
    }
    const transferCanceled = (evt) => {
      dispatch(uploadAttachmentsError())
      setTimeout(() => dispatch(dismissAlert()), 3000)
    }
    const uploadDone = (evt) => {
      // console.log('xhr.responseText: ' + xhr.responseText)
      // console.log('evt.target.responseText: ' + evt.target.responseText)
      dispatch(uploadAttachmentsSuccess(JSON.parse(xhr.responseText).attachments))
      dispatch(uploadComplete())
      setTimeout(() => dispatch(dismissAlert()), 3000)
    }

    // new FormData as a container for the files
    var formData = new FormData()
    // for each entry, add to formdata
    for (var i = 0; i < files.length; i++) {
      formData.append('attachments', files[i])
    }

    // send formData to server-side
    xhr.addEventListener('load', uploadDone)
    xhr.upload.addEventListener('progress', updateProgress)
    xhr.upload.addEventListener('load', transferComplete)
    xhr.upload.addEventListener('error', transferFailed)
    xhr.upload.addEventListener('abort', transferCanceled)
    xhr.open('post', uploadUrl)
    xhr.setRequestHeader('x-csrf-token', getCSRF())
    // console.log(`uploadAttachments.fileSha1Map: ${JSON.stringify(fileSha1Map)}`)
    xhr.setRequestHeader('file-sha1-map', JSON.stringify(fileSha1Map))
    xhr.send(formData)
  }
}

const uploadProgress = (total, loaded, fileCount) => {
  return {
    type: UPLOAD_PROGRESS,
    total,
    loaded,
    fileCount
  }
}

const uploadComplete = () => {
  return {
    type: UPLOAD_COMPLETE
  }
}

const uploadAttachmentsRequest = () => {
  return {
    type: UPLOAD_ATTACHMENTS_REQUEST
  }
}

const uploadAttachmentsSuccess = (attachments) => {
  return {
    type: UPLOAD_ATTACHMENTS_SUCCESS,
    attachments
  }
}

const uploadAttachmentsError = () => {
  return {
    type: UPLOAD_ATTACHMENTS_ERROR
  }
}

export const uploadLibrary = (files, fileSha1Map) => {
  // console.log('uploadLibrary => fileSha1Map: ' + JSON.stringify(fileSha1Map))
  return dispatch => {
    dispatch(uploadLibraryRequest())
    let uploadUrl = `${API_URL}/library`
    let xhr = new XMLHttpRequest() // eslint-disable-line

    const updateProgress = (evt) => {
      if (evt.lengthComputable) {
        dispatch(uploadLibraryProgress(evt.total, evt.loaded, files.length))
      }
    }
    const transferComplete = (evt) => {
      //
    }
    const transferFailed = (evt) => {
      dispatch(uploadLibraryError())
      setTimeout(() => dispatch(dismissAlert()), 3000)
    }
    const transferCanceled = (evt) => {
      dispatch(uploadLibraryError())
      setTimeout(() => dispatch(dismissAlert()), 3000)
    }
    const uploadDone = (evt) => {
      // console.log('xhr.responseText: ' + xhr.responseText)
      if (xhr.status !== 201) {
        // console.log('status !== 201')
        dispatch(uploadLibraryError())
        setTimeout(() => dispatch(dismissAlert()), 3000)
      } else {
        // console.log('status === 201')
        dispatch(uploadLibrarySuccess())
        // console.log('responseText.components: ' + JSON.stringify(JSON.parse(xhr.responseText).components))
        // console.log('responseText.attachments: ' + JSON.stringify(JSON.parse(xhr.responseText).attachments))
        // if (JSON.parse(xhr.responseText).components || JSON.parse(xhr.responseText).attachments) {
        //   dispatch(libraryUpdated(JSON.parse(xhr.responseText).components, JSON.parse(xhr.responseText).attachments))
        // }
        setTimeout(() => dispatch(dismissAlert()), 3000)
      }
    }

    // new FormData as a container for the files
    var formData = new FormData()
    // for each entry, add to formdata
    for (var i = 0; i < files.length; i++) {
      formData.append('library', files[i])
    }

    // send formData to server-side
    xhr.addEventListener('load', uploadDone)
    xhr.upload.addEventListener('progress', updateProgress)
    xhr.upload.addEventListener('load', transferComplete)
    xhr.upload.addEventListener('error', transferFailed)
    xhr.upload.addEventListener('abort', transferCanceled)
    xhr.open('post', uploadUrl)
    xhr.setRequestHeader('x-csrf-token', getCSRF())
    xhr.setRequestHeader('file-sha1-map', JSON.stringify(fileSha1Map))
    xhr.send(formData)
  }
}

const uploadLibraryProgress = (total, loaded, fileCount) => {
  return {
    type: UPLOAD_LIBRARY_PROGRESS,
    total,
    loaded,
    fileCount
  }
}

const uploadLibraryRequest = () => {
  return {
    type: UPLOAD_LIBRARY_REQUEST
  }
}

const uploadLibrarySuccess = () => {
  return {
    type: UPLOAD_LIBRARY_SUCCESS
  }
}

const uploadLibraryError = () => {
  return {
    type: UPLOAD_LIBRARY_ERROR
  }
}

const libraryUpdated = (components, attachments) => {
  // console.log('action.components: ' + JSON.stringify(components))
  // console.log('action.attachments: ' + JSON.stringify(attachments))
  return {
    type: LIBRARY_UPDATED,
    components,
    attachments
  }
}

export const deleteLibraryItem = (sha1) => {
  return dispatch => {
    dispatch(deleteLibraryItemRequest(sha1))
    return fetch(`${API_URL}/library/${sha1}`, fetchOptions(DELETE, {}))
      .then(response => {
        if (response.ok) {
          dispatch(deleteLibraryItemSuccess(sha1))
          setTimeout(() => dispatch(dismissAlert()), 5000)
        } else {
          dispatch(deleteLibraryItemError(sha1))
          setTimeout(() => dispatch(dismissAlert()), 5000)
        }
      })
  }
}

const deleteLibraryItemRequest = (sha1) => {
  return {
    type: DELETE_LIBRARY_ITEM_REQUEST,
    sha1
  }
}

const deleteLibraryItemSuccess = (sha1) => {
  return {
    type: DELETE_LIBRARY_ITEM_SUCCESS,
    sha1
  }
}

const deleteLibraryItemError = (sha1) => {
  return {
    type: DELETE_LIBRARY_ITEM_ERROR,
    sha1
  }
}

export const patchLibraryItem = (sha1, componentUUID) => {
  return dispatch => {
    dispatch(patchLibraryItemRequest(sha1))
    if (!componentUUID) componentUUID = ''
    return fetch(`${API_URL}/library/${sha1}`, fetchOptions(PATCH, {componentUUID}))
      .then(response => {
        if (response.ok) {
          return response.json().then(function (json) {
            dispatch(patchLibraryItemSuccess(sha1, json.componentUUID))
            setTimeout(() => dispatch(dismissAlert()), 5000)
          })
        } else {
          dispatch(patchLibraryItemError(sha1))
          setTimeout(() => dispatch(dismissAlert()), 5000)
        }
      })
  }
}

const patchLibraryItemRequest = (sha1) => {
  return {
    type: PATCH_LIBRARY_ITEM_REQUEST,
    sha1
  }
}

const patchLibraryItemSuccess = (sha1, componentUUID) => {
  return {
    type: PATCH_LIBRARY_ITEM_SUCCESS,
    sha1,
    componentUUID
  }
}

const patchLibraryItemError = (sha1) => {
  return {
    type: PATCH_LIBRARY_ITEM_ERROR,
    sha1
  }
}

export const windowResize = (force = false) => {
  return {
    type: WINDOW_RESIZE,
    force
  }
}

export const searchPubmed = (searchOptions) => {
  return dispatch => {
    // console.log('searchPubmd.searchOptions: ' + JSON.stringify(searchOptions))
    dispatch(searchPubmedRequest())
    return fetch(`${API_URL}/search/pubmed`, fetchOptions(POST, searchOptions))
      .then(response => {
        if (response.ok) {
          return response.json().then(function (json) {
            dispatch(searchPubmedSuccess(json.components, json.term, json.count, json.retstart, json.retmax, json.webEnv, json.queryKey))
          })
        } else {
          return response.json().then(function (json) {
            dispatch(searchPubmedError(json.message))
            setTimeout(() => dispatch(dismissAlert()), 5000)
          })
        }
      })
  }
}

const searchPubmedRequest = () => {
  // console.log('searchPubmedRequest')
  return {
    type: SEARCH_PUBMED_REQUEST
  }
}

const searchPubmedSuccess = (components, term, count, retstart, retmax, webEnv, queryKey) => {
  // console.log('searchPubmedSucess')
  return {
    type: SEARCH_PUBMED_SUCCESS,
    components,
    term,
    count,
    retstart,
    retmax,
    webEnv,
    queryKey
  }
}

const searchPubmedError = (message) => {
  // console.log('searchPubmedError')
  return {
    type: SEARCH_PUBMED_ERROR,
    message
  }
}

export const selectPubmedPage = (page, args) => {
  window.scroll(0, getOffsetTop(document.getElementById('search-element')))
  return dispatch => {
    // console.log('selectPubmedPage ' + page + ': ' + JSON.stringify(args))
    dispatch(selectPubmedPageRequest())
    return fetch(`${API_URL}/search/${page}`, fetchOptions(POST, args))
      .then(response => {
        if (response.ok) {
          return response.json().then(function (json) {
            dispatch(selectPubmedPageSuccess(page, json.components))
          })
        } else {
          return response.json().then(function (json) {
            dispatch(selectPubmedPageError(json.message))
            setTimeout(() => dispatch(dismissAlert()), 5000)
          })
        }
      })
  }
}

const selectPubmedPageRequest = () => {
  // console.log('selectPubmedPageRequest')
  return {
    type: SELECT_PUBMED_PAGE_REQUEST
  }
}

const selectPubmedPageSuccess = (page, components) => {
  // console.log('selectPubmedPageSucess')
  return {
    type: SELECT_PUBMED_PAGE_SUCCESS,
    page,
    components
  }
}

const selectPubmedPageError = (message) => {
  // console.log('selectPubmedPageError')
  return {
    type: SELECT_PUBMED_PAGE_ERROR,
    message
  }
}

export const reconcilePubmed = (searchOptions) => {
  return dispatch => {
    // console.log('searchPubmd')
    dispatch(reconcilePubmedRequest())
    return fetch(`${API_URL}/search`, fetchOptions(POST, searchOptions))
      .then(response => {
        if (response.ok) {
          return response.json().then(function (json) {
            dispatch(reconcilePubmedSuccess(searchOptions, json.components, json.term, json.count, json.retstart, json.retmax, json.webEnv, json.queryKey))
          })
        } else {
          return response.json().then(function (json) {
            dispatch(reconcilePubmedError(json.message))
            setTimeout(() => dispatch(dismissAlert()), 5000)
          })
        }
      })
  }
}

const reconcilePubmedRequest = () => {
  // console.log('reconcilePubmedRequest')
  return {
    type: RECONCILE_PUBMED_REQUEST
  }
}

const reconcilePubmedSuccess = (searchTerm, components, term, count, retstart, retmax, webEnv, queryKey) => {
  // console.log('reconcilePubmedSucess')
  return {
    type: RECONCILE_PUBMED_SUCCESS,
    searchTerm,
    components,
    term,
    count,
    retstart,
    retmax,
    webEnv,
    queryKey
  }
}

const reconcilePubmedError = (message) => {
  // console.log('searchPubmedError')
  return {
    type: RECONCILE_PUBMED_ERROR,
    message
  }
}

export const searchAll = (term) => {
  return dispatch => {
    dispatch(searchAllRequest())
    return fetch(`${API_URL}/search`, fetchOptions(POST, {term}))
      .then(response => {
        if (response.ok) {
          return response.json().then(function (json) {
            dispatch(searchAllSuccess(json))
          })
        } else {
          return response.json().then(function (json) {
            dispatch(searchAllError(json.message))
            setTimeout(() => dispatch(dismissAlert()), 5000)
          })
        }
      })
  }
}

const searchAllRequest = () => {
  // console.log('searchAllRequest')
  return {
    type: SEARCH_ALL_REQUEST
  }
}

const searchAllSuccess = (search) => {
  // console.log('searchAllSucess')
  return {
    type: SEARCH_ALL_SUCCESS,
    search
  }
}

const searchAllError = (message) => {
  // console.log('searchAllError')
  return {
    type: SEARCH_ALL_ERROR,
    message
  }
}

export const reconcileUpload = (componentUUID, sha1, originalComponentUUID) => {
  return dispatch => {
    dispatch(reconcileUploadRequest())
    return fetch(`${API_URL}/library/reconcile`, fetchOptions(POST, {sha1, componentUUID, originalComponentUUID}))
      .then(response => {
        if (response.ok) {
          return response.json().then(function (json) {
            dispatch(libraryUpdated(json.components, json.attachments))
            dispatch(uploadLibrarySuccess(json.storage))
          })
        } else {
          return response.json().then(function (json) {
            dispatch(reconcileUploadError(json.message))
            setTimeout(() => dispatch(dismissAlert()), 5000)
          })
        }
      })
  }
}

const reconcileUploadRequest = () => {
  return {
    type: RECONCILE_UPLOAD_REQUEST
  }
}

const reconcileUploadError = (message) => {
  return {
    type: RECONCILE_UPLOAD_ERROR,
    message
  }
}

export const selectSearchAll = () => {
  return {
    type: SELECT_SEARCH_ALL
  }
}

export const selectPubmedSearch = () => {
  return {
    type: SELECT_PUBMED_SEARCH
  }
}

export const selectLitsignalSearch = () => {
  return {
    type: SELECT_LITSIGNAL_SEARCH
  }
}

export const selectBookSearch = () => {
  return {
    type: SELECT_BOOK_SEARCH
  }
}

export const clearPubmedSearch = () => {
  // console.log('clearPubmedSearch')
  return {type: CLEAR_PUBMED_SEARCH}
}

export const undoPubmedSearch = () => {
  return {type: UNDO_PUBMED_SEARCH}
}

export const updateSearchTerm = (term) => {
  return {
    type: UPDATE_SEARCH_TERM,
    term
  }
}

export const searchLitsignal = (term) => {
  return dispatch => {
    // console.log('searchPubmd')
    dispatch(searchLitsignalRequest())
    return fetch(`${API_URL}/search/litsignal`, fetchOptions(POST, {term}))
      .then(response => {
        if (response.ok) {
          return response.json().then(function (json) {
            dispatch(searchLitsignalSuccess(json.components, json.term))
          })
        } else {
          return response.json().then(function (json) {
            dispatch(searchLitsignalError(json.message))
            setTimeout(() => dispatch(dismissAlert()), 5000)
          })
        }
      })
  }
}

export const searchLitsignalRequest = () => {
  return {
    type: SEARCH_LITSIGNAL_REQUEST
  }
}

export const searchLitsignalError = (message) => {
  return {
    type: SEARCH_LITSIGNAL_ERROR,
    message
  }
}

export const searchLitsignalSuccess = (components, term) => {
  return {
    type: SEARCH_LITSIGNAL_SUCCESS,
    components,
    term
  }
}

export const clearSearch = () => {
  return {
    type: CLEAR_SEARCH
  }
}

export const searchBook = (term) => {
  return dispatch => {
    // console.log('searchPubmd')
    dispatch(searchBookRequest())
    return fetch(`${API_URL}/search/litsignal`, fetchOptions(POST, {term}))
      .then(response => {
        if (response.ok) {
          return response.json().then(function (json) {
            dispatch(searchBookSuccess(json.components, json.term))
          })
        } else {
          return response.json().then(function (json) {
            dispatch(searchBookError(json.message))
            setTimeout(() => dispatch(dismissAlert()), 5000)
          })
        }
      })
  }
}

export const searchBookRequest = () => {
  return {
    type: SEARCH_BOOK_REQUEST
  }
}

export const searchBookError = (message) => {
  return {
    type: SEARCH_BOOK_ERROR,
    message
  }
}

export const searchBookSuccess = (components, term) => {
  return {
    type: SEARCH_BOOK_SUCCESS,
    components,
    term
  }
}

export const uploadEpisode = (files, fileSha1Map, episodeMetadata) => {
  let {author, title, canonicalTitle, fullName, podcastTitle, number, year, description, readme} = episodeMetadata
  // console.log('uploadEpisode => fileSha1Map: ' + JSON.stringify(fileSha1Map))
  return dispatch => {
    dispatch(uploadEpisodeRequest())
    let uploadUrl = `${API_URL}/episode/${author}/${title}`
    let xhr = new XMLHttpRequest() // eslint-disable-line

    const updateProgress = (evt) => {
      if (evt.lengthComputable) {
        dispatch(uploadProgress(evt.total, evt.loaded, files.length))
      }
    }
    const transferComplete = (evt) => {
      //
    }
    const transferFailed = (evt) => {
      dispatch(uploadEpisodeError())
      setTimeout(() => dispatch(dismissAlert()), 3000)
    }
    const transferCanceled = (evt) => {
      dispatch(uploadEpisodeError())
      setTimeout(() => dispatch(dismissAlert()), 3000)
    }
    const uploadDone = (evt) => {
      // console.log('xhr.responseText: ' + xhr.responseText)
      // console.log('evt.target.responseText: ' + evt.target.responseText)
      dispatch(moduleModified(title))
      dispatch(uploadEpisodeSuccess(JSON.parse(xhr.responseText).episode))
      dispatch(uploadComplete())
      setTimeout(() => dispatch(dismissAlert()), 3000)
    }

    // new FormData as a container for the files
    var formData = new FormData()
    // for each entry, add to formdata
    for (var i = 0; i < files.length; i++) {
      formData.append('episode', files[i])
      formData.append('canonicalTitle', canonicalTitle)
      formData.append('fullName', fullName)
      formData.append('number', number)
      formData.append('podcastTitle', podcastTitle)
      formData.append('year', year)
      formData.append('description', description)
      formData.append('readme', readme)
    }

    // send formData to server-side
    xhr.addEventListener('load', uploadDone)
    xhr.upload.addEventListener('progress', updateProgress)
    xhr.upload.addEventListener('load', transferComplete)
    xhr.upload.addEventListener('error', transferFailed)
    xhr.upload.addEventListener('abort', transferCanceled)
    xhr.open('post', uploadUrl)
    xhr.setRequestHeader('x-csrf-token', getCSRF())
    xhr.setRequestHeader('file-sha1-map', JSON.stringify(fileSha1Map))
    xhr.send(formData)
  }
}

const uploadEpisodeRequest = () => {
  return {
    type: UPLOAD_EPISODE_REQUEST
  }
}

const uploadEpisodeSuccess = (episode) => {
  return {
    type: UPLOAD_EPISODE_SUCCESS,
    episode
  }
}

const uploadEpisodeError = () => {
  return {
    type: UPLOAD_EPISODE_ERROR
  }
}

export const dismissReleaseNotes = (version) => {
  return dispatch => {
    dispatch(dismissReleaseNotesRequest())
    let fetchUrl = `${API_URL}/notifications/releases/${version}`
    return fetch(fetchUrl, fetchOptions(DELETE))
      .then(response => {
        if (response.ok) {
          return dispatch(dismissReleaseNotesSuccess())
        } else {
          return dispatch(dismissReleaseNotesError())
        }
      })
  }
}

const dismissReleaseNotesRequest = () => {
  return {
    type: DISMISS_RELEASE_NOTES_REQUEST
  }
}

const dismissReleaseNotesSuccess = () => {
  return {
    type: DISMISS_RELEASE_NOTES_SUCCESS
  }
}

const dismissReleaseNotesError = () => {
  return {
    type: DISMISS_RELEASE_NOTES_ERROR
  }
}
