import React, { Component } from 'react'
import PropTypes from 'prop-types'
import UserSearchForm from '../components/UserSearchForm'
import {debounce} from 'lodash'
import fetch from 'isomorphic-fetch'
import {API_URL, GET} from '../constants'
import {fetchOptions} from '../helpers'
import UserSearchResults from '../components/UserSearchResults'

class AsyncUserSearch extends Component {
  constructor (props) {
    super(props)
    this.state = {term: this.props.term || '', results: []}
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleInputBlur = this.handleInputBlur.bind(this)
    this.debounceSearchUser = debounce(this.debounceSearchUser.bind(this), 300)
  }

  debounceSearchUser (term) {
    // console.log('AsyncUserSearch.debounceSearchUser()')
    let search = this
    if (term) {
      fetch(`${API_URL}/users/search/${term}`, fetchOptions(GET))
        .then(response => {
          if (response.ok) {
            return response.json().then(function (json) {
              const components = json.users.map(user => {
                return {
                  uuid: user.uuid,
                  type: 'user',
                  metadata: {
                    username: user.username,
                    fullName: user.fullName,
                    uuid: user.uuid,
                    location: user.location
                  }
                }
              })
              search.setState({...search.state, results: components})
            })
          } else {
            return response.json().then(function (json) {
              search.setState({...search.state, results: new Error('Unable to locate matching users.')})
            })
          }
        })
    } else {
      this.setState({...this.state, results: [], term: ''})
    }
  }

  handleInputChange (evt) {
    this.setState({...this.state, term: evt.target.value})
    this.debounceSearchUser(evt.target.value)
  }

  handleInputBlur (evt) {
    this.setState({...this.state, term: '', results: []})
  }

  render () {
    const {dispatch, stars, isDesktop, user} = this.props
    const {term, results} = this.state
    return (
      <div className='form-group my-1 col px-0' >
        <UserSearchForm
          onInputChange={this.handleInputChange}
          onInputBlur={this.handleInputBlur}
          term={term}
        />
        {results.length > 0 && <UserSearchResults results={results} dispatch={dispatch} noFilter stars={stars} isDesktop={isDesktop} user={user} />}
      </div>
    )
  }
}

AsyncUserSearch.propTypes = {
  dispatch: PropTypes.func.isRequired,
  stars: PropTypes.object,
  isDesktop: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired
}

export default AsyncUserSearch
