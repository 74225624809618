import React from 'react'
import EditReferences from '../components/EditReferences'
import EditDescription from '../containers/EditDescription'
import UploadProgress from '../components/UploadProgress'
import Attachment from '../components/Attachment'
import ReadmeEditor from '../components/ReadmeEditor'
// TODO: reenable tagging once there are routes for tags to reach
import AsyncRemoveTag from '../containers/AsyncRemoveTag'

const ModuleEditor = ({
  user,
  module,
  stars,
  history,
  storage,
  dispatch,
  isDesktop,
  search
}) => {
  // console.log('user (ModuleEditor): ' + JSON.stringify(user))
  // console.log('isDesktop: ' + isDesktop)
  const isUploads = module.title === 'uploads'
  const modifiedModules = history.modules.modified
  let userLoggedIn = !!user.username

  const Attachments = ({attachments}) => {
    if (attachments && attachments.length > 0) {
      let label = module.title === 'uploads' ? 'UNMATCHED FILES' : 'ATTACHMENTS'
      return (
        <div className='media-list'>
          <li className='media list-group-item ls-heavy'>{label}
            <div className='btn-toolbar' role='toolbar' aria-label='Attachments'>
              {module.attachments.map((attachment, index, array) => {
                return <Attachment attachment={attachment} author={module.author} moduleTitle={module.title} key={index} dispatch={dispatch} editing isUploads={isUploads} search={search} />
              })}
            </div>
          </li>
        </div>
      )
    } else {
      return null
    }
  }

  const moduleClass = 'col-sm-12'

  return (
    <div>
      <div className='row'>
        <div className={moduleClass}>
          {module.tags && module.tags.length > 0 && <div className='card'>
            <div className='card-body ls-module-controls'>
              <div className='ls-heavy'>TAGS</div>
              {module.tags.map((tag, index) => {
                return (
                  <AsyncRemoveTag key={index} tag={tag} componentUUID={module.uuid} dispatch={dispatch} />
                )
              })}
            </div>
          </div>}
          {module.uploading && <UploadProgress />}
          <div className='media-list'>
            <li className='media list-group-item'>
              <EditDescription module={module} dispatch={dispatch} />
              <br />
              <ReadmeEditor module={module} dispatch={dispatch} />
            </li>
          </div>
          <Attachments attachments={module.attachments} />
          <EditReferences module={module} label='REFERENCES' dispatch={dispatch} stars={stars} storage={storage} search={search} isDesktop={isDesktop} userLoggedIn={userLoggedIn} modifiedModules={modifiedModules} />
        </div>
        {/* {userLoggedIn && userCanEdit && user.podcastEnabled && !module.episode && <div className='card'><div className='card-body ls-module-controls'>
              <h4>Podcast</h4>
              <p>Upload an mp3 file to convert this module to a podcast episode. The module text will be used as show notes, and the episode url will point to this page.</p>
              <AsyncUploadEpisode dispatch={dispatch} bsStyle='default' module={module} user={user} />
            </div></div>} */}
      </div>
    </div>
  )
}

export default ModuleEditor
