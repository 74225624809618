const SearchForm = ({
  term,
  type,
  onInputChange
}) => {
  const formUrl = type !== 'all' ? `/search/${type}` : '/search'
  const getPlaceholder = () => {
    switch (type) {
      case 'all': {
        return 'Search for articles or modules'
      }
      case 'litsignal': {
        return 'Search for Litsignal modules'
      }
      case 'pubmed': {
        return 'Search for Pubmed articles'
      }
    }
  }
  return (
    <form className='form-inline w-100' method='get' action={formUrl}>
      <div className='form-group my-1 col px-0' >
        <div className='input-group my-0 col px-0'>
          <input
            id='term'
            className='form-control'
            name='term'
            placeholder={getPlaceholder()}
            onChange={onInputChange}
            value={term}
            autoFocus />
          <div className='input-group-append'>
            <button className='btn btn-light' type='submit'><i className='icon icon-magnifying-glass' /></button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default SearchForm
