import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import logger from 'redux-logger'
import rootReducer from './reducers/rootReducer'
import saveHistory from './middlewares/saveHistory'

export default function configureStore (initialState) {
  if (process.env.NODE_ENV === 'development') {
    return createStore(
      rootReducer,
      initialState,
      applyMiddleware(
        thunkMiddleware,
        logger,
        saveHistory
      )
    )
  } else {
    return createStore(
      rootReducer,
      initialState,
      applyMiddleware(
        thunkMiddleware,
        saveHistory
      )
    )
  }
}
