import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
  return {
    alert: state.alert
  }
}

const Message = ({
  message,
  url
}) => {
  if (url) {
    return (
      <a className='alert-link' href={url}>message</a>
    )
  } else {
    return (
      <span>{message}</span>
    )
  }
}

const AlertContainer = ({
  alert
}) => {
  const alertComp = (
    <Message message={alert.message} url={alert.url} />
  )
  if (alert.visible) {
    return (
      <div className={`alert alert-${alert.category || 'info'}`} style={{margin: 0, border: 0}}>
        {alertComp}
      </div>
    )
  } else {
    return null
  }
}

AlertContainer.propTypes = {
  alert: PropTypes.object.isRequired
}

const Alert = connect(mapStateToProps)(AlertContainer)

export default Alert
