import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ModalCard from '../components/ModalCard'
import {getCSRF} from '../helpers'

class RequestCollaboration extends Component {
  constructor (props) {
    super(props)
    this.state = {showRequestCollaborationModal: false}
    this.openRequestCollaboration = this.openRequestCollaboration.bind(this)
    this.close = this.close.bind(this)
  }

  close (e) {
    e.stopPropagation()
    this.setState({ showRequestCollaborationModal: false })
  }
  openRequestCollaboration () {
    this.setState({ showRequestCollaborationModal: true })
  }

  render () {
    const { author, title, isDesktop } = this.props
    // const isFirstModule = moduleOptions.length === 0
    return (
      <a tabIndex='-1' role='button' title='Submit your changes to the original module' onClick={this.openRequestCollaboration}>
        <i className='icon icon-flow-cascade' />{isDesktop ? 'Collaborate' : ''}
        {this.state.showRequestCollaborationModal && <ModalCard onHide={this.close}>
          <div className='card-body'>
            <h4><i className='icon icon-flow-cascade' />Collaboration</h4>
            <form method='post' action={`/${author}/${title}/collaborations`} >
              <fieldset>
                <p>Submit a collaboration request to give the original author an opportunity to incorporate your changes into the original module.</p>
                <textarea id='message' name='message' type='text' className='form-control' placeholder='Optional message describing your contribution.' />
                <input type='hidden' name='_csrf' value={getCSRF()} />
                <br />
                <button className='btn btn-primary' type='submit' name='collaborate'>
                Request collaboration
                </button>
              </fieldset>
            </form>
          </div>
        </ModalCard>}
      </a>
    )
  }
}

RequestCollaboration.propTypes = {
  isDesktop: PropTypes.bool,
  author: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default RequestCollaboration
