import React from 'react'
import { editModule, windowResize } from '../actions'

const EditModule = ({
  dispatch,
  isDesktop
}) => {
  const onEditing = () => {
    dispatch(editModule())
    setTimeout(() => {
      dispatch(windowResize(true))
    }, 5)
  }

  return (
    <button className='btn btn-primary btn-lg btn-block' tabIndex='-1' role='button' onClick={onEditing}>
      <i className='icon icon-pencil' />{isDesktop ? ' Edit' : ''}
    </button>
  )
}

export default EditModule
