import AsyncRemoveAttachment from '../containers/AsyncRemoveAttachment'

const Attachment = ({
  attachment,
  author,
  moduleTitle,
  dispatch,
  editing
}) => {
  const { sha1, fileName, organization } = attachment
  // console.log('Attachment.sha1: ' + sha1)
  let organizationPath = organization ? `/${organization}` : ''
  // const canReconcile = false
  if (sha1 && fileName) {
    return (
      <div className='btn-group mr-2 mt-2' role='group' aria-label='Third group'>
        <a href={`/downloads/attachments/${author}/${moduleTitle}${organizationPath}/${sha1}`} type='button' className='btn btn-primary' title='Download file.'><i className='icon icon-download mr-2' />{fileName}</a>
        {/* {canReconcile && <button href='#' type='button' className='btn btn-primary' title='Add reference.'><i className='icon icon-magnifying-glass' /></button>} */}
        {editing && <AsyncRemoveAttachment moduleTitle={moduleTitle} author={author} sha1={sha1} dispatch={dispatch} />}
      </div>
    )
  } else {
    return null
  }
}

export default Attachment
