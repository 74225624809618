import React from 'react'
import References from '../components/References'
import AsyncVideo from '../components/AsyncVideo'
import EditModule from './EditModule'
import ModuleHeader from '../components/ModuleHeader'
import Attachment from '../components/Attachment'
import ReadmeViewer from '../components/ReadmeViewer'
import License from '../components/License'
import AsyncStar from '../containers/AsyncStar'
import ForkModule from '../components/ForkModule'
import DuplicateModule from '../components/DuplicateModule'
import RequestCollaboration from '../components/RequestCollaboration'
import AsyncAddToModule from '../containers/AsyncAddToModule'
import AsyncShare from '../containers/AsyncShare'
import AsyncPublishModule from '../containers/AsyncPublishModule'
import moment from 'moment'

const Tag = ({
  tag
}) => {
  return (
    <a className='mr-3' href={`/search/tag?term=${tag}`}>{tag}</a>
  )
}

const ModuleViewer = ({
  user,
  module,
  stars,
  history,
  storage,
  dispatch,
  isDesktop,
  search
}) => {
  // console.log('user (ModuleViewer): ' + JSON.stringify(user))
  // console.log('isDesktop: ' + isDesktop)
  const isBuiltin = ['home', 'stars', 'modules', 'recommendations', 'uploads'].indexOf(module.title) > -1
  const isUploads = module.title === 'uploads'
  const modifiedModules = history.modules.modified
  const isFork = !!module.parentUuid
  const metrics = module.metrics
  const videos = module.visible
    .filter(potentialVideo => {
      if (isBuiltin) return false
      const {url} = potentialVideo.metadata
      return url && (url.toLowerCase().includes('youtube') || url.toLowerCase().includes('vimeo') || url.toLowerCase().includes('youtu.be'))
    })
  const userCanEdit = module.author === user.username
  const forkIsModified = isFork ? module.forkedAtVersionUuid !== module.versionUUIDs[0] && module.contributedAtVersionUuid !== module.versionUUIDs[0] : false
  const everPublished = module.versionUUIDs && module.versionUUIDs.length > 0
  let moduleIsEditable = (user.username === module.author) && !!module.uuid && !module.filter
  let userLoggedIn = !!user.username

  const reference = (
    <div>
      <div><a href={`/${module.author}/${module.title}`} style={{color: 'black'}}>
        <i className='icon icon-documents' />{module.canonicalTitle || module.title}
      </a></div>
      {module.description}
    </div>
  )

  const Videos = ({videos}) => {
    if (videos.length > 0) {
      return (
        <div>
          {videos.map(
            (component, index, array) => {
              return (<AsyncVideo key={component.uuid} dispatch={dispatch} modifiedModules={modifiedModules} stars={stars} metadata={component.metadata} userLoggedIn={userLoggedIn} uuid={component.uuid} author={module.author} moduleTitle={module.title} isDesktop={isDesktop} />)
            }
          )}
        </div>
      )
    } else {
      return null
    }
  }

  const Attachments = ({attachments}) => {
    if (attachments && attachments.length > 0) {
      let label = module.title === 'uploads' ? 'UNMATCHED FILES' : 'ATTACHMENTS'
      return (
        <div className='media-list'>
          <li className='media list-group-item ls-heavy'>{label}
            <div className='btn-toolbar' role='toolbar' aria-label='Attachments'>
              {module.attachments.map((attachment, index, array) => {
                return <Attachment attachment={attachment} author={module.author} moduleTitle={module.title} key={index} dispatch={dispatch} isUploads={isUploads} search={search} />
              })}
            </div>
          </li>
        </div>
      )
    } else {
      return null
    }
  }

  const moduleClass = 'col-sm-9'

  return (
    <div>
      <div className='row'>
        <div className={moduleClass}>
          <div className='media-list'>
            <li className='media list-group-item'>
              <ModuleHeader dispatch={dispatch}
                stars={stars} moduleIsEditable={moduleIsEditable} userLoggedIn={userLoggedIn} userCanEdit={userCanEdit}
                module={module}
                user={user}
                modifiedModules={modifiedModules}
                isDesktop={isDesktop} />
              {module.readme && <ReadmeViewer />}
            </li>
          </div>
          <Attachments attachments={module.attachments} />
          <Videos videos={videos} />
          <div className='media-list'>
            <References module={module} label='REFERENCES' dispatch={dispatch} stars={stars} storage={storage} search={search} isDesktop={isDesktop} userLoggedIn={userLoggedIn} modifiedModules={modifiedModules} />
          </div>
        </div>
        <div className='col-sm-3 d-print-none'>
          <div className='ls-module-sidebar'>
            {userLoggedIn && userCanEdit && <div className='mb-3'>
              <EditModule dispatch={dispatch} isDesktop={isDesktop} />
            </div>}
            {userLoggedIn && userCanEdit && module.unpublished && <div className='card'><div className='card-body ls-module-controls'>
              <h4>Publish</h4>
              {module.versionUUIDs.length === 0 && <p>
                <strong><i className='icon icon-new' /> This module will not be public until you publish it.</strong> Go ahead and edit in private, then click the link below to publish your module.
                Each time you publish this module a new version will be saved.
              </p>}
              {module.versionUUIDs.length > 0 && <div>
                <p><strong><i className='icon icon-new' /> You have unpublished changes.</strong></p>
                <p>Any edits you make will remain private until you publish a new version of this module.</p>
              </div>}
              <AsyncPublishModule dispatch={dispatch} moduleUUID={module.uuid} isDesktop={isDesktop} />
              {everPublished && <div>
                <a href={`/${module.author}/${module.title}/diff`}><i className='icon icon-eye' />See latest changes</a>
                <br />
                <a href={`/${module.author}/${module.title}/history`}><i className='icon icon-back-in-time' />Review history</a></div>}
            </div></div>}
            {((userLoggedIn && everPublished) || (userLoggedIn && userCanEdit)) && <div className='card'><div className='card-body ls-module-controls'>
              <h4>Organize</h4>
              <AsyncStar dispatch={dispatch} stars={stars} refUUID={module.uuid} bsStyle='default' isDesktop={isDesktop} />
              <br />
              <AsyncAddToModule dispatch={dispatch} modifiedModules={modifiedModules} refUUID={module.uuid} reference={reference} isDesktop={isDesktop} />
              <br />
              <AsyncShare dispatch={dispatch} refUUID={module.uuid} reference={reference} bsStyle='default' isDesktop={isDesktop} />
            </div></div>}
            {module.tags.length > 0 && <div className='card'>
              <div className='card-body ls-module-controls'>
                <h4>Tags</h4>
                {module.tags.map((tag, index) => {
                  return (
                    <Tag key={index} tag={tag} />
                  )
                })}
              </div>
            </div>}
            {/* {module.episode && <div className='card'><div className='card-body ls-module-controls'>
              <h4>Podcast</h4>
              <Episode dispatch={dispatch} modifiedModules={modifiedModules}
                stars={stars} author={module.author} moduleTitle={module.title}
                episodeUrl={`${cloudfrontUrl}/${module.episode.objectKey}.mp3`} userLoggedIn={userLoggedIn}
                podcastLink={!isFork ? `${cloudfrontUrl}/feeds/${module.author}/podcast.xml` : ''} />
            </div></div>} */}
            {userLoggedIn && userCanEdit && module.contributions && module.contributions.pending && <div className='card'><div className='card-body ls-module-controls'>
              <h4>Collaborate</h4>
              <strong>
                There are <a href={`/${module.author}/${module.title}/collaboration`}>{module.contributions.pending} {module.contributions.pending > 1 ? 'contributions' : 'contribution'}</a> awaiting review.
              </strong>
            </div></div>}
            {userLoggedIn && !userCanEdit && <div className='card'><div className='card-body ls-module-controls'>
              <h4>Edit</h4>
              <p>
                You can't edit this module directly, but you can create a duplicate of it in your own workspace.
                Your fork is yours to maintain independently or to use as the basis for contributions back to this module.
                If your contribution is approved you will be listed as a contributor to this module, and your changes will be visible in the version history.
              </p>
              <ForkModule author={module.author} canonicalTitle={module.canonicalTitle} username={user.username} isDesktop={isDesktop} />
            </div></div>}
            {userLoggedIn && userCanEdit && <div className='card'><div className='card-body ls-module-controls'>
              <h4>Duplicate</h4>
              <p>
                If you want to use this module as the basis for new content while keeping this module as is, consider creating a duplicate.
              </p>
              <DuplicateModule author={module.author} canonicalTitle={module.canonicalTitle} isDesktop={isDesktop} />
            </div></div>}
            {userLoggedIn && userCanEdit && !module.unpublished && isFork && forkIsModified && <div className='card'><div className='card-body ls-module-controls'>
              <h4>Collaborate</h4>
              <p>
                Now that you have made some changes to this fork, consider contributing them back to the original module.
                You will be listed as a contributor in the original module, and your contribution will appear in the history of the module.
              </p>
              <RequestCollaboration author={module.author} title={module.title} isDesktop={isDesktop} />
            </div></div>}
            {/* {userLoggedIn && userCanEdit && user.podcastEnabled && !module.episode && <div className='card'><div className='card-body ls-module-controls'>
              <h4>Podcast</h4>
              <p>Upload an mp3 file to convert this module to a podcast episode. The module text will be used as show notes, and the episode url will point to this page.</p>
              <AsyncUploadEpisode dispatch={dispatch} bsStyle='default' module={module} user={user} />
            </div></div>} */}

            {metrics && <div className='card'>
              <div className='card-body ls-module-controls'>
                <h4>Stats</h4>
                <div><a href={`/${module.author}`}>{module.author}</a></div>
                <div><i className='icon icon-star' /> {metrics.starCount} stars</div>
                {metrics.reach > 0 && <div><i className='icon icon-bar-graph' /> {metrics.reach} users reached</div>}
                {metrics.impact > 0 && <div><i className='icon icon-line-graph' /> {metrics.impact} impact</div>}
                {metrics.contributorCount > 0 && <div><i className='icon icon-user' /> {metrics.contributorCount} contributors</div>}
                {everPublished && <div>Published {moment(module.pubDate).format('ll')}</div>}
                {!everPublished && <div>Modified {moment(module.pubDate).format('ll')}</div>}
                {module.versionUUIDs.length > 0 && <div>Latest of <a href={`/${module.author}/${module.title}/history`}>{module.versionUUIDs.length} {module.versionUUIDs.length > 1 ? 'releases' : 'release'}</a></div>}
                {/* <div>{module.views && module.views.month} views in the last month</div> */}
                {/* <div>{module.contributions && module.contributions.pending} contributions pending</div> */}
                {/* <div>{module.contributors && module.contributors.length} contributor(s)</div> */}
              </div>
            </div>}
            {everPublished && <div className='card'>
              <div className='card-body ls-module-controls'>
                <h4>License</h4>
                <License type='by' author={module.author} />
                {everPublished && <p>
                  Cite as: {module.authorProfile.fullName ? `${module.authorProfile.fullName}. ` : `${module.authorProfile.username}. `} "{module.canonicalTitle}{[',', '.', '!', '?', ';', ':'].indexOf(module.canonicalTitle.substr(-1)) === -1 ? '.' : ''}" <em>Litsignal</em>. Atanemi, {moment(module.pubDate).format('ll')}. Web. {moment().format('ll')}.
                </p>}
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModuleViewer
