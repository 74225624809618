import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DissociateLibraryItem from './DissociateLibraryItem'
import DeleteLibraryItem from './DeleteLibraryItem'
import {getEncodedFilename} from '../helpers'

class ReferenceLinks extends Component {
  render () {
    const { refUUID, privatePDF, pmid, pmcid, doi, isbn13, fulltext, title } = this.props

    let privatePDFComp = privatePDF ? <a tabIndex='-1' role='button' href={`/downloads/library/${refUUID}/${getEncodedFilename(title + '.pdf')}`} className='storageapi' key='privatePDF' title='Private PDF attachment'>Download the PDF version of this article from your Litsignal library.</a> : null
    let privatePDFDissociate = privatePDF ? <DissociateLibraryItem componentUUID={refUUID} /> : null
    let privatePDFDelete = privatePDF ? <DeleteLibraryItem componentUUID={refUUID} /> : null
    let pubmedComp = pmid ? <a tabIndex='-1' role='button' target='_blank' href={`http://www.ncbi.nlm.nih.gov/pubmed/${pmid}`} key='pubmed' title='View the abstract on PubMed'>Read the abstract on PubMed.</a> : null
    let pubmedCentralComp = pmcid ? <a tabIndex='-1' role='button' target='_blank' href={`http://www.ncbi.nlm.nih.gov/pmc/articles/${pmcid}`} key='pubmedCentral' title='Read the full text version at PubMed Central'>Read the free full text version at PubMed Central.</a> : null
    let doiComp = doi ? <a tabIndex='-1' role='button' target='_blank' itemProp='url' href={`http://doi.org/${doi}`} key='doi' title='Visit the publisher web page using the digital object identifier'>Visit the article's page on the publisher website via the digital object identifier (DOI).</a> : null
    let buyBookComp = isbn13 ? <a tabIndex='-1' role='button' target='_blank' href={`http://www.directtextbook.com/isbn/${isbn13}`} key='buyBook' title='Compare prices on Direct Textbook'>Compare prices on DirectTextbook.com.</a> : null
    let fulltextComp = fulltext ? <a tabIndex='-1' role='button' targe='_blank' href={fulltext} key='fulltext' title='Full text'>Full text</a> : null

    let links = [privatePDFComp, privatePDFDissociate, privatePDFDelete, pubmedCentralComp, pubmedComp, doiComp, buyBookComp, fulltextComp].filter(component => component !== null)
    if (links.length > 0) {
      return (
        <div>
          {links.map((link, index) => {
            return (
              <div key={index}>
                {link}
              </div>
            )
          })}
        </div>
      )
    } else {
      return null
    }
  }
}

ReferenceLinks.propTypes = {
  privatePDF: PropTypes.bool,
  doi: PropTypes.string,
  pmcid: PropTypes.string,
  pmid: PropTypes.number,
  refUUID: PropTypes.string,
  isbn13: PropTypes.number,
  fulltext: PropTypes.string,
  // url: PropTypes.string.isRequired,
  // onChange: PropTypes.func.isRequired,
  // onShareLink: PropTypes.func.isRequired,
  reference: PropTypes.object,
  title: PropTypes.string
}

export default ReferenceLinks
