import {
  MODULE_VISITED, MODULE_MODIFIED, REFERENCE_TOUCHED, CREATE_MODULE_SUCCESS, REINITIALIZE_STORE
} from '../constants'
import {slugify} from '../helpers'

export const STATE_HISTORY = {
  modules: {
    visited: [],
    modified: []
  },
  components: []
}

function history (state = STATE_HISTORY, action) {
  switch (action.type) {
    case REINITIALIZE_STORE: {
      return action.store.history
    }
    case MODULE_VISITED: {
      const stateBefore = new Set(state.visited).delete(`${action.author}/${action.title}`)
      return Object.assign({}, state, {
        modules: Object.assign({}, state.modules, {
          visited: [action.title, ...stateBefore]
        })
      })
    }
    case MODULE_MODIFIED: {
      const titleIndex = state.modules.modified.map(canonicalTitle => slugify(canonicalTitle)).indexOf(action.title)
      const lastModified = state.modules.modified.slice()[titleIndex]
      const otherModules = state.modules.modified.slice(0, titleIndex).concat(state.modules.modified.slice(titleIndex + 1))
      const modules = {...state.modules, modified: [lastModified, ...otherModules]}
      return {...state, modules}
    }
    case CREATE_MODULE_SUCCESS: {
      // console.log('action.module.metadata' + JSON.stringify(action.module.metadata))
      const newModule = action.module.metadata.canonicalTitle
      const modules = {...state.modules, modified: [newModule, ...state.modules.modified]}
      return {...state, modules}
    }
    case REFERENCE_TOUCHED: {
      return Object.assign({}, state, {
        [action]: [action.componentUUID, ...state.articles]
      })
    }
    default:
      return state
  }
}

export default history
