import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Recommendation from './Recommendation'
import ReferenceToolbar from './ReferenceToolbar'

class Module extends Component {
  constructor (props) {
    super(props)
    this.state = {
      expanded: false
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (e) {
    // if (e.target.nodeName === 'A') {
    //   return
    // }
    // this.setState({
    //   expanded: !this.state.expanded
    // })
  }
  render () {
    // console.log('module: ' + JSON.stringify(module))
    const { userLoggedIn, modifiedModules, dispatch, stars, uuid, index, length, module, isDesktop } = this.props
    const { canonicalTitle, title, author, description, recommendation } = module.metadata
    const descriptionRow = description ? <div>{description}</div> : null
    const reference = (
      <div>
        <div className='ls-reference-title'>
          <a href={`/${author}/${title}`}>
            <i className='icon icon-documents' />{canonicalTitle || title}
          </a>
        </div>
        <div>
          <div><a href={`/${author}`} >
            {author}
          </a></div>
          {descriptionRow}
        </div>
      </div>
    )

    let header
    if (recommendation) {
      header = <Recommendation dispatch={dispatch} recommendation={recommendation} />
    }
    const referenceClass = this.state.expanded ? 'media list-group-item ls-reference ls-reference-expanded' : 'media list-group-item ls-reference'
    // const indicatorClass = this.state.expanded ? 'icon icon-chevron-up' : 'icon icon-chevron-down'

    return (
      <li onClick={this.handleClick} className={referenceClass}>
        {header}
        {reference}
        {userLoggedIn && <ReferenceToolbar stars={stars} reference={reference} modifiedModules={modifiedModules} isDesktop={isDesktop} dispatch={dispatch} uuid={uuid} index={index} length={length} userLoggedIn={userLoggedIn} />}
        {/* <div className='ls-reference-indicator'><a tabIndex='-1' role='button' disabled><i className={indicatorClass} /></a></div> */}
      </li>
    )
  }
}

Module.propTypes = {
  dispatch: PropTypes.func.isRequired,
  modifiedModules: PropTypes.arrayOf(PropTypes.string).isRequired,
  stars: PropTypes.object.isRequired,
  uuid: PropTypes.string.isRequired,
  module: PropTypes.shape({
    canonicalTitle: PropTypes.string,
    title: PropTypes.string,
    author: PropTypes.string,
    organization: PropTypes.string
  }).isRequired,
  index: PropTypes.number,
  length: PropTypes.number,
  userLoggedIn: PropTypes.bool
}

export default Module
