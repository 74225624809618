import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ModalCard from '../components/ModalCard'
import {getCSRF, fetchOptions, slugify} from '../helpers'
import fetch from 'isomorphic-fetch'
import {API_URL, GET} from '../constants'
import { debounce } from 'lodash'

class DuplicateModule extends Component {
  constructor (props) {
    super(props)
    this.state = {showDuplicateModuleModal: false, titleAvailable: false, title: slugify(this.props.canonicalTitle), canonicalTitle: this.props.canonicalTitle}
    this.openDuplicateModule = this.openDuplicateModule.bind(this)
    this.close = this.close.bind(this)
    this.onTitleChange = this.onTitleChange.bind(this)
    this.checkTitleAvailability = debounce(this.checkTitleAvailability.bind(this), 50)
  }

  close (e) {
    e.stopPropagation()
    this.setState(Object.assign({}, this.state, {showDuplicateModuleModal: false}))
  }
  openDuplicateModule () {
    this.setState(Object.assign({}, this.state, {showDuplicateModuleModal: true}))
  }
  checkTitleAvailability (title) {
    if (title === this.state.title) {
      const fetchUrl = `${API_URL}/forks/available/${this.state.title}`
      return fetch(fetchUrl, fetchOptions(GET))
        .then(response => {
          if (response.status === 204 || response.status === 304) {
          // proceed with current title
            return {titleAvailable: true}
          } else if (response.status === 409) {
          // prompt for new title
            return {titleAvailable: false}
          } else {
            return {titleAvailabe: false}
          }
        })
        .then(titleAvailable => this.setState(Object.assign({}, this.state, titleAvailable)))
    }
  }

  onTitleChange (evt) {
    const canonicalTitle = evt.target.value
    const title = slugify(canonicalTitle)
    this.setState(Object.assign({}, this.state, {canonicalTitle, title}))
    // console.log(JSON.stringify(this.state))
    this.checkTitleAvailability(title)
  }

  render () {
    const { author, canonicalTitle, isDesktop } = this.props
    const originalTitle = slugify(canonicalTitle)
    const {titleAvailable, title} = this.state
    // const isFirstModule = moduleOptions.length === 0
    const titleFeedbackClass = titleAvailable ? 'valid-feedback' : 'invalid-feedback'
    const TitleFeedback = title ? !titleAvailable ? `${title} is not available` : `${title} is available!` : ''
    const titleValidationClass = title ? titleAvailable ? 'form-control is-valid' : 'form-control is-invalid' : 'form-control'
    return (
      <a tabIndex='-1' role='button' title='Create a duplicate of this module' onClick={this.openDuplicateModule}>
        <i className='icon icon-copy' />{isDesktop ? 'Duplicate' : ''}
        {this.state.showDuplicateModuleModal && <ModalCard onHide={this.close}>
          <div className='card-body'>
            <h4><i className='icon icon-copy' />Duplicate</h4>
            <form method='post' action={`/${author}/${originalTitle}/duplicates`} >
              <fieldset>
                <p>Duplicating a module creates a copy of the module that you can modify and use as the basis for new content. Please verify that you would like to continue with duplication.</p>
                <input type='hidden' name='_csrf' value={getCSRF()} />
                <div className='form-group' >
                  <input
                    className={titleValidationClass}
                    required
                    name='canonicalTitle'
                    type='text'
                    placeholder='title'
                    onChange={this.onTitleChange}
                    value={this.state.canonicalTitle}
                    autoFocus />
                  <div className={titleFeedbackClass}>
                    {TitleFeedback}
                  </div>
                </div>
                <button className='btn btn-primary' disabled={!titleAvailable} type='submit'>
                Create duplicate
                </button>
              </fieldset>
            </form>
          </div>
        </ModalCard>}
      </a>
    )
  }
}

DuplicateModule.propTypes = {
  isDesktop: PropTypes.bool,
  author: PropTypes.string.isRequired,
  canonicalTitle: PropTypes.string.isRequired
}

export default DuplicateModule
