import {
  STATE_USER,
  REGISTER_REQUEST, REGISTER_SUCCESS,
  LOGIN_REQUEST, LOGIN_SUCCESS,
  FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS,
  REINITIALIZE_STORE
} from '../constants'

function user (state = STATE_USER, action) {
  switch (action.type) {
    case REINITIALIZE_STORE: {
      return action.store.user
    }
    case REGISTER_SUCCESS: {
      const {user} = action
      return Object.assign({}, state, {
        username: user.username,
        isAuthenticated: true
      })
    }
    case LOGIN_SUCCESS: {
      const {user} = action
      return Object.assign({}, user, {
        isAuthenticated: true
      })
    }
    case FORGOT_PASSWORD_SUCCESS:
      return Object.assign({}, STATE_USER, {
        auth: {
          message: action.message
        }
      })
    case FORGOT_PASSWORD_REQUEST:
    case REGISTER_REQUEST:
    case LOGIN_REQUEST:
    default:
      return state
  }
}

export default user
