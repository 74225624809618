import React, { Component } from 'react'
import LargeModalCard from '../components/LargeModalCard'
import marked from 'marked'
import { MARKDOWN_GUIDE } from '../constants'

marked.setOptions({
  renderer: new marked.Renderer(),
  gfm: true,
  tables: true,
  breaks: false,
  pedantic: false,
  sanitize: false,
  smartLists: true,
  smartypants: false
})

class AddToModule extends Component {
  constructor (props) {
    super(props)
    this.state = {showHelpModal: false}
    this.openHelpModal = this.openHelpModal.bind(this)
    this.close = this.close.bind(this)
  }

  close (e) {
    e.stopPropagation()
    this.setState({ showHelpModal: false })
  }
  openHelpModal () {
    this.setState({ showHelpModal: true })
  }

  render () {
    // const isFirstModule = moduleOptions.length === 0
    return (
      <a tabIndex='-1' role='button' title='Text formatting tips among other things.' onClick={this.openHelpModal} className='nav-link'>
        <i className='icon icon-help mr-2' />Help
        {this.state.showHelpModal && <LargeModalCard onHide={this.close}>
          <div className='card-body'>
            <div dangerouslySetInnerHTML={{__html: marked(MARKDOWN_GUIDE)}} />
          </div>
        </LargeModalCard>}
      </a>
    )
  }
}

export default AddToModule
