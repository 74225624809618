const RefineSearchForm = ({
  term,
  webEnv,
  queryKey,
  history,
  onInputChange
}) => {
  const formUrl = `/search/pubmed`
  return (
    <form className='form-inline w-100' method='get' action={formUrl}>
      <div className='form-group my-1 col px-0' >
        <div className='input-group my-0 col px-0'>
          <input type='hidden' name='webEnv' value={webEnv} />
          <input type='hidden' name='queryKey' value={queryKey} />
          <input type='hidden' name='history' value={history} />
          <input
            id='term'
            className='form-control'
            name='term'
            placeholder='Submit another term to refine these results'
            onChange={onInputChange}
            value={term}
            autoFocus />
          <div className='input-group-append'>
            <button className='btn btn-primary' type='submit'><i className='icon icon-magnifying-glass mr-2' />Refine</button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default RefineSearchForm
