import React from 'react'
import Article from './Article'
import Module from './Module'
import Link from './Link'
import Book from './Book'
import User from './User'
import Filter from '../containers/Filter'
import ModulePaginator from './ModulePaginator'

const References = ({
  dispatch,
  label,
  module,
  stars,
  search,
  storage,
  isDesktop,
  userLoggedIn,
  modifiedModules,
  noFilter
}) => {
  const {components, editing, rearranging, title} = module

  const visible = module.visible || components
    .filter(potentialVideo => {
      const {url} = potentialVideo.metadata
      return module.editing ? module.editing : !(url && (url.toLowerCase().includes('youtube') || url.toLowerCase().includes('vimeo') || url.toLowerCase().includes('youtu.be')))
    })
  const limit = module.editing ? visible.length : 20
  const page = module.page || 1
  const visibleItems = visible.slice(page * limit - limit, page * limit)
  const isUploads = title === 'uploads'
  const moduleLength = visible.length
  const pages = (moduleLength - moduleLength % limit) / limit + (moduleLength % limit > 0 ? 1 : 0)

  let filterComponent
  if (!editing && (components && components.length > 10)) {
    filterComponent = (
      <li className='media list-group-item'><Filter /></li>
    )
  }
  if (components.length > 0) {
    return (
      <div>
        {label && <li className='media list-group-item ls-heavy'>
          {label}
        </li>}
        {filterComponent}
        {visibleItems.map(
          (component, index, array) => {
            switch (component.type) {
              case 'user': {
                return (
                  <User
                    dispatch={dispatch} stars={stars} user={component} key={component.uuid} index={index}
                    userLoggedIn={userLoggedIn} isDesktop={isDesktop}
                  />
                )
              }
              case 'module': {
                return (
                  <Module
                    dispatch={dispatch} stars={stars} module={component} key={component.uuid} index={index} length={components.length}
                    userLoggedIn={userLoggedIn} editing={editing} uuid={component.uuid}
                    rearranging={rearranging}
                    modifiedModules={modifiedModules} isDesktop={isDesktop}
                  />
                )
              }
              case 'link': {
                return (
                  <Link
                    dispatch={dispatch} uuid={component.uuid} link={component.metadata} key={component.uuid} index={index} length={components.length}
                    editing={editing} userLoggedIn={userLoggedIn}
                    stars={stars}
                    rearranging={rearranging}
                    modifiedModules={modifiedModules} isDesktop={isDesktop}
                  />
                )
              }
              case 'article': {
                return (
                  <Article
                    dispatch={dispatch} uuid={component.uuid} article={component.metadata} key={component.uuid} index={index} length={components.length}
                    editing={editing} userLoggedIn={userLoggedIn}
                    stars={stars}
                    storage={storage}
                    rearranging={rearranging}
                    modifiedModules={modifiedModules} isDesktop={isDesktop} isUploads={isUploads}
                    search={isUploads ? search : null}
                  />
                )
              }
              case 'book': {
                return (
                  <Book
                    dispatch={dispatch} uuid={component.uuid} book={component.metadata} key={component.uuid} index={index} length={components.length}
                    editing={editing} userLoggedIn={userLoggedIn}
                    stars={stars}
                    rearranging={rearranging}
                    modifiedModules={modifiedModules} isDesktop={isDesktop}
                  />
                )
              }
            }
          }
        )}
        <ModulePaginator pages={pages} editing={module.editing} />
      </div>
    )
  } else {
    return null
  }
}

export default References
