import React from 'react'
import { viewModule } from '../actions'

const ViewModule = ({
  isSaved,
  dispatch
}) => {
  const handleClick = () => {
    dispatch(viewModule())
  }
  if (isSaved) {
    return (
      <a className='nav-item nav-link' role='button' tabIndex='-1' onClick={handleClick} >
        <i className='icon icon-squared-cross mr-2' />Close
      </a>
    )
  } else {
    return (
      <span className='nav-item nav-text mr-2 my-auto text-muted' >
        <i className='icon icon-squared-cross mr-2' />Close
      </span>
    )
  }
}

export default ViewModule
