import React, {Component} from 'react'
import References from '../components/References'

class LitsignalSearchResults extends Component {
  render () {
    const { user, search, stars, history, storage, dispatch, client } = this.props
    let module = search
    module.rearranging = false
    const isDesktop = client.type.localeCompare('desktop') === 0 || client.type.localeCompare('tablet') === 0
    const modifiedModules = history.modules.modified
    let userLoggedIn = !!user.username

    let resultsLabel = null
    if (search.submittedTerm && search.components.length > 0) {
      resultsLabel = `${search.components.length} results for the term: ${search.submittedTerm}`
    } else if (search.submittedTerm) {
      resultsLabel = `No results for the term: ${search.submittedTerm}`
    }

    return (
      <div className='media-list'>
        <li className='media list-group-item'>
          {resultsLabel}
        </li>
        <References module={module} noFilter dispatch={dispatch} stars={stars} storage={storage} search={search} isDesktop={isDesktop} userLoggedIn={userLoggedIn} modifiedModules={modifiedModules} />
      </div>
    )
  }
}

export default LitsignalSearchResults
