import React, {Component} from 'react'
import { connect } from 'react-redux'
import { updateFilter, filterModule, selectPage } from '../actions'
import { debounce } from 'lodash'

class FilterForm extends Component {
  constructor (props) {
    super(props)
    this.dispatchFilter = debounce(this.dispatchFilter.bind(this), 300)
    this.handleChange = this.handleChange.bind(this)
  }
  dispatchFilter () {
    this.props.dispatch(filterModule())
    this.props.dispatch(selectPage(1))
  }
  handleChange (evt) {
    this.props.dispatch(updateFilter(evt.target.value))
    this.dispatchFilter()
  }
  render () {
    return (
      <input type='text'
        placeholder='Start typing to filter...'
        id='filterText'
        className='form-control'
        value={this.props.filterText}
        onChange={this.handleChange} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    filterText: state.module.filter
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch
  }
}

const Filter = connect(mapStateToProps, mapDispatchToProps)(FilterForm)

export default Filter
