import React from 'react'
import PropTypes from 'prop-types'
import { removeTag } from '../actions'
import RemoveTag from '../components/RemoveTag'

const AsyncRemoveTag = ({
  dispatch,
  componentUUID,
  tag
}) => {
  const onRemove = () => {
    dispatch(removeTag(tag, componentUUID))
  }

  return (
    <RemoveTag
      onRemove={onRemove}
      tag={tag}
    />
  )
}

AsyncRemoveTag.propTypes = {
  dispatch: PropTypes.func.isRequired,
  componentUUID: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired
}

export default AsyncRemoveTag
