import React, { Component } from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import { addToModule } from '../actions'
import AddToModule from '../components/AddToModule'
import { slugify } from '../helpers'

class AddToModuleComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {moduleSelect: this.props.modifiedModules[0] || '', default: true}
    this.onAddToModule = this.onAddToModule.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    // console.log(`moduleOptions: ${this.props.moduleOptions}`)
  }
  componentWillReceiveProps (nextProps) {
    // console.log(`default: ${this.state.default.toString()}
    // nextProps.modifiedModules[0]: ${nextProps.modifiedModules[0]}
    // moduleSelect: ${this.state.moduleSelect}`)
    if (this.state.default) {
      this.setState({default: true, moduleSelect: nextProps.modifiedModules[0]})
    }
  }
  onAddToModule (e) {
    e.preventDefault()
    const { dispatch, refUUID } = this.props
    if (this.state.moduleSelect) {
      dispatch(addToModule(slugify(this.state.moduleSelect), refUUID))
    }
    this.setState({...this.state, default: true})
  }
  handleSelect (value) {
    this.setState({ moduleSelect: value, default: false })
  }
  render () {
    const { reference, modifiedModules, isDesktop } = this.props
    return (
      <AddToModule
        reference={reference}
        moduleSelect={this.state.moduleSelect}
        onSelect={this.handleSelect}
        onAddToModule={this.onAddToModule}
        moduleOptions={[...modifiedModules].sort(function (a, b) { return a.localeCompare(b, 'en', {'sensitivity': 'base'}) })}
        isDesktop={isDesktop}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    modifiedModules: state.history.modules.modified
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch
  }
}

const AsyncAddToModule = connect(mapStateToProps, mapDispatchToProps)(AddToModuleComponent)

AddToModuleComponent.propTypes = {
  reference: PropTypes.object.isRequired,
  refUUID: PropTypes.string.isRequired,
  modifiedModules: PropTypes.array.isRequired
}

export default AsyncAddToModule
