import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Recommendation from './Recommendation'
import LinkInfo from './LinkInfo'
import ReferenceToolbar from './ReferenceToolbar'

class Link extends Component {
  constructor (props) {
    super(props)
    this.state = {
      expanded: false
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (e) {
    // if (e.target.nodeName === 'A') {
    //   return
    // }
    // this.setState({
    //   expanded: !this.state.expanded
    // })
  }
  render () {
    const { dispatch, modifiedModules, stars, rearranging, uuid, link, moduleTitle, index, length, editing, userLoggedIn, isDesktop } = this.props
    const { url, title, recommendation } = link
    const linkInfo = <LinkInfo title={title} url={url} active />
    const reference = <LinkInfo title={title} url={url} />

    let header
    if (recommendation) {
      header = <Recommendation dispatch={dispatch} recommendation={recommendation} />
    }
    const referenceClass = this.state.expanded ? 'media list-group-item ls-reference ls-reference-expanded' : 'media list-group-item ls-reference'
    // const indicatorClass = this.state.expanded ? 'icon icon-chevron-up' : 'icon icon-chevron-down'

    return (
      <li onClick={this.handleClick} className={referenceClass}>
        {header}
        {linkInfo}
        {userLoggedIn && <ReferenceToolbar stars={stars} reference={reference} editing={editing} modifiedModules={modifiedModules} isDesktop={isDesktop} dispatch={dispatch} rearranging={rearranging} moduleTitle={moduleTitle} uuid={uuid} index={index} length={length} userLoggedIn={userLoggedIn} />}
        {/* {review} */}
        {/* <div className='ls-reference-indicator'><a tabIndex='-1' role='button' disabled><i className={indicatorClass} /></a></div> */}
      </li>
    )
  }
}

Link.propTypes = {
  dispatch: PropTypes.func.isRequired,
  modifiedModules: PropTypes.arrayOf(PropTypes.string).isRequired,
  stars: PropTypes.object.isRequired,
  rearranging: PropTypes.bool.isRequired,
  uuid: PropTypes.string.isRequired,
  link: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    type: PropTypes.string,
    fullText: PropTypes.bool,
    approved: PropTypes.bool
  }).isRequired,
  moduleTitle: PropTypes.string,
  index: PropTypes.number,
  editing: PropTypes.bool,
  length: PropTypes.number,
  userLoggedIn: PropTypes.bool
}

export default Link
