import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ReferenceLinks from './ReferenceLinks'
import AsyncUploadLinks from '../containers/AsyncUploadLinks'
import ReferenceToolbar from './ReferenceToolbar'
import Recommendation from './Recommendation'
import { find } from 'lodash'
import ArticleInfo from './ArticleInfo'

class Article extends Component {
  constructor (props) {
    super(props)
    this.state = {
      expanded: false
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (e) {
    if (e.target.nodeName === 'A') {
      return
    }
    this.setState({
      expanded: !this.state.expanded
    })
  }

  render () {
    const {dispatch, article, storage, modifiedModules, rearranging, editing, moduleTitle, index, length, userLoggedIn, uuid, isDesktop, isUploads, stars} = this.props
    const { recommendation } = article
    // if (typeof pmid === 'string') console.log('has string for pmid: ' + article.title)
    let inLibrary = !!find(storage.library, {componentUUID: uuid})
    const reference = (
      <ArticleInfo
        inLibrary={inLibrary}
        article={Object.assign({}, article, {uuid})}
      />
    )
    // console.log('article: ' + JSON.stringify(article))

    let header
    if (recommendation) {
      header = <Recommendation dispatch={dispatch} recommendation={recommendation} />
    }
    const referenceClass = this.state.expanded ? 'media list-group-item ls-reference ls-reference-expanded' : 'media list-group-item ls-reference'
    const indicatorClass = this.state.expanded ? 'icon icon-chevron-up' : 'icon icon-chevron-down'

    return (
      <li onClick={!editing ? this.handleClick : undefined} className={referenceClass} itemScope
        itemType='http://schema.org/MedicalScholarlyArticle'>
        {header}
        {reference}
        {userLoggedIn && <ReferenceToolbar stars={stars} reference={reference} editing={editing} modifiedModules={modifiedModules} isDesktop={isDesktop} dispatch={dispatch} rearranging={rearranging} moduleTitle={moduleTitle} uuid={uuid} index={index} length={length} isUploads={isUploads} userLoggedIn={userLoggedIn} />}
        {this.state.expanded && <div className='ls-reference-expansion d-print-none'>
          <ReferenceLinks reference={reference} privatePDF={inLibrary} pmcid={article.pmcid} doi={article.doi} pmid={article.pmid} refUUID={uuid} title={article.title} modifiedModule={modifiedModules} />
          {this.moduleTitle === 'uploads' && <AsyncUploadLinks refUUID={uuid} />}
        </div>}
        {!editing && <div className='ls-reference-indicator d-print-none'><a tabIndex='-1' role='button' disabled><i className={indicatorClass} /></a></div>}
      </li>
    )
  }
}

Article.propTypes = {
  dispatch: PropTypes.func.isRequired,
  modifiedModules: PropTypes.arrayOf(PropTypes.string).isRequired,
  stars: PropTypes.object.isRequired,
  storage: PropTypes.object.isRequired,
  rearranging: PropTypes.bool,
  uuid: PropTypes.string.isRequired,
  article: PropTypes.shape({
    pmid: PropTypes.number,
    pmcid: PropTypes.string,
    title: PropTypes.string,
    authors: PropTypes.arrayOf(PropTypes.string),
    date: PropTypes.string,
    doi: PropTypes.string,
    journal: PropTypes.shape({
      name: PropTypes.string,
      abbreviation: PropTypes.string,
      issn: PropTypes.string,
      nlmid: PropTypes.string,
      publisher: PropTypes.string
    }),
    volume: PropTypes.string,
    issue: PropTypes.string,
    pages: PropTypes.string,
    stargazers: PropTypes.arrayOf(PropTypes.string),
    approved: PropTypes.bool
  }).isRequired,
  moduleTitle: PropTypes.string,
  index: PropTypes.number,
  length: PropTypes.number,
  editing: PropTypes.bool,
  userLoggedIn: PropTypes.bool,
  search: PropTypes.object
}

export default Article
