import {
  DISMISS_ALERT,
  ADD_TO_MODULE_ERROR, ADD_TO_MODULE_SUCCESS,
  CREATE_MODULE_ERROR, CREATE_MODULE_SUCCESS,
  REMOVE_FROM_MODULE_ERROR, REMOVE_FROM_MODULE_SUCCESS,
  MOVE_REFERENCE_ERROR, MOVE_REFERENCE_SUCCESS,
  SHARE_LINK_ERROR,
  SUGGEST_ERROR, SUGGEST_SUCCESS,
  RECOMMEND_ERROR, RECOMMEND_SUCCESS,
  UPLOAD_ATTACHMENTS_ERROR,
  UPLOAD_LIBRARY_ERROR,
  STORAGE_SYNC_SUCCESS, STORAGE_SYNC_ERROR,
  LOGIN_SUCCESS, LOGIN_ERROR, REGISTER_SUCCESS, REGISTER_ERROR,
  FORGOT_PASSWORD_ERROR, FORGOT_PASSWORD_SUCCESS
} from '../constants'

const STATE_ALERT = {
  visible: false,
  message: '',
  url: null,
  category: null,
  type: null
}

function alert (state = STATE_ALERT, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
    case DISMISS_ALERT: {
      return Object.assign({}, state, STATE_ALERT)
    }
    //
    // API ALERTS
    //
    // Display an error messge regardless of whether one is received from the server,
    case FORGOT_PASSWORD_SUCCESS:
    case STORAGE_SYNC_ERROR:
    case ADD_TO_MODULE_ERROR:
    case CREATE_MODULE_ERROR:
    case REMOVE_FROM_MODULE_ERROR:
    case SUGGEST_ERROR:
    case SHARE_LINK_ERROR:
    case RECOMMEND_ERROR:
    case UPLOAD_ATTACHMENTS_ERROR:
    case UPLOAD_LIBRARY_ERROR:
    case MOVE_REFERENCE_ERROR: {
      return Object.assign({}, state, {
        visible: true,
        message: action.message || 'Oops. Please try again later or file a report.',
        url: action.url || null,
        category: action.category || null,
        type: action.type
      })
    }
    case LOGIN_ERROR:
    case REGISTER_ERROR:
    case FORGOT_PASSWORD_ERROR:
      return Object.assign({}, state, {
        visible: true,
        message: action.message,
        url: null,
        category: 'danger',
        type: action.type
      })
    // Display a success message regardless of whether one is received from the server
    case STORAGE_SYNC_SUCCESS:
    case SUGGEST_SUCCESS:
    case RECOMMEND_SUCCESS:
    case CREATE_MODULE_SUCCESS:
    case ADD_TO_MODULE_SUCCESS: {
      return Object.assign({}, state, {
        visible: true,
        message: action.message || null,
        url: action.url || null,
        category: action.category || 'Success',
        type: action.type
      })
    }
    // Don't display a success message unless one is received from the server
    case REMOVE_FROM_MODULE_SUCCESS:
    case MOVE_REFERENCE_SUCCESS: {
      return Object.assign({}, state, STATE_ALERT)
    }
    default: {
      return STATE_ALERT
    }
  }
}

export default alert
