import { connect } from 'react-redux'
import ModalCard from './ModalCard'

const UploadLibraryProgress = ({uploading}) => {
  if (uploading) {
    const {total, loaded} = uploading
    const percentComplete = Math.floor((loaded / total) * 100)
    if (percentComplete < 100) {
      return (
        <ModalCard >
          <div className='card-body'>
            <div className='progress'>
              <div className='progress-bar' role='progressbar' aria-valuenow={percentComplete} aria-valuemin='0' aria-valuemax='100' style={{'minWidth': '2em', 'width': percentComplete.toString() + '%'}} >
                {percentComplete}%
              </div>
            </div>
          </div>
        </ModalCard>
      )
    } else {
      return (
        <ModalCard >
          <div className='card-body'>
            <div className='progress'>
              <div className='progress-bar progress-bar-striped active' role='progressbar' aria-valuenow='100' aria-valuemin='0' aria-valuemax='100' style={{'width': '100%'}} >
                <strong>Processing...</strong>
              </div>
            </div>
          </div>
        </ModalCard>
      )
    }
  } else {
    return null
  }
}

const mapStateToProps = (state) => {
  return {
    uploading: state.storage.uploading
  }
}

const Filter = connect(mapStateToProps)(UploadLibraryProgress)

export default Filter
