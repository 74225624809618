import React, {Component} from 'react'
import PropTypes from 'prop-types'
// import { removeUpload, reconcileUpload, reconcilePubmed, clearPubmedSearch } from '../actions'
import { reconcileUpload, reconcilePubmed, clearPubmedSearch } from '../actions'
import RemoveFromModule from '../components/RemoveFromModule'
import ReconcileUpload from '../components/ReconcileUpload'
import {connect} from 'react-redux'

const mapStateToProps = (state) => {
  return {
    storage: state.storage
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch
  }
}

class AsyncUploadLinks extends Component {
  constructor (props) {
    super(props)
    this.state = {
      searchTerm: ''
    }
    this.onRemove = this.onRemove.bind(this)
    this.onReconcile = this.onReconcile.bind(this)
    this.onSearch = this.onSearch.bind(this)
    this.onSearchTermChange = this.onSearchTermChange.bind(this)
    this.onClearSearch = this.onClearSearch.bind(this)
  }
  // onRemove () {
  //   this.props.dispatch(removeUpload(this.props.refUUID, this.props.sha1))
  // }
  onClearSearch () {
    this.props.dispatch(clearPubmedSearch())
  }
  onReconcile (componentUUID) {
    this.props.dispatch(reconcileUpload(componentUUID, this.props.sha1, this.props.refUUID))
  }
  onSearch () {
    this.props.dispatch(reconcilePubmed({term: this.state.searchTerm, webEnv: '', queryKey: ''}))
  }
  onSearchTermChange (searchTerm) {
    this.setState(Object.assign({}, this.state, {searchTerm}))
  }
  render () {
    return (
      <div>
        <ReconcileUpload
          onReconcile={this.onReconcile}
          onSearch={this.onSearch}
          onSearchTermChange={this.onSearchTermChange}
          onClearSearch={this.onClearSearch}
          searchTerm={this.state.searchTerm}
          search={this.props.search}
        />
        <RemoveFromModule
          onRemove={this.onRemove}
        />
      </div>
    )
  }
}

AsyncUploadLinks.propTypes = {
  dispatch: PropTypes.func.isRequired,
  refUUID: PropTypes.string,
  sha1: PropTypes.string,
  search: PropTypes.object.isRequired
}

const ConnectAsyncUploadLinks = connect(mapStateToProps, mapDispatchToProps)(AsyncUploadLinks)

export default ConnectAsyncUploadLinks
