import React from 'react'
import EditorNavbar from '../components/EditorNavbar'
import SearchNavbar from '../containers/SearchNavbar'
import DefaultNavbar from '../containers/DefaultNavbar'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
  return {
    user: state.user,
    history: state.history,
    client: state.client,
    storage: state.storage,
    module: state.module,
    search: state.search
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch
  }
}

const NavbarContainer = ({
  user,
  history,
  dispatch, client,
  storage,
  module,
  search
}) => {
  const appLocation = window.location
  // console.log(`appLocation: ${appLocation}`)
  const appUrl = new URL(appLocation)
  // console.log(`appPath: ${appUrl.pathname}`)
  const appPathArray = appUrl.pathname.split('/')
  // console.log(`appPathArray: ${appPathArray}`)
  const appPathFirst = appPathArray[1]
  // console.log(`appPathFirst: ${appPathFirst}`)
  // console.log(`appPathFirst.toLocaleLowerCase === 'search': ${appPathFirst.toLocaleLowerCase() === 'search'}`)
  // console.log('client: ' + JSON.stringify(client))
  if (module.editing) {
    return <EditorNavbar
      user={user}
      dispatch={dispatch}
      history={history}
      storage={storage}
      module={module}
      isDesktop={client.type.localeCompare('desktop') === 0 || client.type.localeCompare('tablet') === 0}
    />
  } else if (appPathFirst.toLocaleLowerCase() === 'search') {
    // console.log('SearchNavbar')
    return <SearchNavbar />
  } else {
    // console.log('DefaultNavbar')
    return <DefaultNavbar />
  }
}

const NavbarView = connect(mapStateToProps, mapDispatchToProps)(NavbarContainer)

export default NavbarView
