import {Component} from 'react'
import Recaptcha from 'react-google-invisible-recaptcha'
import {validator, getRecaptchaSiteKey} from '../helpers'
const sitekey = getRecaptchaSiteKey()

class ForgotPasswordForm extends Component {
  constructor (props) {
    super(props)
    this.onResolved = this.onResolved.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }
  onResolved () {
    // console.log('resolved with: ' + this.recaptcha.getResponse())
    this.props.forgot(this.recaptcha.getResponse())
  }
  onSubmit (evt) {
    evt.preventDefault()
    // document.getElementsByClassName('password-form')[0].classList.add('was-validated')
    // console.log('form submit')
    this.recaptcha.execute()
  }
  render () {
    const {onEmailChange, email, showLogin} = this.props
    const emailIsValid = validator.isEmail(email)
    const emailFeedback = emailIsValid ? '' : 'Please enter a valid email address'
    const emailValidationClass = email ? emailIsValid ? 'form-control is-valid' : 'form-control is-invalid' : 'form-control'
    const emailFeedbackClass = emailIsValid ? 'valid-feedback' : 'invalid-feedback'
    return (
      <form className='password-form needs-validation' noValidate onSubmit={this.onSubmit}>
        <h5 className='card-title'>Forgot password</h5>
        <div className='form-group' >
          <label htmlFor='emailfield'>Email</label>
          <input
            className={emailValidationClass}
            id='emailfield'
            name='email'
            type='text'
            placeholder='email'
            onChange={onEmailChange}
            value={email} />
          <div className={emailFeedbackClass}>
            {emailFeedback}
          </div>
        </div>
        <div className='form-group'>
          <button className='btn btn-primary' type='submit' disabled={!validator.isEmail(email)} >Reset password</button>
        </div>
        <Recaptcha
          ref={ref => { this.recaptcha = ref }}
          sitekey={sitekey}
          onResolved={this.onResolved} />
        <p>
          Know your password? <a tabIndex='-1' role='button' onClick={showLogin} disabled={!emailIsValid}>Sign in here</a>.
        </p>
      </form>
    )
  }
}

export default ForgotPasswordForm
