import React, {Component} from 'react'
import {Portal} from 'react-portal'
import PropTypes from 'prop-types'

class ModalCard extends Component {
  render () {
    const {onHide} = this.props
    return (
      <Portal node={document.getElementById('react-modal')}>
        <div onClick={onHide} className='md-overlay md-show-overlay' />
        <div onClick={e => e.stopPropagation()} className='md-modal md-show'>
          <div className='card' style={{marginBottom: '0rem'}}>
            {this.props.children}
          </div>
        </div>
      </Portal>
    )
  }
}

ModalCard.propTypes = {
  onHide: PropTypes.func
}

export default ModalCard
