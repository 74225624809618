import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { recommend } from '../actions'
import Share from '../components/Share'

class AsyncShare extends Component {
  constructor (props) {
    super(props)
    this.state = { recipient: '', message: '' }
    this.onRecommend = this.onRecommend.bind(this)
    this.handleRecipientChange = this.handleRecipientChange.bind(this)
    this.handleMessageChange = this.handleMessageChange.bind(this)
  }
  onRecommend (e) {
    e.preventDefault()
    const { dispatch, refUUID } = this.props
    dispatch(recommend(this.state.recipient, this.state.message, refUUID))
  }
  handleRecipientChange (value) {
    this.setState(Object.assign({}, this.state, {recipient: value}))
  }
  handleMessageChange (value) {
    this.setState(Object.assign({}, this.state, {message: value}))
  }
  render () {
    const { reference } = this.props
    return (
      <Share
        reference={reference}
        recipient={this.state.recipient}
        message={this.state.message}
        onRecipientChange={this.handleRecipientChange}
        onMessageChange={this.handleMessageChange}
        onRecommend={this.onRecommend}
        bsStyle={this.props.bsStyle}
        isDesktop={this.props.isDesktop}
      />
    )
  }
}

AsyncShare.propTypes = {
  dispatch: PropTypes.func.isRequired,
  refUUID: PropTypes.string.isRequired,
  reference: PropTypes.object
}

export default AsyncShare
