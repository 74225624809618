const License = ({
  type,
  author
}) => {
  const Icon = ({type}) => {
    switch (type) {
      case 'allrightsreserved': {
        return <h2>©</h2>
      }
      case 'by': {
        return (
          <a rel='license' target='_blank' href='http://creativecommons.org/licenses/by/4.0/'>
            <h2><i className='icon icon-creative-commons' /><i className='icon icon-creative-commons-attribution' /></h2>
          </a>
        )
      }
      case 'zero': {
        return (
          <a rel='license' style={{color: 'black'}} target='_blank' href='http://creativecommons.org/publicdomain/zero/1.0/'>
            <h2><i className='icon icon-creative-commons' /><i className='icon icon-creative-commons-public-domain' /></h2>
          </a>
        )
      }
      case 'bysa': {
        return (
          <a rel='license' style={{color: 'black'}} target='_blank' href='http://creativecommons.org/licenses/by-sa/4.0/'>
            <h2><i className='icon icon-creative-commons' /><i className='icon icon-creative-commons-attribution' /><i className='icon icon-creative-commons-sharealike' /></h2>
          </a>
        )
      }
      case 'bynd': {
        return (
          <a rel='license' style={{color: 'black'}} target='_blank' href='http://creativecommons.org/licenses/by-nd/4.0/'>
            <h2><i className='icon icon-creative-commons' /><i className='icon icon-creative-commons-attribution' /><i className='icon icon-creative-commons-noderivs' /></h2>
          </a>
        )
      }
      case 'bync': {
        return (
          <a rel='license' style={{color: 'black'}} target='_blank' href='http://creativecommons.org/licenses/by-nc/4.0/'>
            <h2><i className='icon icon-creative-commons' /><i className='icon icon-creative-commons-attribution' /><i className='icon icon-creative-commons-noncommercial-us' /></h2>
          </a>
        )
      }
      case 'byncsa': {
        return (
          <a rel='license' style={{color: 'black'}} target='_blank' href='http://creativecommons.org/licenses/by-nc-sa/4.0/'>
            <h2><i className='icon icon-creative-commons' /><i className='icon icon-creative-commons-attribution' /><i className='icon icon-creative-commons-noncommercial-us' /><i className='icon icon-creative-commons-sharealike' /></h2>
          </a>
        )
      }
      case 'byncnd': {
        return (
          <a rel='license' style={{color: 'black'}} target='_blank' href='http://creativecommons.org/licenses/by-nc-nd/4.0/'>
            <h2><i className='icon icon-creative-commons' /><i className='icon icon-creative-commons-attribution' /><i className='icon icon-creative-commons-noncommercial-us' /><i className='icon icon-creative-commons-noderivs' /></h2>
          </a>
        )
      }
    }
  }
  const TextLink = ({type}) => {
    switch (type) {
      case 'by': {
        return <a rel='license' target='_blank' href='http://creativecommons.org/licenses/by/4.0/'>Creative Commons Attribution 4.0 International License</a>
      }
      case 'zero': {
        return <a rel='license' target='_blank' href='http://creativecommons.org/publicdomain/zero/1.0/'>CC0 1.0 Universal License</a>
      }
      case 'bysa': {
        return <a rel='license' target='_blank' href='http://creativecommons.org/licenses/by-sa/4.0/'>Creative Commons Attribution-ShareAlike 4.0 International License</a>
      }
      case 'bynd': {
        return <a rel='license' target='_blank' href='http://creativecommons.org/licenses/by-nd/4.0/'>Creative Commons Attribution-NoDerivatives 4.0 International License</a>
      }
      case 'bync': {
        return <a rel='license' target='_blank' href='http://creativecommons.org/licenses/by-nc/4.0/'>Creative Commons Attribution-NonCommercial 4.0 International License</a>
      }
      case 'byncsa': {
        return <a rel='license' target='_blank' href='http://creativecommons.org/licenses/by-nc-sa/4.0/'>Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International License</a>
      }
      case 'byncnd': {
        return <a rel='license' target='_blank' href='http://creativecommons.org/licenses/by-nc-nd/4.0/'>Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International License</a>
      }
    }
  }
  if (type === 'allrightreserved') return (<div><Icon type={type} /><p>{`Copyright ${author}. All rights reserved.`}</p></div>)
  return (
    <div>
      <Icon type={type} />
      <div>
        This work is licensed under a <TextLink type={type} />.
      </div>
    </div>
  )
}

export default License
