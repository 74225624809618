import { connect } from 'react-redux'
import { selectPage } from '../actions'
import PageButtons from './PageButtons'

const mapStateToProps = (state, ownProps) => {
  return {
    pages: ownProps.pages,
    activePage: state.module.page
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleSelect: (event) => {
      dispatch(selectPage(event.target.getAttribute('data-tag')))
    }
  }
}

const PaginationAdvanced = ({
  pages,
  activePage,
  handleSelect,
  editing
}) => {
  activePage = parseInt(activePage, 10)
  pages = parseInt(pages, 10)
  if (pages < 2 || editing) return null
  return (
    <div className='media list-group-item'>
      <ul className='my-0 pagination justify-content-center' ><PageButtons pages={pages} activePage={activePage} handleSelect={handleSelect} /></ul>
    </div>
  )
}

const ModulePaginator = connect(mapStateToProps, mapDispatchToProps)(PaginationAdvanced)

export default ModulePaginator
