import {
  STATE_REF_COLLECTIONS,
  STATE_USER,
  STATE_STORAGE,
  STATE_ALERT,
  STATE_CLIENT,
  STATE_MODULE
} from './constants'
import { STATE_HISTORY } from './reducers/history'

const getExtension = (filename) => {
  return filename.slice().split('.').slice(-1)[0]
}

const getBareFilename = (filename) => {
  return stripFinalPeriods(filename.slice(0, filename.lastIndexOf(getExtension(filename))))
}

const stripFinalPeriods = (str) => {
  const endsWithPeriod = str.endsWith('.')
  return !endsWithPeriod ? str : stripFinalPeriods(str.slice(0, -1))
}

export function getEncodedFilename (filename) {
  const extension = getExtension(filename)
  const bareFilename = getBareFilename(filename)
  return extension ? encodeURIComponent(`${bareFilename.slice(0, 196)}.${extension}`) : encodeURIComponent(bareFilename.slice(0, 196))
}

export function getCSRF () {
  return document.querySelector("meta[name='token']").getAttribute('content')
}

export function getRecaptchaSiteKey () {
  return document.querySelector("meta[name='recaptcha-site-key']").getAttribute('content')
}

export function fetchOptions (method, body = null) {
  let csrfToken = getCSRF()
  const fetchHeaders = { 'Content-Type': 'application/json' }
  if (csrfToken) fetchHeaders['x-csrf-token'] = csrfToken
  return {
    method: method,
    credentials: 'same-origin',
    headers: new Headers(fetchHeaders),    // eslint-disable-line
    body: body ? JSON.stringify(body) : null
  }
}

export const getReadmeAfterReferenceMove = (readme, oldIndex, newIndex) => {
  const oldRegex = new RegExp(`][${oldIndex + 1}]`, 'g')
  return readme.replace(oldRegex, `][${newIndex + 1}]`)
}

export const getReadmeAfterReferenceDelete = (readme, deletedIndex) => {
  const deletedRegex = new RegExp(`\\[[^[]*?\\]\\[${deletedIndex}\\]`, 'g')
  return readme.replace(deletedRegex, '')
}

export const addFilterString = (component) => {
  if (component === null) return null
  // console.log('component: ' + component)
  const metadata = component.metadata
  let filterString = ''
  switch (component.type) {
    case 'article': {
      filterString += metadata.title + ' ' + metadata.authors.join(' ') + ' ' + metadata.journal.name + ' ' + metadata.journal.abbreviation
      break
    }
    case 'link': {
      filterString += metadata.title + ' ' + metadata.url
      break
    }
    case 'book': {
      filterString += metadata.title + ' ' + metadata.author
      break
    }
    case 'module': {
      filterString += metadata.title + ' ' + metadata.author

      break
    }
    case 'user': {
      filterString += metadata.username + ' ' + (metadata.fullName || '')
      break
    }
  }
  let newComponent = Object.assign({}, component, {
    filterString: filterString
  })
  return newComponent
}

export const getModuleHydrationDataFromPage = () => {
  return document.querySelector("meta[name='module-hydration-data']").getAttribute('content') ? JSON.parse(document.querySelector("meta[name='module-hydration-data']").getAttribute('content')) : null
}
export const decorateModuleHydrationData = (moduleHydrationData) => {
  const components = moduleHydrationData.components.map(addFilterString) || []
  moduleHydrationData['components'] = components
  moduleHydrationData['visible'] = components
  // console.log(`moduleHydrationData: ${JSON.stringify(moduleHydrationData)}`)
  return Object.assign({}, STATE_MODULE, moduleHydrationData)
}
export function getHydrationDataFromStore () {
  if (window.history.state && window.history.state.store) {
    // console.log('hydrationData from window.history.state.store')
    return window.history.state.store
  } else {
    return getHydrationData()
  }
}
export function getHydrationData () {
  let hydrationData = {}
  let rawSessionHydrationData = document.querySelector("meta[name='session-hydration-data']").getAttribute('content')
  let sessionHydrationData = JSON.parse(rawSessionHydrationData || '{}')

  let moduleHydrationData = getModuleHydrationDataFromPage()
  let searchHydrationData = (document.querySelector("meta[name='search-hydration-data']").getAttribute('content')) ? JSON.parse(document.querySelector("meta[name='search-hydration-data']").getAttribute('content')) : null
  let userMetricsHydrationData = (document.querySelector("meta[name='user-metrics-hydration-data']").getAttribute('content')) ? JSON.parse(document.querySelector("meta[name='user-metrics-hydration-data']").getAttribute('content')) : null
  hydrationData['userMetrics'] = userMetricsHydrationData || {}
  hydrationData['user'] = sessionHydrationData['user'] || STATE_USER
  // console.log('hydrationData.user.podcast: ' + JSON.stringify(hydrationData.user.podcast))
  if (sessionHydrationData['stars']) {
    hydrationData['stars'] = {
      starring: STATE_REF_COLLECTIONS,
      unstarring: STATE_REF_COLLECTIONS,
      componentUUIDs: sessionHydrationData['stars']['componentUUIDs'] || STATE_REF_COLLECTIONS
    }
  }
  if (sessionHydrationData['notifications']) {
    hydrationData['notifications'] = sessionHydrationData['notifications']
  }
  hydrationData['history'] = STATE_HISTORY
  hydrationData['history']['modules']['modified'] = sessionHydrationData['history'] ? sessionHydrationData.history.modules.modified : []

  hydrationData['storage'] = sessionHydrationData['storage'] || STATE_STORAGE
  hydrationData['storage']['uploading'] = false
  hydrationData['alert'] = sessionHydrationData['alert'] || STATE_ALERT
  hydrationData['client'] = sessionHydrationData['client'] || STATE_CLIENT
  if (moduleHydrationData) {
    hydrationData['module'] = decorateModuleHydrationData(moduleHydrationData)
  }
  if (searchHydrationData) {
    hydrationData['search'] = searchHydrationData
  }

  return hydrationData
}

export function indexOfObjWithAttrInArr (array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[ i ][ attr ] === value) {
      return i
    }
  }
  return -1
}

export function rearrangeObjInArray (components, fromIndex, toIndex) {
  let item = components[fromIndex]
  let intermediateComponents = components.slice(0, fromIndex).concat(components.slice(fromIndex + 1))
  let finalComponents = intermediateComponents.slice(0, toIndex).concat(item).concat(intermediateComponents.slice(toIndex))
  return finalComponents
}

export function renameFile (extension, title, journalAbbr = '', date = '') {
  var newTitle = slugify(journalAbbr + ' ' + date + ' ' + title)
  newTitle = newTitle.substring(0, 100) + '.' + extension
  return newTitle
}

export const slugify = (str) => require('slugify')(str, {lower: true, remove: /[$*_+~,<>;{}=#%^&`.()?'"!\:@/]/g}) // eslint-disable-line

export const validator = {
  isEmail: function (email) {
    return /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/.test(email)  // eslint-disable-line
  },
  isPassword: function (password) {
    return /.{6,}/.test(password)
  },
  isUrl: function (url) {
    return /@^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$@iS/.test(url)
  }
}

export function base64toBlob (b64Data, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data)    // eslint-disable-line
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[ i ] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })    // eslint-disable-line
  return blob
}
