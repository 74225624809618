import React, {Component} from 'react'
import Article from '../components/Article'
import SearchPaginator from '../components/SearchPaginator'
import RefineSearchForm from '../components/RefineSearchForm'

class PubmedSearchResults extends Component {
  constructor (props) {
    super(props)
    this.state = {term: ''}
    this.handleTermChange = this.handleTermChange.bind(this)
  }

  handleTermChange (e) {
    this.setState({...this.state, term: e.target.value})
  }

  render () {
    const { user, search, stars, history, storage, dispatch, client } = this.props
    const {webEnv, queryKey, retmax, count, page} = search
    const {term} = this.state
    const encodedHistory = encodeURIComponent(JSON.stringify(search.history))
    // console.log('search.history.length: ' + search.history.length)
    // console.log('user (PubmedSearchResults): ' + JSON.stringify(user))
    const isDesktop = client.type.localeCompare('desktop') === 0 || client.type.localeCompare('tablet') === 0
    const modifiedModules = history.modules.modified
    const components = search.components
    // const page = page || 1

    let userLoggedIn = !!user.username

    const resultCount = parseInt(count, 10)
    const firstResultIndex = (page - 1) * retmax
    let lastResultIndex = page * retmax - 1
    if (lastResultIndex > (resultCount - 1)) lastResultIndex = resultCount - 1
    const pages = resultCount ? (resultCount - resultCount % retmax) / retmax + (resultCount % retmax > 0 ? 1 : 0) : 0

    let Terms = () => {
      const TermsList = () => {
        if (!search.history) return null
        return (
          <ol className='mb-0'>
            {
              search.history.map((item, index) => {
                if (item.term) {
                  if (item.term === 'review[filter]') {
                    return (
                      <li key={index}>limit to review articles</li>
                    )
                  }
                  return (
                    <li key={index}>{item.term}</li>
                  )
                } else if (item.reldate) {
                  const years = (item.reldate - item.reldate % 365) / 365
                  const yearsSince = years > 1 ? `${years} years` : 'year'
                  return (
                    <li key={index}>limit to articles published within the last {yearsSince}</li>
                  )
                } else {
                  return (
                    <li key={index}>{item.term}</li>
                  )
                }
              })
            }
          </ol>
        )
      }
      const ResultReport = () => {
        const termOrTerms = search.history.length > 1 ? 'the terms:' : ''
        if (resultCount === 0) return <span>{`No results for ${termOrTerms}`}</span>
        return <span>{`${firstResultIndex + 1}-${lastResultIndex + 1} of ${resultCount} results for ${termOrTerms}`}</span>
      }
      if (search.history.length === 1) {
        return (
          <div>
            <ResultReport /> {search.history[0].term}
          </div>
        )
      }
      if (search.history.length > 1) {
        return (
          <div>
            <ResultReport />
            <TermsList />
          </div>
        )
      }
      return null
    }

    const Limits = () => {
      const dateLimitApplied = search.history.length > 1 && search.history.map(entry => entry.reldate).filter(relDate => parseInt(relDate, 10) > 0).length > 0
      const typeLimitApplied = search.history.length > 1 && search.history.map(entry => entry.term).indexOf('review[filter]') > -1
      // console.log('history.reldateMap: ' + search.history.map(entry => entry.reldate))
      // console.log('history.termMap: ' + search.history.map(entry => entry.term))
      // console.log('dateLimitApplied: ' + dateLimitApplied)
      // console.log('typeLimitApplied: ' + typeLimitApplied)
      if (search.history.length > 0 && resultCount > 20 && (!typeLimitApplied || !dateLimitApplied)) {
        return (
          <div>
            Enter another term to narrow your current results, or restrict your results to {!typeLimitApplied && <a tabIndex='-1' style={{marginRight: '0em'}} href={`/search/pubmed?term=review[filter]&webEnv=${webEnv}&queryKey=${queryKey}&history=${encodedHistory}`}>review articles</a>}
            {!typeLimitApplied && !dateLimitApplied && ' or '}
            {!dateLimitApplied && <span>
              {'articles published in the last '}
              <a href={`/search/pubmed?reldate=366&webEnv=${webEnv}&queryKey=${queryKey}&history=${encodedHistory}`} style={{marginRight: '0em'}} >1 year</a>{' / '}
              <a href={`/search/pubmed?reldate=1827&webEnv=${webEnv}&queryKey=${queryKey}&history=${encodedHistory}`} style={{marginRight: '0em'}} >5 years</a>{' / '}
              <a href={`/search/pubmed?reldate=3653&webEnv=${webEnv}&queryKey=${queryKey}&history=${encodedHistory}`} style={{marginRight: '0em'}} >10 years</a>
            </span>}.
          </div>
        )
      } else {
        return null
      }
    }

    const Stats = () => {
      if (search.history.length > 0) {
        return (
          <div>
            <Terms />
          </div>
        )
      } else {
        return null
      }
    }

    return (
      <div>
        <div className='card'>
          <div className='card-body'>
            <div className={search.history.length > 0 && 'mb-2'}>
              <RefineSearchForm term={term} webEnv={webEnv} queryKey={queryKey} history={encodedHistory} onInputChange={this.handleTermChange} />
            </div>
            {search.history.length > 0 && <Limits />}
            {search.history.length > 0 && <Stats />}
          </div>
        </div>
        <div className='media-list'>
          {components.map(
            (component, index, array) => {
            // console.log('component.type: ' + component.type)
              switch (component.type) {
                case 'article': {
                // console.log('COMPONENT: ' + JSON.stringify(component))
                  return (
                    <Article
                      dispatch={dispatch} uuid={component.uuid} article={component.metadata} key={component.uuid} index={index} length={search.components.length}
                      editing={false} userLoggedIn={userLoggedIn}
                      searchTitle={search.title}
                      stars={stars}
                      storage={storage}
                      rearranging={search.rearranging}
                      modifiedModules={modifiedModules}
                      isDesktop={isDesktop}
                    />
                  )
                }
              }
            }
          )}
          <SearchPaginator pages={pages} activePage={page} searchUrl={`/search/pubmed?webEnv=${webEnv}&queryKey=${queryKey}&history=${encodedHistory}`} />
        </div>
      </div>
    )
  }
}

export default PubmedSearchResults
