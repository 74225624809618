import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { uploadAttachments } from '../actions'
import Upload from '../components/Upload'

class AsyncUploadAttachments extends Component {
  constructor (props) {
    super(props)
    this.onUpload = this.onUpload.bind(this)
  }
  onUpload (files, fileSha1Map) {
    const { dispatch, moduleTitle, moduleAuthor, organization } = this.props
    if (files.length > 0) {
      dispatch(uploadAttachments(files, fileSha1Map, moduleAuthor, moduleTitle, organization))
    }
  }
  render () {
    return (
      <Upload
        onUpload={this.onUpload}
        existingSha1s={this.props.existingSha1s}
        label='Upload a file'
        icon='upload'
        multipleFiles
        asNav
      />
    )
  }
}

AsyncUploadAttachments.propTypes = {
  moduleAuthor: PropTypes.string.isRequired,
  moduleTitle: PropTypes.string.isRequired,
  organization: PropTypes.string,
  existingSha1s: PropTypes.arrayOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired
}

export default AsyncUploadAttachments
