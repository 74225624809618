import {
  STATE_MODULE,
  FILTER_MODULE, UPDATE_FILTER, SELECT_PAGE,
  REMOVE_FROM_MODULE_REQUEST, REMOVE_FROM_MODULE_SUCCESS, REMOVE_FROM_MODULE_ERROR,
  MOVE_REFERENCE_REQUEST, MOVE_REFERENCE_SUCCESS, MOVE_REFERENCE_ERROR,
  EDIT_MODULE, VIEW_MODULE,
  LIBRARY_UPDATED,
  UPLOAD_ATTACHMENTS_REQUEST, UPLOAD_ATTACHMENTS_SUCCESS, UPLOAD_ATTACHMENTS_ERROR, UPLOAD_PROGRESS, UPLOAD_COMPLETE,
  REMOVE_ATTACHMENT_REQUEST, REMOVE_ATTACHMENT_SUCCESS, REMOVE_ATTACHMENT_ERROR,
  UPLOAD_EPISODE_REQUEST, UPLOAD_EPISODE_SUCCESS, UPLOAD_EPISODE_ERROR,
  REMOVE_EPISODE_REQUEST, REMOVE_EPISODE_SUCCESS, REMOVE_EPISODE_ERROR,
  SHARE_LINK_SUCCESS,
  SEARCH_PUBMED_REQUEST,
  UPDATE_README_SUCCESS, UPDATE_README_REQUEST,
  PUBLISH_MODULE_SUCCESS,
  CREATE_MODULE_SUCCESS,
  UPDATE_DESCRIPTION_SUCCESS, UPDATE_DESCRIPTION_REQUEST,
  ADD_TAG_REQUEST, ADD_TAG_ERROR, ADD_TAG_SUCCESS,
  REMOVE_TAG_REQUEST, REMOVE_TAG_ERROR, REMOVE_TAG_SUCCESS, REVERT_CHANGES,
  SAVING, SAVED, CHANGING, MODULE_CHANGING, SHARE_LINK_REQUEST, SHARE_LINK_ERROR, REINITIALIZE_STORE, SEARCH_LITSIGNAL_REQUEST, SEARCH_ALL_REQUEST
} from '../constants'

import { rearrangeObjInArray, slugify } from '../helpers'

const getFilter = (state) => {
  return (component) => {
    const filterTerms = state.filter.replace(',', ' ').split(' ')
    return !filterTerms.map(function (term) {
      return component.filterString.toLowerCase().includes(term.toLowerCase()) || component.filterString.toLowerCase().includes('youtube') || component.filterString.toLowerCase().includes('youtu.be') || component.filterString.toLowerCase().includes('vimeo')
    }).includes(false)
  }
}

function module (state = STATE_MODULE, action) {
  switch (action.type) {
    case REINITIALIZE_STORE: {
      return action.store.module || state
    }
    case SEARCH_PUBMED_REQUEST:
    case SEARCH_ALL_REQUEST:
    case SEARCH_LITSIGNAL_REQUEST: {
      return STATE_MODULE
    }
    case ADD_TAG_REQUEST:
    case ADD_TAG_ERROR:
    case REMOVE_TAG_REQUEST:
    case REMOVE_TAG_ERROR:
      return state
    case REMOVE_FROM_MODULE_REQUEST:
      return Object.assign({}, state, {
        saveState: SAVING,
        removing: state.removing.concat(action.componentUUID)
      })
    case REMOVE_FROM_MODULE_SUCCESS: {
      const removingIndex = state.removing.indexOf(action.componentUUID)

      const stateAfter = Object.assign({}, state, {
        saveState: SAVED,
        unpublished: true,
        removing: state.removing
          .slice(0, removingIndex)
          .concat(state.removing.slice(removingIndex + 1)),
        components: state.components.filter(component => component.uuid !== action.componentUUID)
      })
      const visible = stateAfter.components
      const finalState = Object.assign({}, stateAfter, {
        visible: visible
      })
      return finalState
    }
    case REMOVE_FROM_MODULE_ERROR: {
      let removingIndex = state.removing.indexOf(action.componentUUID)
      return Object.assign({}, state, {
        saveState: SAVED,
        removing: state.removing
          .slice(0, removingIndex)
          .concat(state.removing.slice(removingIndex + 1))
      })
    }
    case CREATE_MODULE_SUCCESS: {
      const {module} = action
      if (state.title === 'modules') {
        // console.log('Module created while ON the /username/modules page.')
        return Object.assign({}, state, {
          components: [module].concat(state.components),
          visible: [module].concat(state.components),
          filter: ''
        })
      }
      // console.log('Module created while AWAY FROM the /username/modules page.')
      return state
    }
    case MOVE_REFERENCE_REQUEST: {
      // rearrange and place in rearranging onject
      const components = rearrangeObjInArray(state.components, action.fromIndex, action.toIndex)
      return Object.assign({}, state, {
        rearranging: true,
        components,
        saveState: SAVING,
        visible: components
      })
    }
    case MOVE_REFERENCE_SUCCESS: {
      // use a helper function to rearrange the module
      return Object.assign({}, state, {
        unpublished: true,
        saveState: SAVED,
        rearranging: false
      })
    }

    case MOVE_REFERENCE_ERROR: {
      // use the same function to move the item back to its original position
      const components = rearrangeObjInArray(state.components, action.toIndex, action.fromIndex)
      return Object.assign({}, state, {
        rearranging: false,
        saveState: SAVED,
        components,
        visible: components
      })
    }
    case UPDATE_FILTER: {
      return Object.assign({}, state, {
        filter: action.terms
      })
    }
    case FILTER_MODULE: {
      const filteredModule = state.components.filter(getFilter(state))
      return Object.assign({}, state, {
        visible: filteredModule
      })
    }
    case SELECT_PAGE: {
      return Object.assign({}, state, {
        page: action.page
      })
    }
    case EDIT_MODULE: {
      return Object.assign({}, state, {
        filter: '',
        editing: true
      })
    }
    case VIEW_MODULE: {
      return Object.assign({}, state, {
        editing: false
      })
    }
    case REVERT_CHANGES: {
      return action.module
    }
    case SHARE_LINK_REQUEST: {
      return Object.assign({}, state, {
        saveState: SAVING
      })
    }
    case SHARE_LINK_ERROR: {
      return Object.assign({}, state, {
        saveState: SAVED
      })
    }
    case SHARE_LINK_SUCCESS: {
      const components = state.components.concat(action.link)
      const stateAfter = Object.assign({}, state, {
        saveState: SAVED,
        components,
        visible: components
      })
      return stateAfter
    }
    case UPLOAD_EPISODE_REQUEST:
    case REMOVE_EPISODE_REQUEST:
    case REMOVE_ATTACHMENT_REQUEST:
    case UPLOAD_ATTACHMENTS_REQUEST: {
      return Object.assign({}, state, {
        saveState: SAVING
      })
    }
    case UPLOAD_ATTACHMENTS_ERROR: {
      return Object.assign({}, state, {
        uploading: false,
        saveState: SAVED
      })
    }
    case UPLOAD_ATTACHMENTS_SUCCESS: {
      return Object.assign({}, state, {
        unpublished: true,
        uploading: false,
        saveState: SAVED,
        attachments: action.attachments
      })
    }
    case UPLOAD_EPISODE_ERROR: {
      return Object.assign({}, state, {
        uploading: false,
        saveState: SAVED
      })
    }
    case UPLOAD_EPISODE_SUCCESS: {
      return Object.assign({}, state, {
        unpublished: true,
        uploading: false,
        saveState: SAVED,
        episode: action.episode
      })
    }
    case REMOVE_ATTACHMENT_ERROR: {
      return Object.assign({}, state, {
        saveState: SAVED
      })
    }
    case REMOVE_ATTACHMENT_SUCCESS: {
      return Object.assign({}, state, {
        unpublished: true,
        saveState: SAVED,
        attachments: action.attachments
      })
    }
    case REMOVE_EPISODE_ERROR: {
      return Object.assign({}, state, {
        saveState: SAVED
      })
    }
    case REMOVE_EPISODE_SUCCESS: {
      return Object.assign({}, state, {
        unpublished: true,
        saveState: SAVED,
        episode: null
      })
    }
    case MODULE_CHANGING: {
      return Object.assign({}, state, {
        saveState: CHANGING
      })
    }
    case UPDATE_README_REQUEST: {
      return Object.assign({}, state, {
        saveState: SAVING
      })
    }
    case UPDATE_README_SUCCESS: {
      return Object.assign({}, state, {
        readme: action.readme,
        saveState: SAVED,
        unpublished: true
      })
    }
    case UPDATE_DESCRIPTION_REQUEST: {
      return Object.assign({}, state, {
        saveState: SAVING
      })
    }
    case UPDATE_DESCRIPTION_SUCCESS: {
      return Object.assign({}, state, {
        description: action.description,
        unpublished: true,
        saveState: SAVED
      })
    }
    case UPLOAD_PROGRESS: {
      return Object.assign({}, state, {
        uploading: {
          total: action.total,
          loaded: action.loaded,
          fileCount: action.fileCount
        }
      })
    }
    case UPLOAD_COMPLETE: {
      return Object.assign({}, state, {
        uploading: false
      })
    }
    case LIBRARY_UPDATED: {
      return Object.assign({}, state, {
        filter: '',
        components: action.components,
        attachments: action.attachments,
        visible: action.components
      })
    }
    case PUBLISH_MODULE_SUCCESS: {
      const {description, readme, attachments, episode, components, unpublished, versionUUIDs} = action.module
      return Object.assign({}, state, {description, readme, attachments, episode, components, unpublished, versionUUIDs})
    }
    case ADD_TAG_SUCCESS: {
      const {tag} = action
      return {...state, tags: [...state.tags, tag]}
    }
    case REMOVE_TAG_SUCCESS: {
      const {tag} = action
      const tagIndex = state.tags.indexOf(slugify(tag))
      const tags = state.tags.slice(0, tagIndex).concat(state.tags.slice(tagIndex + 1))
      return {...state, tags}
    }
    default:
      return state
  }
}

export default module
