import 'core-js/features/array/find'
import 'core-js/features/promise'

import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import configureStore from './configureStore'
import { getHydrationData, getHydrationDataFromStore } from './helpers'
import { reinitializeStore } from './actions'
import RootLayout from './containers/RootLayout'
import StaticLayout from './containers/StaticLayout'
import Footer from './components/Footer'

let rootElement = document.getElementById('react-root')
let navbarElement = document.getElementById('react-navbar')
let footerElement = document.getElementById('react-footer')

const store = configureStore(getHydrationData())
// window.history.pushState({store: store.getState()}, '', window.location)

window.addEventListener('popstate', (evt) => {
  if (window.history.state && window.history.state.store) {
    // console.log('navigation.type: ' + evt.currentTarget.performance.navigation.type)
    store.dispatch(reinitializeStore(getHydrationDataFromStore()))
  }
})

const renderRoot = (element, store) => {
  render(
    <Provider store={store}>
      <RootLayout />
    </Provider>,
    element
  )
}

const renderNavbar = (element, store) => {
  render(
    <Provider store={store}>
      <StaticLayout />
    </Provider>,
    element
  )
}

const renderFooter = (element, store) => {
  render(
    <Provider store={store}>
      <Footer />
    </Provider>,
    element
  )
}

const renderPage = (root, navbar, footer) => {
  root && renderRoot(root, store)
  navbar && renderNavbar(navbar, store)
  footer && renderFooter(footer, store)
}

renderPage(rootElement, navbarElement, footerElement)
