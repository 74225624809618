import React from 'react'
import Alert from '../components/Alert'
import NavbarView from './NavbarView'
import UploadLibraryProgress from '../components/UploadLibraryProgress'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
  return {
    module: state.module,
    search: state.search,
    userMetrics: state.userMetrics,
    notifications: state.notifications,
    alert: state.alert
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch
  }
}

class LayoutContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {position: document.body.scrollHeight > window.screen.availHeight ? 'relative' : 'absolute'}
  }
  render () {
    return (
      <div id='layout'>
        <div id='react-modal' />
        <Alert />
        <NavbarView />
        <UploadLibraryProgress />
      </div>
    )
  }
}

const StaticLayout = connect(mapStateToProps, mapDispatchToProps)(LayoutContainer)

export default StaticLayout
