import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {debounce} from 'lodash'
import { updateDescription, moduleChanging } from '../actions'
import TextareaAutosize from 'react-autosize-textarea/lib'

class EditDescription extends Component {
  constructor (props) {
    super(props)
    this.state = {description: this.props.module.description}
    this.descriptionChanged = this.descriptionChanged.bind(this)
    this.dispatchUpdateDescription = debounce(this.dispatchUpdateDescription.bind(this), 1000)
  }
  dispatchUpdateDescription () {
    this.props.dispatch(updateDescription(this.props.module.uuid, this.state.description))
  }
  descriptionChanged (e) {
    this.setState({description: e.target.value})
    this.props.dispatch(moduleChanging())
    this.dispatchUpdateDescription()
  }
  render () {
    return (
      <div>
        <label htmlFor='description' className='ls-heavy'>DESCRIPTION</label>
        <TextareaAutosize
          rows={1}
          id='description'
          name='description'
          placeholder='Add a description to this module...'
          onChange={this.descriptionChanged}
          className='form-control'
          value={this.state.description} />
      </div>
    )
  }
}

EditDescription.propTypes = {
  module: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default EditDescription
