import React from 'react'
import PubmedSearchResults from '../components/PubmedSearchResults'
import LitsignalSearchResults from '../components/LitsignalSearchResults'
import UserSearchResults from '../components/UserSearchResults'
import AllSearchResults from '../components/AllSearchResults'
import { connect } from 'react-redux'
import ModalCard from '../components/ModalCard'

const mapStateToProps = (state) => {
  return {
    user: state.user,
    search: state.search,
    stars: state.stars,
    history: state.history,
    storage: state.storage,
    client: state.client
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch
  }
}

const SearchContainer = ({
  user,
  search,
  stars,
  history,
  storage,
  dispatch,
  client
}) => {
  return (
    <div>
      {search.searching && <ModalCard>
        <div className='card-body'><h1 className='text-center'>Searching</h1></div>
      </ModalCard>}
      {search.components.length > 0 && <div>
        {search.type === 'litsignal' && <LitsignalSearchResults
          user={user}
          search={search}
          stars={stars}
          history={history}
          storage={storage}
          dispatch={dispatch}
          client={client}
        />}
        {search.type === 'pubmed' && <PubmedSearchResults
          user={user}
          search={search}
          stars={stars}
          history={history}
          storage={storage}
          dispatch={dispatch}
          client={client}
        />}
        {search.type === 'user' && <UserSearchResults
          user={user}
          search={search}
          stars={stars}
          history={history}
          storage={storage}
          dispatch={dispatch}
          client={client}
        />}
        {search.type === 'all' && <AllSearchResults
          user={user}
          search={search}
          stars={stars}
          history={history}
          storage={storage}
          dispatch={dispatch}
          client={client}
        />}
      </div>}
      {search.components.length === 0 && search.term && <div className='card'>
        <div className='card-body'>
          <div>No results found for: {search.term}</div>
          <div>Please submit a different term above to start a new search, or use your browser's back button to return to the previous results.</div>
        </div>
      </div>}
      {search.components.length === 0 && !search.term && <div className='card'>
        <div className='card-header'>
          <h5 className='my-0'>Litsignal Search</h5>
        </div>
        <div className='card-body'>
          <div>
            Please submit a term using the form above to initiate a search.
          </div>
        </div>
      </div>}
    </div>
  )
}

const SearchView = connect(mapStateToProps, mapDispatchToProps)(SearchContainer)

export default SearchView
