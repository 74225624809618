import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ModalCard from '../components/ModalCard'
import {getCSRF, fetchOptions, slugify} from '../helpers'
import fetch from 'isomorphic-fetch'
import {API_URL, GET} from '../constants'
import { debounce } from 'lodash'

class ForkModule extends Component {
  constructor (props) {
    super(props)
    this.state = {showForkModuleModal: false, titleAvailable: true, title: slugify(this.props.canonicalTitle), canonicalTitle: this.props.canonicalTitle}
    this.openForkModule = this.openForkModule.bind(this)
    this.close = this.close.bind(this)
    this.onTitleChange = this.onTitleChange.bind(this)
    this.checkTitleAvailability = debounce(this.checkTitleAvailability.bind(this), 50)
  }

  componentWillMount () {
    const fetchUrl = `${API_URL}/forks/available/${this.state.title}`
    fetch(fetchUrl, fetchOptions(GET))
      .then(response => {
        if (response.status === 204 || response.status === 304) {
        // proceed with current title
          return {titleAvailabe: true}
        } else if (response.status === 409) {
        // prompt for new title
          return {titleAvailable: false}
        } else {
          return {titleAvailabe: false}
        }
      })
      .then(titleAvailable => this.setState(Object.assign({}, this.state, titleAvailable)))
  }
  
  checkTitleAvailability (title) {
    if (title === this.state.title) {
      const fetchUrl = `${API_URL}/forks/available/${this.state.title}`
      return fetch(fetchUrl, fetchOptions(GET))
        .then(response => {
          if (response.status === 204 || response.status === 304) {
          // proceed with current title
            return {titleAvailable: true}
          } else if (response.status === 409) {
          // prompt for new title
            return {titleAvailable: false}
          } else {
            return {titleAvailabe: false}
          }
        })
        .then(titleAvailable => this.setState(Object.assign({}, this.state, titleAvailable)))
    }
  }

  close (e) {
    e.stopPropagation()
    this.setState(Object.assign({}, this.state, {showForkModuleModal: false}))
  }
  openForkModule () {
    this.setState(Object.assign({}, this.state, {showForkModuleModal: true}))
  }

  onTitleChange (evt) {
    const canonicalTitle = evt.target.value
    const title = slugify(canonicalTitle)
    this.setState(Object.assign({}, this.state, {canonicalTitle, title}))
    // console.log(JSON.stringify(this.state))
    this.checkTitleAvailability(title)
  }

  render () {
    const { author, canonicalTitle, isDesktop, username } = this.props
    const originalTitle = slugify(canonicalTitle)
    const {titleAvailable, title} = this.state
    // const isFirstModule = moduleOptions.length === 0
    const titleFeedbackClass = titleAvailable ? 'valid-feedback' : 'invalid-feedback'
    const TitleFeedback = title ? !titleAvailable ? `${username}/${title} is not available` : `${username}/${title} is available!` : ''
    const titleValidationClass = title ? titleAvailable ? 'form-control is-valid' : 'form-control is-invalid' : 'form-control'
    return (
      <a tabIndex='-1' role='button' title='Create a fork of this module' onClick={this.openForkModule}>
        <i className='icon icon-flow-branch' />{isDesktop ? 'Edit' : ''}
        {this.state.showForkModuleModal && <ModalCard onHide={this.close}>
          <div className='card-body'>
            <h4><i className='icon icon-flow-branch' />Edit</h4>
            <form method='post' action={`/${author}/${originalTitle}/forks`} >
              <fieldset>
                <p>Editing someone else's module actually creates a fork of the module in your account. You can edit the fork independently and then contribute your updates to the original, or you can maintain it as an independent project. Please verify that you would like to continue this action.</p>
                <input type='hidden' name='_csrf' value={getCSRF()} />
                <div className='form-group' >
                  <input
                    className={titleValidationClass}
                    required
                    name='canonicalTitle'
                    type='text'
                    placeholder='title'
                    onChange={this.onTitleChange}
                    value={this.state.canonicalTitle}
                    autoFocus />
                  <div className={titleFeedbackClass}>
                    {TitleFeedback}
                  </div>
                </div>
                <button className='btn btn-primary' disabled={!titleAvailable} type='submit'>
                Create fork
                </button>
              </fieldset>
            </form>
          </div>
        </ModalCard>}
      </a>
    )
  }
}

ForkModule.propTypes = {
  isDesktop: PropTypes.bool,
  author: PropTypes.string.isRequired,
  canonicalTitle: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired
}

export default ForkModule
