import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ModalCard from '../components/ModalCard'

class Share extends Component {
  constructor (props) {
    super(props)
    this.state = {showRecommendModal: false}
    this.openRecommend = this.openRecommend.bind(this)
    this.closeRecommend = this.closeRecommend.bind(this)
    this.handleRecipientChange = this.handleRecipientChange.bind(this)
    this.handleMessageChange = this.handleMessageChange.bind(this)
    this.recommend = this.recommend.bind(this)
  }

  closeRecommend (e) {
    e.stopPropagation()
    this.setState({ showRecommendModal: false })
  }
  openRecommend () {
    this.setState({ showRecommendModal: true })
  }

  handleRecipientChange (e) {
    this.props.onRecipientChange(e.target.value)
  }

  handleMessageChange (e) {
    this.props.onMessageChange(e.target.value)
  }

  recommend (e) {
    this.closeRecommend(e)
    this.props.onRecommend(e)
  }

  render () {
    const { reference, recipient, message, isDesktop } = this.props
    const remainingMessageCharacters = 255 - message.length
    let messageLabel = 'Message'
    if (remainingMessageCharacters < 50) {
      messageLabel = 'Message (' + remainingMessageCharacters + ' characters remaining)'
    }
    const remainingRecipientCharacters = 255 - recipient.length
    let recipientLabel = 'Recipient'
    if (remainingRecipientCharacters < 50) {
      recipientLabel = 'Recipient (' + remainingRecipientCharacters + ' characters remaining)'
    }
    return (
      <a tabIndex='-1' role='button' id='dropdown' onClick={this.openRecommend}>
        <span className='icon icon-share' />{isDesktop ? 'Share' : ''}
        {this.state.showRecommendModal && <ModalCard onHide={this.closeRecommend}>
          <div className='card-body'>
            {reference}

            <form style={{paddingTop: '1rem'}}>
              <div className='form-group'>
                <label htmlFor='message-input'>{messageLabel}</label>
                <textarea id='message-input' name='message'
                  className='form-control'
                  type='text'
                  placeholder='(optional)'
                  value={message}
                  onChange={this.handleMessageChange}
                  maxLength='255'
                  autoFocus
                />
              </div>
              <div className='form-group'>
                <label htmlFor='recipient-input'>{recipientLabel}</label>
                <div className='input-group'>
                  <input id='recipient-input' name='recipient'
                    type='text'
                    className='form-control'
                    placeholder='Username or email'
                    value={recipient}
                    onChange={this.handleRecipientChange}
                    maxLength='255'
                  />
                  <div className='input-group-append'>
                    <button className='btn btn-primary' type='button' onClick={this.recommend}>Recommend</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </ModalCard>}
      </a>
    )
  }
}

Share.propTypes = {
  reference: PropTypes.element.isRequired,
  recipient: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onRecommend: PropTypes.func.isRequired,
  onRecipientChange: PropTypes.func.isRequired,
  onMessageChange: PropTypes.func.isRequired,
  bsStyle: PropTypes.string,
  isDesktop: PropTypes.bool
}

export default Share
