import {
  SEARCH_PUBMED_REQUEST, SEARCH_PUBMED_SUCCESS, SEARCH_PUBMED_ERROR,
  RECONCILE_PUBMED_REQUEST, RECONCILE_PUBMED_SUCCESS, RECONCILE_PUBMED_ERROR,
  SELECT_SEARCH_ALL,
  SELECT_PUBMED_SEARCH, SELECT_LITSIGNAL_SEARCH, SELECT_BOOK_SEARCH,
  SEARCH_LITSIGNAL_REQUEST, SEARCH_LITSIGNAL_SUCCESS, SEARCH_LITSIGNAL_ERROR,
  SEARCH_BOOK_REQUEST, SEARCH_BOOK_SUCCESS, SEARCH_BOOK_ERROR,
  SELECT_PUBMED_PAGE_REQUEST, SELECT_PUBMED_PAGE_SUCCESS, SELECT_PUBMED_PAGE_ERROR,
  SELECT_LITSIGNAL_PAGE_REQUEST, SELECT_LITSIGNAL_PAGE_SUCCESS, SELECT_LITSIGNAL_PAGE_ERROR,
  RECONCILE_UPLOAD_REQUEST,
  UPDATE_SEARCH_TERM,
  REINITIALIZE_STORE,
  SEARCH_ALL_SUCCESS,
  SEARCH_ALL_ERROR,
  SEARCH_ALL_REQUEST
} from '../constants'

export const STATE_SEARCH = {
  type: 'all',
  term: '',
  submittedTerm: '',
  page: 1,
  webEnv: '',
  queryKey: '',
  retmax: '20',
  reldate: '',
  components: [],
  visible: [],
  history: [],
  searching: false,
  error: false
}

function search (state = STATE_SEARCH, action) {
  switch (action.type) {
    case REINITIALIZE_STORE: {
      // console.log(`action.store.search: ${JSON.stringify(action.store.search)}`)
      return action.store.search
    }
    case RECONCILE_PUBMED_SUCCESS:
    case SEARCH_PUBMED_SUCCESS: {
      const {webEnv, queryKey, retstart, retmax, count, components, term, history} = action
      return Object.assign({}, state, {
        type: 'pubmed',
        components: components,
        visible: components,
        page: 1,
        term,
        queryKey,
        retstart,
        retmax,
        count,
        webEnv,
        history,
        searching: false
      })
    }
    case SEARCH_ALL_SUCCESS: {
      const {search} = action
      return search
    }
    case RECONCILE_UPLOAD_REQUEST: {
      return Object.assign({}, STATE_SEARCH, {
        type: 'pubmed'
      })
    }
    case RECONCILE_PUBMED_REQUEST:
    case SEARCH_BOOK_REQUEST:
    case SEARCH_ALL_REQUEST:
    case SEARCH_LITSIGNAL_REQUEST:
    case SELECT_LITSIGNAL_PAGE_REQUEST:
    case SELECT_PUBMED_PAGE_REQUEST:
    case SEARCH_PUBMED_REQUEST: {
      return Object.assign({}, state, {
        searching: true,
        error: false
      })
    }
    case SELECT_PUBMED_PAGE_SUCCESS: {
      const {components, page} = action
      return Object.assign({}, state, {
        type: 'pubmed',
        page,
        components,
        visible: components,
        searching: false
      })
    }
    case SEARCH_BOOK_SUCCESS:
    case SEARCH_LITSIGNAL_SUCCESS: {
      const {components, term} = action
      return Object.assign({}, state, {
        type: 'litsignal',
        page: 1,
        submittedTerm: term,
        components,
        visible: components,
        searching: false,
        term,
        history: []
      })
    }
    case SELECT_LITSIGNAL_PAGE_SUCCESS: {
      const {page, components} = action
      return Object.assign({}, state, {
        type: 'litsignal',
        page,
        components,
        visible: components,
        searching: false
      })
    }
    case RECONCILE_PUBMED_ERROR:
    case SEARCH_PUBMED_ERROR: {
      return Object.assign({}, state, {
        components: [],
        visible: [],
        error: 'There was an error connecting with the PubMed server. Please try again.',
        searching: false
      })
    }
    case SELECT_PUBMED_PAGE_ERROR: {
      return Object.assign({}, state, {
        error: 'There was an error connecting with the PubMed server. Please try again.',
        searching: false
      })
    }
    case SEARCH_BOOK_ERROR:
    case SELECT_LITSIGNAL_PAGE_ERROR:
    case SEARCH_ALL_ERROR:
    case SEARCH_LITSIGNAL_ERROR: {
      return Object.assign({}, state, {
        components: [],
        visible: [],
        error: 'There was an error while retrieving search results. Please try again.',
        searching: false
      })
    }
    case SELECT_SEARCH_ALL: {
      if (state.type === 'all') return state
      return Object.assign({}, STATE_SEARCH, {
        type: 'all'
      })
    }
    case SELECT_LITSIGNAL_SEARCH: {
      if (state.type === 'litsignal') return state
      return Object.assign({}, STATE_SEARCH, {
        type: 'litsignal'
      })
    }
    case SELECT_PUBMED_SEARCH: {
      if (state.type === 'pubmed') return state
      return Object.assign({}, STATE_SEARCH, {
        type: 'pubmed'
      })
    }
    case SELECT_BOOK_SEARCH: {
      if (state.type === 'book') return state
      return Object.assign({}, STATE_SEARCH, {
        type: 'book'
      })
    }
    case UPDATE_SEARCH_TERM: {
      // console.log('action.term: ' + action.term)
      if (action.term === '' && state.type === 'user') return Object.assign({}, state, {term: '', components: [], visible: [], searching: false})
      return Object.assign({}, state, {
        term: action.term
      })
    }
    default:
      return state
  }
}

export default search
