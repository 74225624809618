import React, {Component} from 'react'
import PropTypes from 'prop-types'
import AsyncModuleLink from '../containers/AsyncModuleLink'
import AsyncModuleVideo from '../containers/AsyncModuleVideo'
import AsyncUploadAttachments from '../containers/AsyncUploadAttachments'
// import SettingsLink from '../components/SettingsLink'
import ViewModule from '../components/ViewModule'
import EditorHelp from '../components/EditorHelp'
import AsyncAddTag from '../containers/AsyncAddTag'
import {logout} from '../actions'
import {SAVING, SAVED} from '../constants'

class EditorNavbar extends Component {
  constructor (props) {
    super(props)
  }
  render () {
    const {dispatch, module, isDesktop} = this.props

    const SaveIndicator = ({saveState}) => {
      if (saveState === SAVING) {
        return <span className='btn btn-sm btn-warning disabled'><i className='icon icon-hour-glass' />{' Saving'}</span>
      } else if (saveState === SAVED) {
        return <span className='btn btn-sm btn-link disabled'><i className='icon icon-save' />{' Saved'}</span>
      } else {
        return <span className='btn btn-sm btn-info disabled'><i className='icon icon-pencil' />{' Editing'}</span>
      }
    }

    return (
      <nav className='navbar sticky-top navbar-expand navbar-light bg-light app-navbar flex-nowrap d-print-none editing-navbar' >
        <div className='flex-column w-100'>
          <div className='flex-row'>
            <ul className='navbar-nav'>
              <li className='nav-item navbar-brand my-auto mr-auto'>{module.canonicalTitle}</li>
              <li className='nav-item navbar-text my-auto'><SaveIndicator saveState={module.saveState} /></li>
            </ul>
          </div>
          <div className='flex-row editing-controls'>
            <ul className='navbar-nav'>
              <li className='nav-item'><AsyncModuleLink moduleUUID={module.uuid} dispatch={dispatch} isDesktop={isDesktop} /></li>
              <li className='nav-item'><AsyncModuleVideo moduleUUID={module.uuid} dispatch={dispatch} isDesktop={isDesktop} /></li>
              <li className='nav-item'><AsyncUploadAttachments dispatch={dispatch} bsStyle='default' moduleAuthor={module.author} moduleTitle={module.title} existingSha1s={module.attachments.map(attachment => attachment.sha1)} isDesktop={isDesktop} /></li>
              <li className='nav-item mr-auto'><AsyncAddTag componentUUID={module.uuid} dispatch={dispatch} isDesktop={isDesktop} /></li>
              {/* <li className='nav-item mr-auto'><SettingsLink module={module} /></li> */}
              <EditorHelp />
              <ViewModule isSaved={module.saveState === SAVED} dispatch={dispatch} />
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}

EditorNavbar.propTypes = {
  user: PropTypes.object.isRequired,
  storage: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  isDesktop: PropTypes.bool.isRequired
}

export default EditorNavbar
