import React from 'react'
import PropTypes from 'prop-types'
import { removeAttachment } from '../actions'
import RemoveAttachment from '../components/RemoveAttachment'

const AsyncRemoveAttachment = ({
  dispatch,
  author,
  moduleTitle,
  sha1
}) => {
  const onRemove = () => {
    dispatch(removeAttachment(author, moduleTitle, sha1))
  }

  return (
    <RemoveAttachment
      onRemove={onRemove}
    />
  )
}

AsyncRemoveAttachment.propTypes = {
  dispatch: PropTypes.func.isRequired,
  author: PropTypes.string.isRequired,
  moduleTitle: PropTypes.string.isRequired,
  sha1: PropTypes.string.isRequired
}

export default AsyncRemoveAttachment
