import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { publishModule } from '../actions'
import PublishModule from '../components/PublishModule'

class AsyncPublishModule extends Component {
  constructor (props) {
    super(props)
    this.state = { comment: '' }
    this.onPublish = this.onPublish.bind(this)
    this.handleCommentChange = this.handleCommentChange.bind(this)
  }
  onPublish (e) {
    e.preventDefault()
    const { dispatch, moduleUUID } = this.props
    dispatch(publishModule(moduleUUID, this.state.comment))
  }
  handleCommentChange (value) {
    this.setState({comment: value})
  }
  render () {
    const { moduleUUID, isDesktop } = this.props
    return (
      <PublishModule
        moduleUUID={moduleUUID}
        comment={this.state.comment}
        onCommentChange={this.handleCommentChange}
        onPublish={this.onPublish}
        bsStyle={this.props.bsStyle}
        isDesktop={isDesktop}
      />
    )
  }
}

AsyncPublishModule.propTypes = {
  dispatch: PropTypes.func.isRequired,
  moduleUUID: PropTypes.string.isRequired,
  reference: PropTypes.object
}

export default AsyncPublishModule
