import {SAVED} from '../constants'
const {history} = window

const saveHistory = store => next => action => {
  const shouldUpdateModule = store.getState().module.saveState === SAVED && store.getState().module.editing
  // const previousState = store.getState()
  const result = next(action)
  if (shouldUpdateModule) {
    history.replaceState({store: Object.assign({}, store.getState(), {module: Object.assign({}, store.getState().module, {editing: false})})}, '', '')
  }
  return result
}

export default saveHistory
