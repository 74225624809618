import React from 'react'
import AsyncStar from '../containers/AsyncStar'
import AsyncShare from '../containers/AsyncShare'
// import Edit from '../components/Edit'
// import Review from '../components/Review'
import AsyncAddToModule from '../containers/AsyncAddToModule'

const ReferenceToolbar = ({
  dispatch,
  stars,
  isDesktop,
  modifiedModules,
  reference,
  approved,
  uuid,
  isUploads,
  userLoggedIn
}) => {
  if (isUploads) {
    return (
      <div className='ls-controls'>
        <AsyncStar dispatch={dispatch} stars={stars} refUUID={uuid} isDesktop={isDesktop} />
        <AsyncAddToModule dispatch={dispatch} modifiedModules={modifiedModules} refUUID={uuid} reference={reference} isDesktop={isDesktop} />
        {/* {approved !== false && <Edit refUUID={uuid} type='article' isDesktop={isDesktop} />}
        {approved === false && <Review dispatch={dispatch} refUUID={uuid} isDesktop={isDesktop} />} */}
        <AsyncShare dispatch={dispatch} refUUID={uuid} reference={reference} isDesktop={isDesktop} />
      </div>
    )
  } else {
    return (
      <div className='ls-controls'>
        <AsyncStar dispatch={dispatch} stars={stars} refUUID={uuid} isDesktop={isDesktop} />
        <AsyncAddToModule dispatch={dispatch} modifiedModules={modifiedModules} refUUID={uuid} reference={reference} isDesktop={isDesktop} />
        {/* {approved !== false && <Edit refUUID={uuid} type='article' isDesktop={isDesktop} />}
        {approved === false && <Review dispatch={dispatch} refUUID={uuid} isDesktop={isDesktop} />} */}
        <AsyncShare dispatch={dispatch} refUUID={uuid} reference={reference} isDesktop={isDesktop} />
      </div>
    )
  }
}

export default ReferenceToolbar
