import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {debounce} from 'lodash'
import { updateReadme, moduleChanging } from '../actions'
import TextArea from 'react-autosize-textarea'

class ReadmeEditor extends Component {
  constructor (props) {
    super(props)
    this.state = {readme: this.props.module.readme}
    this.readmeChanged = this.readmeChanged.bind(this)
    this.dispatchUpdateReadme = debounce(this.dispatchUpdateReadme.bind(this), 1000)
  }
  dispatchUpdateReadme () {
    this.props.dispatch(updateReadme(this.props.module.uuid, this.state.readme))
  }
  readmeChanged (e) {
    this.setState({readme: e.target.value})
    this.props.dispatch(moduleChanging())
    this.dispatchUpdateReadme()
  }
  render () {
    return (
      <div>
        <label htmlFor='readme' className='ls-heavy'>TEXT</label>
        <TextArea
          id='readme'
          name='readme'
          placeholder='Start typing to add text to this module...'
          rows={15}
          onChange={this.readmeChanged}
          className='form-control'
          value={this.state.readme} />
      </div>
    )
  }
}

ReadmeEditor.propTypes = {
  module: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default ReadmeEditor
