import SearchPageButtons from '../components/SearchPageButtons'

const SearchPaginator = ({
  pages,
  activePage,
  searchUrl
}) => {
  if (pages < 2) return null
  activePage = parseInt(activePage, 10)
  pages = parseInt(pages, 10)
  return (
    <li className='media list-group-item'>
      <ul className='my-0 pagination justify-content-center' ><SearchPageButtons pages={pages} activePage={activePage} searchUrl={searchUrl} /></ul>
    </li>
  )
}

export default SearchPaginator
