import React from 'react'
import PropTypes from 'prop-types'
import UserModule from '../components/UserModule'
import UserUploads from '../components/UserUploads'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
  return {
    user: state.user,
    module: state.module,
    stars: state.stars,
    history: state.history,
    storage: state.storage,
    client: state.client,
    search: state.search
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch
  }
}

const UserContainer = ({
  user,
  module,
  stars,
  history,
  storage,
  dispatch,
  client,
  search
}) => {
  const isDesktop = client.type.localeCompare('desktop') === 0 || client.type.localeCompare('tablet') === 0
  let homeClass = 'mr-4'
  let starsClass = module.title === 'stars' ? 'mr-4 font-weight-bold' : 'mr-4'
  let modulesClass = module.title === 'modules' ? 'mr-4 font-weight-bold' : 'mr-4'
  let uploadsClass = module.title === 'uploads' ? 'mr-4 font-weight-bold' : 'mr-4'
  let recommendationsClass = module.title === 'recommendations' ? 'font-weight-bold' : ''
  const userLoggedIn = !!user.username
  const userOwnsList = user && user.username === module.author
  // console.log(`module: ${JSON.stringify(module)}`)
  return (
    <div>
      {userLoggedIn && <div>
        <div className='media-list'>
          <div className='media list-group-item ls-reference bg-light'>
            <div className='d-flex'>
              <div className='mr-auto'>
                <a className={homeClass} href={`/${module.author}`}><i className='icon icon-home' />{isDesktop ? 'Home' : ''}</a>
                <a className={modulesClass} href={`/${module.author}/modules`}><i className='icon icon-documents' />{isDesktop ? 'Modules' : ''}</a>
                <a className={starsClass} href={`/${module.author}/stars`}><i className='icon icon-star' />{isDesktop ? 'Stars' : ''}</a>
                {user.username === module.author && <a className={uploadsClass} href={`/${module.author}/uploads`}><i className='icon icon-upload' />{isDesktop ? 'Uploads' : ''}</a>}
                {user.username === module.author && <a className={recommendationsClass} href={`/${module.author}/recommendations`}><i className='icon icon-thumbs-up' />{isDesktop ? 'Recommendations' : ''}</a>}
              </div>
              <h5 className='my-0'>{module.author}</h5>
            </div>
          </div>
          {module.title === 'uploads' && <UserUploads
            user={user}
            module={module}
            storage={storage}
            dispatch={dispatch}
          />}
          {module.components.length > 0 && <UserModule
            user={user}
            module={module}
            stars={stars}
            history={history}
            storage={storage}
            dispatch={dispatch}
            isDesktop={isDesktop}
            search={search}
          />}
          {module.components.length === 0 && module.title === 'modules' && <div className='media list-group-item'>
            {userOwnsList && <li className='media list-group-item'>
              <h1>Litsignal modules</h1>
              <h2 className='ls-light'>Modules are the primary building blocks of the Litsignal ecosystem.</h2>
              <p className='ls-lead'>
                A module is like a wiki page that you create to share a topic that is important to you.
                It could be as simple as a list of references, but it can also contain original text, file attachments, and embedded video.
                Modules can also contain other mofules, almost like a file folder.
                Your module will remain private until you are ready to publish it.
                Then you can continue updating it and publishing new versions over time.
                You can even accept contributions from other users.
              </p>
              <p className='ls-lead'>
                Click on the <code>+ New module</code> button at the top of the screen to create your first module.
              </p>
            </li>}
            {!userOwnsList && <li className='media list-group-item'>
              <h2 className='ls-light'>{`${module.author} hasn't published any modules.`}</h2>
            </li>}
          </div>}
          {module.components.length === 0 && module.title === 'stars' && <div className='media list-group-item'>
            {userOwnsList && <li className='media list-group-item'>
              <h1>Stars</h1>
              <h2 className='ls-light'>Use Stars to organize your favorite references, modules and users.</h2>
              <p className='ls-lead'>
                Stars are the simplest organizational tool on Litsignal.
                When you see something you like, whether it's an article, module, or user, Star it.
                Then come back to this page to see all of the content you've starred.
              </p>
            </li>}
            {!userOwnsList && <li className='media list-group-item'>
              <h2 className='ls-light'>{`${module.author} hasn't starred any content.`}</h2>
            </li>}
          </div>}
          {module.components.length === 0 && module.title === 'uploads' && <div className='media list-group-item'>
            {userOwnsList && <li className='media list-group-item'>
              <h1>Uploads</h1>
              <h2 className='ls-light'>Organize your personal collection of PDF articles.</h2>
              <p className='ls-lead'>
                For many people, this is the best place to get started.
                Just click on the <code><i className='icon icon-upload' /> Upload library</code> option under your account dropdown in the upper right corner.
                Select the full text PDF articles that are saved on your device.
                The system will upload the files to your personal Litsignal folder and do its best to connect the files with structured references in the PubMed database.
                Then the resulting list will be displayed here in a familiar and interactive format along with download links that you can access anyhwere.
              </p>
            </li>}
            {!userOwnsList && <li className='media list-group-item'>
              <h2 className='ls-light'>{`${module.author} hasn't uploaded any documents.`}</h2>
            </li>}
          </div>}
          {module.components.length === 0 && module.title === 'recommendations' && userOwnsList && <div className='media list-group-item'>
            <li className='media list-group-item'>
              <h3>You don't have any recommendations</h3>
              You do not currently have any recommendations from any other users.
            </li>
          </div>}
        </div>
      </div>}
      {!userLoggedIn && <div>
        <div className='card'>
          <div className='card-header ls-reference'>
            <div className='d-flex'>
              <div className='mr-auto'>
                <ul className='nav nav-tabs card-header-tabs' role='tab-list'>
                  <li className='nav-item'><a className={homeClass} href={`/${module.author}`}><i className='icon icon-home' />{isDesktop ? 'Home' : ''}</a></li>
                  <li className='nav-item'><a className={modulesClass} href={`/${module.author}/modules`}><i className='icon icon-documents' />{isDesktop ? 'Modules' : ''}</a></li>
                  <li className='nav-item'><a className={starsClass} href={`/${module.author}/stars`}><i className='icon icon-star' />{isDesktop ? 'Stars' : ''}</a></li>
                  {/* <li className='nav-item'><a className={uploadsClass} href={`/${module.author}/uploads`}><i className='icon icon-upload' />{isDesktop ? 'Uploads' : ''}</a></li> */}
                  {/* <li className={activityClass} ><a href={`/${module.author}/activity`}><i className='icon icon-line-graph' />{isDesktop ? 'Activity' : ''}</a></li> */}
                </ul>
              </div>
              <h5 className='my-0'>{module.author}</h5>
            </div>
          </div>
          {module.components.length > 0 && <UserModule
            user={user}
            module={module}
            stars={stars}
            history={history}
            storage={storage}
            dispatch={dispatch}
            isDesktop={isDesktop}
            search={search}
          />}
          {module.components.length === 0 && module.title === 'modules' && <div className='media list-group-item'>
            <li className='media list-group-item'>
              <h2 className='ls-light'>{`${module.author} hasn't published any modules.`}</h2>
            </li>
          </div>}
          {module.components.length === 0 && module.title === 'stars' && <div className='media list-group-item'>
            <li className='media list-group-item'>
              <h2 className='ls-light'>{`${module.author} hasn't starred any content.`}</h2>
            </li>
          </div>}
          {/* {module.components.length === 0 && module.title === 'uploads' && <div className='media list-group-item'>
            <li className='media list-group-item'>
              <h2 className='ls-light'>{`${module.author} hasn't uploaded any documents.`}</h2>
            </li>
          </div>} */}
        </div>
      </div>}
    </div>
  )
}

UserContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  module: PropTypes.object.isRequired,
  stars: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  storage: PropTypes.object.isRequired,
  search: PropTypes.object.isRequired
}

const UserView = connect(mapStateToProps, mapDispatchToProps)(UserContainer)

export default UserView
