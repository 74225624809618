import { connect } from 'react-redux'
import { README_WELCOME } from '../constants'
import marked from 'marked'

marked.setOptions({
  renderer: new marked.Renderer(),
  gfm: true,
  tables: true,
  breaks: false,
  pedantic: false,
  sanitize: false,
  smartLists: true,
  smartypants: false
})

const ReadmeComponent = ({readme, editing, isBeginner, handleChange}) => {
  const getReadme = () => {
    if (isBeginner && !readme) return marked(welcomeMessage)
    return marked(readme)
  }
  const welcomeMessage = README_WELCOME
  if (getReadme() && getReadme().trim().length > 0) {
    return (
      <div className='ls-readme'>
        <hr className='mt-4' />
        <div dangerouslySetInnerHTML={{__html: getReadme()}} />
      </div>
    )
  } else {
    return null
  }
}

const mapStateToProps = (state) => {
  return {
    readme: state.module.readme || '',
    editing: state.module.editing,
    isBeginner: state.history.modules.modified.length < 5 && state.user.isAuthenticated && state.module.author === state.user.username && ['stars', 'modules', 'recommendations', 'uploads'].indexOf(state.module.title) === -1
  }
}

const Readme = connect(mapStateToProps)(ReadmeComponent)

export default Readme
