import React from 'react'
import References from '../components/References'

const UserModule = ({
  user,
  module,
  stars,
  history,
  storage,
  dispatch,
  isDesktop,
  search
}) => {
  const modifiedModules = history.modules.modified
  let userLoggedIn = !!user.username

  return (
    <References module={module} dispatch={dispatch} stars={stars} storage={storage} search={search} isDesktop={isDesktop} userLoggedIn={userLoggedIn} modifiedModules={modifiedModules} />
  )
}

export default UserModule
