import React, {Component} from 'react'
import {connect} from 'react-redux'
import fetch from 'isomorphic-fetch'
// import {deleteLibraryItem, associateLibraryItem} from '../actions'
import {deleteLibraryItem} from '../actions'
import ModalCard from './ModalCard'
import { API_URL } from '../constants'

const mapStateToProps = (state) => {
  return {
    username: state.user.username,
    unmatchedFiles: state.storage.unmatchedFiles
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

class DissociatedFile extends Component {
  constructor (props) {
    super(props)
    this.state = {showModal: false, association: '', previewUrl: null}
    this.updateAssociation = this.updateAssociation.bind(this)
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.fetchPreviewUrl = this.fetchPreviewUrl.bind(this)
  }
  fetchPreviewUrl () {
    console.log('fetchPreviewUrl')
    if (!this.state.previewUrl) {
      fetch(`${API_URL}/library/preview/${this.props.sha1}`, {method: 'GET'})
        .then(response => {
          if (response.ok) {
            response.json()
              .then(json => {
                this.setState({...this.state, previewUrl: json.url})
              })
          }
        })
    }
  }
  updateAssociation (e) {
    e.stopPropagation()
    this.setState(Object.assign({}, this.state, {
      association: e.target.value
    }))
  }
  openModal (e) {
    this.setState({...this.state, showModal: true})
    this.fetchPreviewUrl()
  }
  closeModal (e) {
    this.setState({...this.state, showModal: false})
  }
  render () {
    const {filename, sha1, onDelete, onAssociate} = this.props
    const {showModal, association, previewUrl} = this.state
    return (
      <div className='btn-group mr-2 mt-2' role='group' aria-label={filename}>
        <a href={`/downloads/dissociated/${sha1}/${filename}`} type='button' className='btn btn-link' title='Download file.'>
          {filename}
        </a>
        <button onClick={this.openModal} className='btn btn-link' type='button' title={`Associate ${filename} with a reference`} >
          <i className='icon icon-tools mr-2' />Associate
        </button>
        <button tabIndex='-1' className='btn btn-link' role='button' onClick={onDelete} data-tag={sha1} title='Delete file.' >
          <i className='icon icon-cross mr-2' />Delete
        </button>
        {showModal && <ModalCard onHide={this.closeModal}>
          <div className='card-body'>
            <form onSubmit={onAssociate}>
              <h6>{`Associate ${filename} with a reference`}</h6>
              <div className='input-group mb-O'>
                <input
                  id='associationfield'
                  className='form-control'
                  name='association'
                  type='text'
                  placeholder='Pubmed ID or DOI'
                  value={association}
                  onChange={this.updateAssociation}
                  autoFocus
                />
                <div className='input-group-append'>
                  <button className='btn btn-primary' type='submit' >Associate file</button>
                </div>
              </div>
            </form>
            {previewUrl && <div style={{'max-height': 'calc(60vh)', 'overflow': 'auto'}} >
              <img src={previewUrl} />
            </div>}
          </div>
        </ModalCard>}
      </div>
    )
  }
}

const DissociatedFilesContainer = ({
  username,
  unmatchedFiles,
  dispatch
}) => {
  const onDelete = (e) => {
    e.stopPropagation()
    const sha1 = e.target.getAttribute('data-tag')
    dispatch(deleteLibraryItem(sha1))
  }
  // const onAssociate = (sha1, association) => {
  //   console.log('Associate!')
  //   dispatch(associateLibraryItem(sha1, association))
  // }
  if (unmatchedFiles.length > 0) {
    // console.log(`unmatchedFiles: ${JSON.stringify(unmatchedFiles)}`)
    return (
      unmatchedFiles.map(fileEntry => {
        const {sha1, filename} = fileEntry
        return (
          // <div key={sha1}><DissociatedFile sha1={sha1} filename={filename} username={username} onDelete={onDelete} onAssociate={onAssociate} /></div>
          <div key={sha1}><DissociatedFile sha1={sha1} filename={filename} username={username} onDelete={onDelete} /></div>
        )
      })
    )
  } else {
    return null
  }
}

const DissociatedFiles = connect(mapStateToProps, mapDispatchToProps)(DissociatedFilesContainer)

export default DissociatedFiles
