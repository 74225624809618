import PropTypes from 'prop-types'

const UserSearchForm = ({
  term,
  onInputChange,
  onInputBlur
}) => {
  return (
    <div className='input-group my-0 col px-0'>
      <input
        id='searchfield'
        className='form-control'
        name='searchterm'
        placeholder='Search for Litsignal users by name'
        onChange={onInputChange}
        onBlur={onInputBlur}
        value={term}
        autoComplete='off' />
    </div>
  )
}

UserSearchForm.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onInputBlur: PropTypes.func.isRequired,
  term: PropTypes.string.isRequired
}

export default UserSearchForm
