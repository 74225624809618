import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

// TITLE

export const ArticleTitleRow = ({url, title, hint, inLibrary}) => {
  if (url) {
    return <div className='ls-reference-title' itemProp='name'><a href={url} target={inLibrary ? '' : '_blank'} onClick={function (event) { event.stopPropagation() }} title={hint} itemProp='url' ><i className='icon icon-text-document' /><span dangerouslySetInnerHTML={{__html: _.unescape(title)}} /></a></div>
  } else {
    return <div className='ls-reference-title' itemProp='name'><i className='icon icon-text-document' /><span dangerouslySetInnerHTML={{__html: _.unescape(title)}} /></div>
  }
}
ArticleTitleRow.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  hint: PropTypes.string,
  inLibrary: PropTypes.bool
}

export class BookTitleRow extends Component {
  render () {
    const { title } = this.props
    return <div className='ls-reference-title'><i className='icon icon-book' />{_.unescape(title)}></div>
  }
}
BookTitleRow.propTypes = {
  isbn13: PropTypes.number,
  title: PropTypes.string
}

export const LinkTitleRow = ({ title, url, active }) =>
  <div className='ls-reference-title'>
    <a target='_blank' href={active ? url : null} onClick={function (event) { event.stopPropagation() }} title='Visit the web page'><i className={(url.toLowerCase().includes('youtube') || url.toLowerCase().includes('vimeo') || url.toLowerCase().includes('youtu.be')) ? 'icon icon-clapperboard' : 'icon icon-link'} />{title}</a>
  </div>
LinkTitleRow.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string
}

// AUTHOR

export const AuthorsRow = ({ authors }) => {
  // console.log(`authors: ${authors.join(' - ')}`)
  if (authors.length > 0) {
    return (
      <div >
        <span itemProp='author' itemType='http://schema.org/Person'>{authors.join(authors[0].includes(',') ? '; ' : ', ')}</span>
      </div>
    )
  } else {
    return null
  }
}
AuthorsRow.propTypes = {
  authors: PropTypes.array
}

export const BookAuthorRow = ({ author }) =>
  <div>{author}</div>
BookAuthorRow.propTypes = {
  author: PropTypes.string
}

// JOURNAL

export const JournalRow = ({ journalName, abbr, volume, issue, pages, date }) => {
  const vol = volume ? `; ${volume}` : ''
  const iss = issue ? `(${issue})` : ''
  const pag = pages ? `: ${pages}` : ''
  const dateDetails = `${date}${vol}${iss}${pag}.`
  // console.log('dateDetails: ' + dateDetails)
  // console.log(`journalName: ${journalName}`)
  return (
    <div >
      {_.unescape(titleCaseFromAbbreviation(journalName, abbr) + '. ')}
      {dateDetails}
    </div>
  )
}
JournalRow.propTypes = {
  journalName: PropTypes.string,
  volume: PropTypes.string,
  issue: PropTypes.string,
  pages: PropTypes.string,
  date: PropTypes.string
}

const titleCaseFromAbbreviation = function (title, abbr) {
  title = title ? title.trim() : ''
  abbr = abbr ? abbr.trim() : ''
  // console.log('abbr: ' + abbr)
  // console.log('title: ' + title)
  // ("New Engl J Med", "The New England journal of medicine")
  var titleArray = title.split(' ')
  var abbrArray = abbr.split(' ')
  var newTitle = ''
  if (titleArray[0] === 'The') {
    newTitle = titleArray.shift() // -> "The"
  }
  // console.log(abbrArray)
  newTitle += ' ' + (titleArray[0].charAt(0).toUpperCase() + titleArray[0].slice(1)) // -> "The New"
  var a = 1 // abbreviation iterator
  if (abbrArray.length > 0) {
    // console.log('abbrArray: ' + abbrArray)
    // console.log('titleArray: ' + titleArray)
    for (var i = 1; i < titleArray.length; i++) { // title iterator
      newTitle += ' ' // -> "The New "
      if (a < abbrArray.length && (titleArray[i].indexOf(abbrArray[a].toLowerCase()) !== -1 || titleArray[i].indexOf(abbrArray[a]) !== -1)) {
        var titleWord = (titleArray[i].charAt(0).toUpperCase() + titleArray[i].slice(1))
        var hyphenatedAt = titleWord.indexOf('-')
        if (hyphenatedAt > -1) {
          if (abbrArray[a + 1] && titleWord.indexOf(abbrArray[a + 1].toLowerCase)) {
            titleWord = titleWord.slice(0, hyphenatedAt) + '-' + titleWord.charAt(hyphenatedAt + 1).toUpperCase() + titleWord.slice(hyphenatedAt + 2)
            a++
          }
        }
        newTitle += titleWord
        a++
      } else {
        newTitle += titleArray[i]
      }
    }
  }
  return newTitle
}
