import {
  STATE_STORAGE,
  UPLOAD_LIBRARY_REQUEST, UPLOAD_LIBRARY_SUCCESS, UPLOAD_LIBRARY_ERROR, UPLOAD_LIBRARY_PROGRESS,
  LOGIN_SUCCESS, REGISTER_SUCCESS,
  REINITIALIZE_STORE,
  DELETE_LIBRARY_ITEM_SUCCESS,
  PATCH_LIBRARY_ITEM_SUCCESS
} from '../constants'

// TODO: refactor reducers to accommodate the unmatched file shape {sha1, originalName}

function storage (state = STATE_STORAGE, action) {
  switch (action.type) {
    case REINITIALIZE_STORE: {
      return action.store.storage
    }
    case UPLOAD_LIBRARY_REQUEST: {
      return state
    }
    case UPLOAD_LIBRARY_ERROR: {
      return Object.assign({}, state, {
        uploading: false
      })
    }
    case UPLOAD_LIBRARY_PROGRESS: {
      return Object.assign({}, state, {
        uploading: {
          total: action.total,
          loaded: action.loaded,
          fileCount: action.fileCount
        }
      })
    }
    case UPLOAD_LIBRARY_SUCCESS: {
      return Object.assign({}, state, {
        uploading: false
      })
    }
    case LOGIN_SUCCESS: {
      return Object.assign({}, action.storage, {
        uploading: false
      })
    }
    case DELETE_LIBRARY_ITEM_SUCCESS: {
      const {sha1} = action
      return Object.assign({}, state, {
        library: state.library.filter(item => {
          return item.sha1 !== sha1
        }),
        unmatchedFiles: state.unmatchedFiles.filter(item => {
          return item.sha1 !== sha1
        })
      })
    }
    case PATCH_LIBRARY_ITEM_SUCCESS: {
      const {sha1, componentUUID} = action
      if (componentUUID) {
        return Object.assign({}, state, {
          library: [{sha1, componentUUID}].concat(state.library),
          unmatchedFiles: state.unmatchedFiles.filter(item => {
            return item !== sha1
          })
        })
      } else {
        return Object.assign({}, state, {
          library: state.library.filter(item => {
            return item.sha1 !== sha1
          }),
          unmatchedFiles: [sha1].concat(state.unmatchedFiles)
        })
      }
    }
    case REGISTER_SUCCESS: {
      return STATE_STORAGE
    }
    default:
      return state
  }
}

export default storage
