import React, {Component} from 'react'
import Article from '../components/Article'
import Module from '../components/Module'

class AllSearchResults extends Component {
  render () {
    const { user, search, stars, history, storage, dispatch, client } = this.props
    // console.log('search.history.length: ' + search.history.length)
    // console.log('user (AllSearchResults): ' + JSON.stringify(user))
    const isDesktop = client.type.localeCompare('desktop') === 0 || client.type.localeCompare('tablet') === 0
    const modifiedModules = history.modules.modified
    const components = search.components
    const retmax = search.history.length > 0 ? search.history[search.history.length - 1].retmax : null
    const page = search.page || 1

    let userLoggedIn = !!user.username

    const resultCount = search.history.length > 0 ? parseInt(search.history[search.history.length - 1].count, 10) : null
    let lastResultIndex = search.history.length > 0 ? page * retmax - 1 : null
    if (lastResultIndex > (resultCount - 1)) lastResultIndex = resultCount - 1

    const articles = components.filter(component => component.type === 'article')
    const modules = components.filter(component => component.type === 'module')

    return (
      <div>
        {modules && modules.length > 0 && <div className='media-list'>
          <li className='media list-group-item'>
            <strong>Modules</strong>
          </li>
          {modules.slice(0, 5).map(
            (component, index, array) => {
              return (
                <Module
                  dispatch={dispatch} stars={stars} module={component} key={component.uuid} index={index} length={components.length}
                  userLoggedIn={userLoggedIn}
                  moduleTitle={'search'} uuid={component.uuid}
                  modifiedModules={modifiedModules} isDesktop={isDesktop}
                />
              )
            }
          )}
          <li className='media list-group-item'><a className='ml-auto' href={`/search/litsignal?term=${search.term}`}><i className='icon icon-arrow-right' />See all module results</a></li>
        </div>}
        {articles && articles.length > 0 && <div className='media-list'>
          <li className='media list-group-item'>
            <strong>Articles</strong>
          </li>
          {articles.map(
            (component, index, array) => {
              return (
                <Article
                  dispatch={dispatch} uuid={component.uuid} article={component.metadata} key={component.uuid} index={index} length={search.components.length}
                  editing={false} userLoggedIn={userLoggedIn}
                  searchTitle={search.title}
                  stars={stars}
                  storage={storage}
                  rearranging={search.rearranging}
                  modifiedModules={modifiedModules}
                  isDesktop={isDesktop}
                />
              )
            }
          )}
          <li className='media list-group-item' ><a href={`/search/pubmed?term=${search.term}`} ><i className='icon icon-arrow-right' />See all article results</a></li>
        </div>}
      </div>
    )
  }
}

export default AllSearchResults
