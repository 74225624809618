import { ArticleTitleRow, AuthorsRow, JournalRow } from './ReferenceComponents'
import {getEncodedFilename} from '../helpers'

const ArticleInfo = ({
  inLibrary,
  article
}) => {
  const { uuid, title, authors, journal, date, volume, issue, pmid, pmcid, pages, doi } = article
  const fullTextAvailable = !!pmcid || inLibrary
  let url = null
  let hint = null
  if (inLibrary) {
    url = `/downloads/library/${uuid}/${getEncodedFilename(title + '.pdf')}`
    hint = 'Private PDF attachment'
  } else if (pmcid) {
    url = `http://www.ncbi.nlm.nih.gov/pmc/articles/${pmcid}`
    hint = 'Get the full text version at PubMed Central'
  } else if (pmid) {
    url = `http://www.ncbi.nlm.nih.gov/pubmed/${pmid}`
    hint = 'View the abstract on PubMed'
  } else if (doi) {
    url = `http://doi.org/${doi}`
    hint = 'Visit the publisher web page using the digital object identifier'
  }
  const newTitle = fullTextAvailable ? `(Full text) ${title}` : title
  return (
    <div style={{lineHeight: 1.4}}>
      <ArticleTitleRow title={newTitle} url={url} inLibrary={inLibrary} hint={hint} />

      <AuthorsRow authors={authors} />
      <JournalRow journalName={journal.name} date={date} volume={volume} issue={issue} pages={pages} abbr={journal.abbreviation} />

    </div>
  )
}

export default ArticleInfo
